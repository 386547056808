<template>
  <div>
    <form-title :form="form" />
    <preview-navigation v-if="isPreview" />
    <div :class="['ui', 'segment', { loading: submitting }]">
      <div v-if="form.isStepForm">
        <div v-for="(step, index) in form.steps" :key="index" class="ui raised segment">
          <div class="ui internally celled grid">
            <h3>{{ step.title | trans }}</h3>
            <div class="w100">{{ step.description | trans }}</div>
            <div class="ui internally celled grid">
              <field-view
                v-for="field in fieldsInStep(step.fields)"
                :key="field.id"
                :field="field"
                :value="record[field.code].value"
                class="row"
              ></field-view>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="ui internally celled grid">
        <field-view
          v-for="field in fields"
          :key="field.id"
          :field="field"
          :value="record[field.code].value"
          class="row"
        ></field-view>
      </div>
      <div class="ui stackable grid">
        <back-button :icon="'reply'" @back="back"></back-button>
        <post-button :disabled="posted" @post="submit"></post-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import FieldView from 'Public/FieldView';
import PostButton from 'Public/Button/PostButton';
import BackButton from 'Public/Button/BackButton';
import FormTitle from 'Public/Header/FormTitle';
import PreviewNavigation from 'Public/Header/PreviewNavigation';
import url, { getPathType } from '../utils/url-utils';

export default {
  name: 'PublicFormConfirm',
  components: { FieldView, PostButton, BackButton, FormTitle, PreviewNavigation },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.form.googleAnalytics && vm.$store.state.form.trackingId) {
        vm.$gtag.event(`confirm: ${to.params.code}`, {
          event_category: 'Form',
        });
      }
    });
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    fieldsInStep() {
      return (stepFields) => this.fields.filter((field) => stepFields.indexOf(field.code) >= 0);
    },
    isPreview() {
      return getPathType(window.location.pathname) === 'private';
    },
    ...mapState(['fields', 'record', 'submitting', 'kappauthPreview']),
    ...mapGetters(['posted']),
  },
  created() {
    this.$store.commit('callEvents', { events: fb.events.confirm.created });
    this.form = this.$store.state.form;
  },
  mounted() {
    this.$store.commit('callEvents', { events: fb.events.confirm.mounted });
    this.$scrollTo('body');
  },
  methods: {
    back() {
      this.$store.commit('callEvents', { events: fb.events.confirm.back });
      const query = {};
      if (this.kappauthPreview) {
        query.kappauthPreview = this.kappauthPreview;
      }
      this.$router.replace({
        name: url.renameTo(this.$route.name, '/'),
        query,
      });
    },
    submit() {
      this.$store.commit('callEvents', { events: fb.events.confirm.submit });
      this.$store.dispatch('submit', this);
    },
  },
};
</script>

<style scoped>
.w100 {
  width: 100%;
}
.ui.grid {
  margin: 1em 0;
}
</style>
