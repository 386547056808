<template>
  <span>
    <a
      v-if="isUseTemporarySave && canUseStorage"
      tabindex="0"
      :class="['ui labeled icon button', { disabled }]"
      @click="save"
      @keydown.enter="save"
    >
      <i class="pause icon"></i>
      {{ 'Temporary Save' | trans }}
    </a>
    <el-dialog
      :width="isMobile ? '90%' : '50%'"
      :visible.sync="restoreData"
      :before-close="closeDialog"
      :title="'Temporary Save' | trans"
    >
      <p>{{ 'continue where we left off.' | trans }}</p>
      <p v-if="versionChanged">
        {{ 'Web form has been changed. Some temporary save data may not be restored.' | trans }}
      </p>
      <div class="save-dialog-btn">
        <icon-button icon="redo" color="primary" label="from the middle" labeled @click="restoreSaveData" />
        <icon-button icon="play" label="from the beginning" labeled @click="closeDialog" />
      </div>
    </el-dialog>
  </span>
</template>
<script>
import md5 from 'js-md5';
import u from '../../utils/utils';
import su from '../../utils/storage-utils';
import { types } from '../../utils/field-utils';
import IconButton from '../Common/IconButton';

export default {
  name: 'TemporarySave',
  components: { IconButton },
  props: {
    active: {
      type: Number,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    restoreData: false,
    versionChanged: false,
    isMobile: u.isMobile(),
  }),
  computed: {
    canUseStorage() {
      return su.canUse('IndexedDB') || su.canUse('localStorage');
    },
    isUseTemporarySave() {
      return this.$store.state.form.temporarySave;
    },
  },
  mounted() {
    if (this.isUseTemporarySave && this.canUseStorage) {
      su.get({ key: this.$store.state.form.code }, this.canUseStorage)
        .then((value) => {
          this.restoreData = true;
          if (value.version !== this.getVersion()) {
            this.versionChanged = true;
          }
        })
        .catch(() => {});
    }
  },
  methods: {
    restoreSaveData() {
      su.get({ key: this.$store.state.form.code }, this.canUseStorage)
        .then((value) => {
          Object.keys(value.record).forEach((fieldCode) => {
            const storedField = this.getField(fieldCode);
            if (!storedField) return;

            const record = value.record[fieldCode];
            if (storedField.type !== record.type) return;

            if (record.type === 'SUBTABLE') {
              this.restoreTableField(record, fieldCode);
            } else {
              this.$store.dispatch('updateField', { code: fieldCode, value: record.value, vm: this });
            }
          });
          if (this.$store.state.form.isStepForm) {
            const maxNextCount = this.$store.state.form.steps.length - 1;
            for (let i = 0; i < value.active && i < maxNextCount; i += 1) {
              this.$emit('next');
            }
          }
        })
        .catch((error) => {
          u.error(this, error);
        });
      this.closeDialog();
    },
    restoreTableField(record, fieldCode) {
      const storedTableField = this.getField(fieldCode);
      record.value.forEach((table, i) => {
        const tableFields = table.value;
        if (i > 0) {
          this.$store.dispatch('addTableRecord', {
            code: fieldCode,
            type: table.type,
            fields: storedTableField.fields,
            vm: this,
          });
        }
        Object.keys(tableFields).forEach((tableFieldCode) => {
          const storedField = storedTableField.fields.find((field) => field.code === tableFieldCode);
          if (!storedField) return;

          const field = tableFields[tableFieldCode];
          if (storedField.type !== field.type) return;

          this.$store.dispatch('updateTableField', {
            tableCode: fieldCode,
            tableIndex: i,
            code: tableFieldCode,
            type: field.type,
            value: field.value,
            vm: this,
          });
        });
      });
    },
    getField(code) {
      return this.$store.getters.getFieldByCode(code);
    },
    closeDialog() {
      this.restoreData = false;
    },
    save() {
      su.set(
        {
          key: this.$store.state.form.code,
          value: {
            record: this.$store.state.record,
            active: this.active,
            version: this.getVersion(),
          },
        },
        this.canUseStorage,
      )
        .then(() => {
          u.success(this, 'complete save.');
        })
        .catch((error) => {
          u.error(this, error);
        });
    },
    getVersion() {
      const { fields } = this.$store.state;
      const flattenFields = fields.flatMap((field) => {
        if (field.type === 'SUBTABLE') {
          return [field].concat(field.fields);
        }
        return field;
      });
      const valueFields = flattenFields.filter((field) => !types.noValue.includes(field.type));
      const codeTypes = valueFields.map((field) => field.code + field.type);
      codeTypes.sort();
      return md5(codeTypes.join(''));
    },
  },
};
</script>

<style scoped>
.save-dialog-btn {
  margin-top: 2em;
}
</style>
