<template>
  <h1 class="ui header fb-title">
    <div class="content">
      {{ form.title | trans }}
    </div>
  </h1>
</template>

<script>
export default {
  name: 'FormTitle',
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped />
