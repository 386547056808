<template>
  <vue-slider
    ref="slider"
    v-model="val"
    :interval="field.step"
    :min="field.minValue"
    :max="field.maxValue"
    :piecewise="field.step > 1"
    :tooltip="'hover'"
    :disabled="!field.editable"
  ></vue-slider>
</template>

<script>
import VueSlider from 'vue-slider-component';
import fu from 'utils/field-utils';

export default {
  name: 'FbSlider',
  components: { VueSlider },
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  created() {
    const self = this;
    this.$watch(
      'field',
      (field) => {
        if (fu.isShowField(field)) {
          self.$refs.slider.refresh();
        }
      },
      {
        deep: true,
      },
    );
  },
};
</script>

<style scoped></style>
