<template>
  <fb-file :style="{ maxWidth: fieldWidth }" :field="field" :value="value" @update="update" />
</template>

<script>
import u from 'utils/utils';
import FbFile from 'Field/File';

export default {
  name: 'FbTableFile',
  components: { FbFile },
  props: {
    tableField: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fieldWidth() {
      // スマホ用フィールドに親テーブルが設定されていない時
      // フィールド設定で指定した幅からテーブルセルのpaddingを引いた値を絶対値としてフィールドのサイズに指定してあげることでoverflowが効く様にした。
      // スマホ用フィールドに親テーブルが設定されている時
      // displayサイズに合わせてテーブル全体の幅が動的に決まっており絶対値で幅が指定できなかったため公開フォームのパディング、マージン、ボーダーを引いて幅を計算して指定している
      return u.isMobile() && this.hasMobileStyle
        ? 'calc(100vw - 7.5rem - 4px)'
        : `calc(${this.field.style.width} - (0.78571429em * 2))`;
    },
    hasMobileStyle() {
      return this.tableField.isMobileStyle;
    },
  },
  methods: {
    update(data) {
      this.$emit('update', data);
    },
  },
};
</script>
