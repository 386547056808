/**
 * @param {Number|String} size
 */
const formatFileSize = (size) => {
    const units = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const threshold = 1024;
    const siz = Number(size) * threshold;
    const i = siz === 0 ? 0 : Math.floor(Math.log(siz) / Math.log(threshold));
    // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    return `${(siz / threshold ** i).toFixed(2) * 1} ${units[i]}`;
};
const dic = {
    en: {
        messages: {
            _default: () => `Please enter a valid value.`,
            after: (_, [target, inclusion]) => `Please enter a value after ${inclusion ? 'or equal to ' : ''}${target}.`,
            alpha_dash: () => `Please enter a value that contains only alpha-numeric characters, dashes and/or underscores.`,
            alpha_num: () => `Please enter a value that contains only alpha-numeric characters.`,
            alpha_spaces: () => `Please enter a value that contains only alphabetic characters and/or spaces.`,
            alpha: () => `Please enter a value that contains only alphabetic characters.`,
            before: (_, [target, inclusion]) => `Please enter a value before ${inclusion ? 'or equal to ' : ''}${target}.`,
            between: (_, [min, max]) => `Please enter a value between ${min} and ${max}.`,
            confirmed: () => `The values do not match.`,
            credit_card: () => `This credit card information is incorrect.`,
            date_between: (_, [min, max]) => `Please choose a date between ${min} and ${max}.`,
            date_format: (_, [format]) => `Please enter a date with the format ${format}.`,
            decimal: (_, [decimals = '*'] = []) => `This value must be numeric and may contain ${!decimals || decimals === '*' ? '' : decimals} decimal points.`,
            digits: (_, [length]) => `This value must be numeric and exactly contain ${length} digits.`,
            dimensions: (_, [width, height]) => `This image must be within ${width} pixels by ${height} pixels.`,
            email: () => `Please enter a valid email.`,
            ext: () => `Please enter a valid file.`,
            image: () => `Please enter an image file.`,
            in: () => `Please enter a valid value.`,
            integer: () => `Please enter an integer.`,
            ips: () => `Please enter a valid form of IP or IPs range.`,
            exist_in_kviewer: () => `You have selected fields that have not been published by kViewer.`,
            every_exist_in_kviewer: () => `You have selected fields that have not been published by kViewer.`,
            length: (_, [length, max]) => {
                if (max) {
                    return `Please enter a field length between ${length} and ${max}.`;
                }
                return `The field length must be ${length}.`;
            },
            max: (_, [length]) => `This value may not be greater than ${length} characters.`,
            max_value: (_, [max]) => `This value must be ${max} or less.`,
            mimes: () => `The file type is invalid.`,
            min: (_, [length]) => `This value must be at least ${length} characters.`,
            min_value: (_, [min]) => `This value must be ${min} or more.`,
            not_in: () => `This value is invalid.`,
            numeric: () => `Please enter a value that contains only numeric characters.`,
            regex: () => `This format is invalid.`,
            required: () => `This field is required.`,
            size: (_, [size]) => `The file size must be less than ${formatFileSize(size)}.`,
            url: () => `Please enter a valid URL.`,
            alpha_sign: () => `Please enter a value that contains only alpha-numeric characters as well as (@ . - _).`,
            alpha_num_sign: () => `Please enter a value that contains only alpha-numeric characters or signs.`,
            not_sign: () => `Symbols other than _ cannot be used.`,
            not_space: () => `Spaces cannot be used.`,
            not_numeric: () => `You cannot use numeric characters only.`,
            unique_code: () => `Please enter a unique field code.`,
            reserved_code: () => `This field code is not available.`,
            tel: () => `Please enter a phone number.`,
            expression: () => `There is a mistake in the expression`,
            datetime_between: (_, [min, max]) => `Please enter a datetime between ${min} and ${max}.`,
            unique: () => `Please enter a unique value.`,
            not_equal_in_case_insensitive: (_, compare) => `Must be different from ${compare}`,
            required_choices: () => `Required choices have not been selected.`,
            confirmed_field: () => `The values do not match.`,
            secure_access: () => `Please remove '.s' in your URL because Client Certificate Authentication cannot be used.`,
            kintone_url: () => `The URL of the kintone is invalid.`,
            kintone_app_url: () => `The URL of the Kintone app is invalid.`,
            origin_matched_kintone_app_url: () => `%link%, please enter the URL of the kintone app created in the environment.`,
            min_select: (_, min) => `Please select at least ${min} items.`,
            max_select: (_, max) => `You cannot select more than ${max} items.`,
            max_length: (_, max) => `You cannot set more than ${max} items.`,
            custom_code_regexp: () => 'Contains characters that cannot be used',
            max_with_message_includes_count: (_field, [max], { count }) => `Please set within ${max} characters. Currently ${count} characters.`,
            ext_in_url: (_field, exts) => `The file extension must be one of ${exts.join(', ')}`,
        },
    },
    ja: {
        messages: {
            alpha_sign: () => `半角英数字か @ . - _ で入力してください`,
            not_sign: () => `_ 以外の記号は使えません`,
            not_space: () => `空白は使えません`,
            not_numeric: () => `数字のみの入力はできません`,
            unique_code: () => `重複したフィールドコードを利用することはできません`,
            reserved_code: () => `利用できないフィールドコードです`,
            alpha_dash: () => `半角英数字か - _ で入力してください`,
            alpha_num: () => `半角英数字を入力してください`,
            alpha_num_sign: () => `半角英数字か記号で入力してください`,
            alpha_spaces: () => `半角英字かスペースで入力してください`,
            alpha: () => `半角英字を入力してください`,
            between: (_, [min, max]) => `${min}以上${max}以下にしてください`,
            confirmed: () => `同じ値を入力してください`,
            confirmed_field: () => `同じ値を入力してください`,
            credit_card: () => `クレジットカード情報が間違っています`,
            date_between: (_, [min, max]) => `${min}から${max}までの日を入力してください`,
            datetime_between: (_, [min, max]) => `${min}から${max}までの日時を入力してください`,
            decimal: (_, [decimals] = ['*']) => `小数点${decimals === '*' ? '' : `${decimals}桁`}の数値を入力してください`,
            digits: (_, [length]) => `${length}桁以上の数値を入力してください`,
            dimensions: (_, [width, height]) => `${width} px × ${height} px以内の画像を入力してください`,
            email: () => `メールアドレスを入力してください`,
            ext: () => `有効なファイルではありません`,
            image: () => `画像ファイルを入力してください`,
            in: () => `有効な値ではありません`,
            ips: () => `有効なIPアドレス指定方法ではありません`,
            exist_in_kviewer: () => `kViewerで公開していないフィールドを選択しています`,
            every_exist_in_kviewer: () => `kViewerで公開していないフィールドを選択しています`,
            max: (_, [length]) => `${length}文字以内にしてください`,
            max_value: (_, [max]) => `${max}以下にしてください`,
            mimes: () => `ファイルタイプが不正です`,
            min: (_, [length]) => `${length}文字以上にしてください`,
            min_value: (_, [min]) => `${min}以上にしてください`,
            not_in: () => `有効な値ではありません`,
            numeric: () => `半角数値を入力してください`,
            regex: () => `入力できないフォーマットです`,
            required: () => `必須項目です`,
            size: (_, [size]) => `${size} KB以下のファイルを入力してください`,
            url: () => `URLを入力してください`,
            tel: () => `電話番号を入力してください`,
            expression: () => `計算式に誤りがあります`,
            unique: () => `重複しない値を入力してください`,
            not_equal_in_case_insensitive: (_, compare) => `${compare}と異なる値にしてください`,
            required_choices: () => `必須の選択肢が選択されていません`,
            secure_access: () => `セキュアアクセスには対応しておりません。.s を外したURLを入力してください。`,
            kintone_url: () => `kintoneのURLが有効なURLではありません`,
            kintone_app_url: () => `kintoneアプリのURLが有効なURLではありません`,
            origin_matched_kintone_app_url: () => `%link%で登録している環境で作成したkintoneアプリのURLを入力してください。`,
            min_select: (_, min) => `${min}個以上選択してください`,
            max_select: (_, max) => `${Number(max) + 1}個以上選択できません`,
            max_length: (_, max) => `${max}個以下にしてください`,
            custom_code_regexp: () => '使用できない文字が含まれています',
            max_with_message_includes_count: (_field, [max], { count }) => `${max}文字以内で設定してください。現在は${count}文字です`,
            ext_in_url: (_field, exts) => `拡張子は ${exts.join(', ')} のいずれかでなければなりません`,
        },
    },
    cn: {
        messages: {
            alpha_sign: () => `请输入半角字母数字字符或 @ . - _`,
            not_sign: () => `不能使用 _ 以外的符号`,
            not_space: () => `空间不能使用`,
            not_numeric: () => `您不能仅输入数字`,
            unique_code: () => `字段代码应该是唯一的`,
            reserved_code: () => `此字段代码不可用`,
            alpha_dash: () => `请输入半角字母数字字符 - _`,
            alpha_num: () => `请输入半角字母数字`,
            alpha_num_sign: () => `请输入半角字母数字字符`,
            alpha_spaces: () => `请输入半角字母或空格`,
            alpha: () => `请输入半角字母`,
            between: (_, [min, max]) => `必须大于等于${min}且小于等于${max}`,
            confirmed: () => `请输入相同的值`,
            confirmed_field: () => `请输入相同的值`,
            credit_card: () => `信用卡信息错误`,
            date_between: (_, [min, max]) => `必须大于等于${min}且小于等于${max}`,
            datetime_between: (_, [min, max]) => `必须大于等于${min}且小于等于${max}`,
            decimal: (_, [decimals] = ['*']) => `请输入${decimals === '*' ? '' : decimals}位小数的数字`,
            digits: (_, [length]) => `请输入${length}位数或者以上的值`,
            dimensions: (_, [width, height]) => `请输入${width} px x ${height} px内的图片`,
            email: () => `请输入您的电子邮件地址`,
            ext: () => `它不是有效的文件`,
            image: () => `请输入图像文件`,
            in: () => `它不是有效的值`,
            ips: () => `请输入有效的IP地址规范`,
            exist_in_kviewer: () => `你选择了一个尚未被kViewer发布的字段`,
            every_exist_in_kviewer: () => `你选择了一个尚未被kViewer发布的字段`,
            max: (_, [length]) => `必须为${length}个字符或更少`,
            max_value: (_, [max]) => `必须为${max}或更小`,
            mimes: () => `文件类型无效`,
            min: (_, [length]) => `必须至少${length}个字母`,
            min_value: (_, [min]) => `必须是${min}或更多`,
            not_in: () => `它不是有效的值`,
            numeric: () => `请输入半角数字`,
            regex: () => `它是一种无法输入的格式`,
            required: () => `必需项目`,
            size: (_, [size]) => `请输入${size}KB以下的文件`,
            url: () => `请输入网址`,
            tel: () => `请输入电话号码`,
            expression: () => `计算公式不正确`,
            unique: () => `请输入不重复的值`,
            not_equal_in_case_insensitive: (_, compare) => `应与${compare}不同`,
            required_choices: () => `没有选择一个必要的选择`,
            kintone_url: () => `kintone URL不是一个有效的URL`,
            min_select: (_, min) => `请选择至少${min}项`,
            max_select: (_, max) => `您不能选择${max}个以上的项目`,
            max_length: (_, max) => `必须是${max}件或更少`,
        },
    },
};
export default dic;
