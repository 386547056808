<template>
  <div :class="{ fields: field.optionOrder === 'horizontal' }">
    <div v-for="(option, key) in field.options" :key="key" class="field">
      <el-checkbox
        v-model="val"
        :label="option"
        :disabled="!field.editable"
        style="word-wrap: break-word; white-space: normal"
      >
        {{ label(option) }}
      </el-checkbox>
    </div>
  </div>
</template>

<script>
import u from '../../../utils/utils';

export default {
  name: 'FbCheckBox',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : [];
      },
      set(value) {
        value.sort((a, b) => {
          const indexA = this.field.options.indexOf(a);
          const indexB = this.field.options.indexOf(b);
          return indexA > indexB ? 1 : -1;
        });
        this.$emit('update', value);
      },
    },
  },
  methods: {
    label(option) {
      if (this.$store.state.form.isTranslate) {
        return u.trans(option);
      }
      return option;
    },
  },
};
</script>

<style scoped>
.ui.form .fields {
  display: block;
  margin-bottom: 0;
}
.fields > .field {
  display: inline-block;
}
</style>
