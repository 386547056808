<template>
  <div v-if="field.type === 'RICH_TEXT'" v-html="getEscapeValue(value)"></div>
  <div v-else-if="field.type === 'MULTI_LINE_TEXT'">
    <div v-for="(line, index) in value.split(/(?:\r\n|\r|\n)/)" :key="index">{{ line }}</div>
  </div>
  <div v-else-if="field.type === 'CHECK_BOX' || field.type === 'MULTI_SELECT'">{{ getArrayValue(value) }}</div>
  <div v-else-if="field.type === 'RADIO_BUTTON' || field.type === 'DROP_DOWN'">{{ getSelectValue(value) }}</div>
  <div v-else-if="field.type === 'DATE'">{{ getDate(value) }}</div>
  <div v-else-if="field.type === 'DATETIME'">{{ getDateTime(value) }}</div>
  <div v-else-if="field.type === 'TIME'">{{ getTime(value) }}</div>
  <div v-else-if="isUrlField">
    <a target="_blank" rel="noopener noreferrer" :href="value">{{ value }}</a>
  </div>
  <div v-else-if="field.type === 'FILE'" v-html="getFiles(value)"></div>
  <div v-else-if="field.type === 'SUBTABLE'">
    <table class="ui collapsing celled unstackable table">
      <thead>
        <tr>
          <th v-for="child in shownTableFields()" :key="child.id">
            <label v-show="!child.noLabel">{{ getLabel(child) }}</label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, rowIndex) in value" :key="rowIndex">
          <td v-for="(child, index) in shownTableFields(tr.value)" :key="index">
            <value-view
              :field="child"
              :value="tr.value[child.code].value"
              :is-translate="isTranslate"
              :is-error-log="isErrorLog"
            ></value-view>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>{{ value }}</div>
</template>

<script>
import dayjs from 'dayjs';
import u from 'utils/utils';
import Router from 'filters/router';

export default {
  name: 'ValueView',
  components: {},
  props: ['field', 'value', 'isTranslate', 'isErrorLog'],
  computed: {
    isUrlField() {
      const { field } = this;
      return (
        field.type === 'SINGLE_LINE_TEXT' &&
        field.attributes &&
        field.attributes.type &&
        field.attributes.type === 'url'
      );
    },
    shownTableFields() {
      return (tableRecord = null) => this.field.fields.filter((child) => this.isTableFieldShow(child, tableRecord));
    },
  },
  created() {},
  methods: {
    getLabel(field) {
      if (this.isTranslate) {
        return u.trans(field.label);
      }
      return field.label;
    },
    isTableFieldShow(field, tableRecord = null) {
      if (tableRecord) {
        return u.has(tableRecord, field.code) && !field.hide && field.type !== 'KVIEWER_LOOKUP';
      }
      return !field.hide && field.type !== 'KVIEWER_LOOKUP';
    },
    getEscapeValue(value) {
      return u.escape(value);
    },
    getArrayValue(value) {
      if (!value) {
        return '';
      }
      if (this.isTranslate) {
        const val = [];
        value.forEach((v) => {
          val.push(u.trans(v));
        });
        return val.toString();
      }
      return value.toString();
    },
    getSelectValue(value) {
      if (this.isTranslate) {
        return u.trans(value);
      }
      return value;
    },
    getDate(value) {
      if (!value) {
        return value;
      }
      if (u.isDateObject(value)) {
        return dayjs(value).format('YYYY-M-D');
      }
      return value;
    },
    getDateTime(value) {
      if (!value) {
        return value;
      }
      return dayjs(value).format('YYYY-M-D HH:mm');
    },
    getTime(value) {
      if (!value) {
        return '';
      }
      return value
        .split(':')
        .map((s) => s.padStart(2, '0'))
        .join(':');
    },
    getFiles(value) {
      const v = [];
      value.forEach((val) => {
        if (this.isS3File(val.fileKey) && this.isErrorLog) {
          v.push(`<a href="${this.link(val.fileKey)}">${val.name}</a>`);
        } else {
          v.push(val.name);
        }
      });
      return u.escape(v.toString());
    },
    link(fileKey) {
      const { id } = this.$route.params;
      return Router('answer_error_file', { id, key: encodeURIComponent(fileKey) });
    },
    isS3File(fileKey) {
      return fileKey.indexOf('public-form/upload') !== -1;
    },
  },
};
</script>

<style scoped></style>
