import Axios from 'axios';
import { MessageBox } from 'element-ui';
import { trans as t } from 'filters/trans';
import { isFbErrorResponse } from 'utils/api-utils';
const CUSTOM_HEADER = {
    'X-Requested-With': 'XMLHttpRequest',
};
const alertAndAbort = async (message, aborterFunc) => {
    await MessageBox.alert(t(message), t('Warning'), {
        confirmButtonText: 'OK',
        type: 'warning',
        showClose: false,
    });
    aborterFunc();
    // aborterFuncの処理中(リロードやページ遷移を想定)にAxiosの処理を完了しないように, resolveし得ないPromiseを返す.
    return new Promise(() => { });
};
const alertAndMoveToLogin = async (message) => alertAndAbort(message, () => {
    window.location.href = `${ACCOUNT_KINTONEAPP_URL}/login`;
});
const isLogout = (error) => error.response != null &&
    error.response.status === 403 &&
    isFbErrorResponse(error.response.data) &&
    error.response.data.code === 'AUTHORIZATION_ERROR';
// FB自身へのリクエストにはカスタムヘッダーを付与する
const publicFormAxiosClient = Axios.create({
    headers: CUSTOM_HEADER,
});
// kviewerなど外部へのCors目的のリクエストではカスタムヘッダに対応していない場合があるので付与しない
const publicFormCorsAxiosClient = Axios.create({
    // kintoneApp認証 + kviewer連携(CORS) 時にCookieの認証情報を送信するためwithCredentialsをtrueにしている
    withCredentials: true,
});
const mainAxiosClient = Axios.create({
    headers: CUSTOM_HEADER,
});
mainAxiosClient.interceptors.response.use((response) => response, (error) => {
    if (isLogout(error)) {
        return alertAndMoveToLogin('In case you are logging out, the page will reload.');
    }
    return Promise.reject(error);
});
export { publicFormAxiosClient, publicFormCorsAxiosClient, mainAxiosClient, CUSTOM_HEADER };
