<template>
  <el-date-picker
    v-model="val"
    :picker-options="pickerOptions"
    type="datetime"
    format="yyyy-MM-dd HH:mm"
    :disabled="!field.editable"
  ></el-date-picker>
</template>

<script>
export default {
  name: 'FbDatetime',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : '';
      },
      set(value) {
        this.$emit('update', value);
      },
    },
    pickerOptions() {
      if (this.field.pickerOptions) {
        return this.field.pickerOptions;
      }
      return {};
    },
  },
  mounted() {
    this.$el.getElementsByTagName('input')[0].setAttribute('readonly', 'readonly');
  },
};
</script>

<style scoped></style>
