import { mainAxiosClient } from 'api/axios';
import { getUriFromRouteName } from 'utils/routing-utils';
const api = {
    fetchRecipes: () => mainAxiosClient.get(getUriFromRouteName('api_recipe_index')).then((resp) => resp.data.recipes),
    fetchSimpleAuthEnabledRecipes: () => mainAxiosClient.get(getUriFromRouteName('api_simple_auth_recipes')),
    createByKintone: (bridge) => mainAxiosClient.post(getUriFromRouteName('api_recipe_create_by_kintone'), bridge),
    createByMultipleKintone: (bridges) => mainAxiosClient.post(getUriFromRouteName('api_recipe_create_by_multiple_kintone'), bridges),
    createByScenario: (scenario, bridge) => mainAxiosClient.post(getUriFromRouteName(`api_recipe_create_by_${scenario}_scenario`), bridge),
    fetch: (id) => mainAxiosClient.get(getUriFromRouteName('api_recipe_show', { id })),
    fetchByCode: (code) => mainAxiosClient.get(getUriFromRouteName('api_recipe_show_by_code', { code })),
    put: (id, recipe) => mainAxiosClient.put(getUriFromRouteName('api_recipe_edit', { id }), recipe),
    clear: (id) => mainAxiosClient.put(getUriFromRouteName('api_recipe_clear', { id })),
    remove: (id) => mainAxiosClient.delete(getUriFromRouteName('api_recipe_remove', { id })),
    setPublic: (id) => mainAxiosClient.put(getUriFromRouteName('api_recipe_public', { id })),
    setPrivate: (id) => mainAxiosClient.put(getUriFromRouteName('api_recipe_private', { id })),
    startDevelop: (id) => mainAxiosClient.put(getUriFromRouteName('api_recipe_start_develop', { id })),
    deploy: (id) => mainAxiosClient.put(getUriFromRouteName('api_recipe_deploy', { id })),
    copy: (id) => mainAxiosClient.post(getUriFromRouteName('api_recipe_copy', { id })),
    validateCustomCode: (recipeId, customCode) => mainAxiosClient.get(getUriFromRouteName('api_recipe_custom_url_validate', { recipeId }), {
        params: { customCode },
    }),
    updateCustomCode: (recipeId, customCode) => mainAxiosClient.patch(getUriFromRouteName('api_recipe_custom_url_update', { recipeId }), { customCode }),
    fetchFeatureDiffStatuses: (recipeId) => mainAxiosClient.get(getUriFromRouteName('api_recipe_feature_diff_statuses', { recipeId })),
    fetchDomainInvalidIds: () => mainAxiosClient
        .get(getUriFromRouteName('api_recipes_domain_invalid_ids'))
        .then((resp) => resp.data.recipeIds),
};
export default api;
