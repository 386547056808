import { Validator } from 'vee-validate';
import dayjs from 'dayjs';
import c from 'js/const';
import { isV4Format, ip2Long } from 'utils/ip-utils';
import { isKintoneUrl, isKintoneAppUrl, isOriginMatchedKintoneAppUrl } from 'utils/kintone-app-utils';
import ru from 'utils/record-utils';
import fu from 'utils/field-utils';
import api from 'api/bridge';
const defaultValidations = [
    {
        name: 'alpha_num_sign',
        rule: {
            validate: (value) => /^[0-9A-Z!"#$%&'()*+,-.\\/:;<=>?@[¥\]^_`{|}~]*$/i.test(value),
        },
    },
    {
        name: 'alpha_sign',
        rule: {
            validate: (value) => /^[0-9A-Z@._-]*$/i.test(value),
        },
    },
    {
        name: 'not_sign',
        rule: {
            validate: (value) => /^[^!#@$%^&*()+|~=`{}[\]:";'<>?,.\\/-]+$/.test(value),
        },
    },
    {
        name: 'not_space',
        rule: {
            validate: (value) => /^[^ ]+$/.test(value),
        },
    },
    {
        name: 'not_numeric',
        rule: {
            validate: (value) => !/^[0-9]+$/.test(value),
        },
    },
    {
        name: 'tel',
        rule: {
            validate: (value) => /^\+?([0-9]{2,4})\)?[-.]?([0-9]{2,4})[-.]?([0-9]{2,4})$/.test(value),
        },
    },
    {
        name: 'secure_access',
        rule: {
            validate: (value) => !/\.s\./.test(value),
        },
    },
    {
        name: 'date_between',
        rule: {
            /* vee-validate's date_between does not correspond to Date object. */
            validate: (value, params) => {
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                const min = params[0];
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                const max = params[1];
                let isValid = true;
                if (min) {
                    isValid && (isValid = value >= dayjs(min).toDate());
                }
                if (max) {
                    isValid && (isValid = value <= dayjs(max).toDate());
                }
                return isValid;
            },
        },
    },
    {
        name: 'datetime_between',
        rule: {
            validate: (value, params) => {
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                const min = params[0];
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                const max = params[1];
                let isValid = true;
                if (min) {
                    isValid && (isValid = value >= dayjs(min).toDate());
                }
                if (max) {
                    isValid && (isValid = value <= dayjs(max).toDate());
                }
                return isValid;
            },
        },
    },
    {
        name: 'unique',
        rule: {
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            validate: (value, params) => params.filter((v) => v === value).length <= 1,
        },
    },
    {
        name: 'not_equal_in_case_insensitive',
        rule: {
            validate: (value, params) => {
                if (typeof params === 'undefined' || !Array.isArray(params)) {
                    return true;
                }
                return value.toLowerCase() !== params[0].toLowerCase();
            },
        },
    },
    {
        name: 'required_choices',
        rule: {
            validate: (value, requiredChoices) => Array.isArray(requiredChoices) && requiredChoices.every((requiredChoice) => value.includes(requiredChoice)),
        },
    },
    {
        name: 'min_select',
        rule: {
            // @ts-expect-error ts-migrate(2569) FIXME: Type 'object | any[] | undefined' is not an array ... Remove this comment to see the full error message
            validate: (value, [min]) => value.length >= min,
        },
    },
    {
        name: 'max_select',
        rule: {
            validate: (value, [max]) => value.length <= max,
        },
    },
    {
        name: 'max_length',
        rule: {
            validate: (value, [max]) => value.length <= max,
        },
    },
    {
        name: 'ips',
        rule: {
            validate: (value) => {
                const ips = value.split('~');
                return ((ips.length === 1 && isV4Format(ips[0])) ||
                    (ips.length === 2 && isV4Format(ips[0]) && isV4Format(ips[1]) && ip2Long(ips[0]) < ip2Long(ips[1])));
            },
        },
    },
    {
        name: 'kintone_url',
        rule: {
            validate: (value) => isKintoneUrl(value),
        },
    },
    {
        // 値が kintone アプリの URL フォーマットを満たしているか
        // REMARKS: ドメインが登録されているものと一致しているかの確認は行わない. 登録kintoneドメインとの一致チェックが必要な場合は, matched_kintone_app_url を使用すること.
        name: 'kintone_app_url',
        rule: {
            validate: (value) => isKintoneAppUrl(value),
        },
    },
    {
        // 値が登録されている連携先 kintone とドメインがマッチする kintone アプリ URL か
        name: 'origin_matched_kintone_app_url',
        rule: {
            validate: (value) => {
                const origin = user?.kintone;
                return isOriginMatchedKintoneAppUrl(value, origin);
            },
        },
    },
    {
        name: 'exist_in_kviewer',
        rule: {
            validate: (value, kviewerFields) => Array.isArray(kviewerFields) && kviewerFields.map((kviewerField) => kviewerField.code).includes(value),
        },
    },
    {
        name: 'every_exist_in_kviewer',
        rule: {
            validate: (values, kviewerFields) => values.every((value) => Array.isArray(kviewerFields) && kviewerFields.map((kviewerField) => kviewerField.code).includes(value)),
        },
    },
    {
        // 文字列が公開フォームのURLとして使用可能な文字種のみで構成されている
        name: 'custom_code_regexp',
        rule: {
            validate: (value) => /^[-a-z\d\u3005-\u3006\u4e00-\u9fff\u3040-\u30ff]+$/.test(value),
        },
    },
    {
        // 標準実装のルール "max" と同じ検証内容だが, エラーメッセージに現在の文字数を含める
        name: 'max_with_message_includes_count',
        rule: {
            validate: (value, [max]) => ({
                valid: value.length <= max,
                data: { count: value.length },
            }),
        },
    },
    {
        name: 'unique_code',
        rule: {
            validate: (value, fbFields) => fu.isUniqueCode(value, fbFields),
        },
    },
    {
        name: 'reserved_code',
        rule: {
            validate: (value) => !c.reservedFieldCode.includes(value),
        },
    },
    {
        name: 'ext_in_url',
        rule: {
            validate: (value, [accepts]) => {
                const ext = new URL(value).pathname?.split('.')?.pop() ?? '';
                return accepts.includes(ext);
            },
        },
    },
];
function init() {
    defaultValidations.forEach(({ name, rule }) => {
        Validator.extend(name, rule);
    });
}
function addRenderValidation(vm) {
    Validator.extend('renderable', {
        getMessage: (_field, _params, data) => data.message,
        validate: (value) => {
            if (!vm.availableFields) {
                return { valid: true };
            }
            const record = {};
            vm.availableFields.forEach((field) => {
                if (field.code) {
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    record[field.code] = { value: ru.generateValue(field), type: field.type };
                }
            });
            const recipeId = vm.$store.state.recipe.id;
            return api
                .validateMailRendering(value, record, recipeId)
                .then((response) => ({
                valid: response.data.isValid,
                data: response.data,
            }))
                .catch((error) => ({ valid: false, data: error.data }));
        },
    });
}
function addPagerValidation(name, size, verifier, verifierFunc = null) {
    Validator.extend(name, {
        getMessage: (_field, _params, data) => {
            const pages = data.errorPages.join(', ');
            const messages = {
                en: `This is an error on ${pages} pages.`,
                ja: `${pages} ページ目にエラーがあります。`,
                cn: `第 ${pages}页上有错误`,
            };
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            return messages[locale];
        },
        validate: async (values) => {
            const validator = new Validator();
            // FIXME: validator.verify() が遅いため, values のサイズが長くなる(500 or above)と知覚可能な遅延が発生
            const verifyResults = await Promise.all(values.map((value) => validator.verify(value, verifier)));
            const verifierFuncResults = await Promise.all(values.map((value) => !verifierFunc || verifierFunc(value)));
            const errorPages = [
                ...new Set(verifyResults
                    .map(({ valid }, index) => ({ index, isValid: valid && verifierFuncResults[index] }))
                    .filter(({ isValid }) => !isValid)
                    .map(({ index }) => Math.floor(index / size) + 1)),
            ];
            return {
                valid: errorPages.length === 0,
                data: { errorPages },
            };
        },
    });
}
// TODO: 以下は削除し, named-export のみにする (現状は後方互換性のためにのみ定義)
export default {
    init,
    addRenderValidation,
    addPagerValidation,
};
export { init, addRenderValidation, addPagerValidation };
