<template>
  <span
    v-bind="$attrs"
    class="inline-flex"
    :aria-hidden="!title"
    :aria-label="title"
    role="img"
    @click="$emit('click', $event)"
  >
    <svg :width="size" :height="size" viewBox="0 0 24 24" :fill="fillColor" :class="{ 'animate-spin': spin }">
      <path
        opacity="0.25"
        d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 20.4C7.356 20.4 3.6 16.644 3.6 12C3.6 7.356 7.356 3.6 12 3.6C16.644 3.6 20.4 7.356 20.4 12C20.4 16.644 16.644 20.4 12 20.4Z"
      />
      <path
        d="M23.9996 12H20.3996C20.3996 16.644 16.6436 20.4 11.9996 20.4C9.68362 20.4 7.58362 19.464 6.05962 17.94L3.51562 20.484C5.68762 22.656 8.68762 24 11.9996 24C18.6236 24 23.9996 18.624 23.9996 12Z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'ProgressIndicator',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    title: {
      type: String,
    },
    fillColor: {
      type: String,
      default: '#0079D2',
    },
    size: {
      type: Number,
      default: 24,
    },
    spin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
