<template>
  <el-upload
    ref="el-upload"
    multiple
    :auto-upload="false"
    :with-credentials="true"
    :action="action"
    :headers="headers"
    :file-list="fileList"
    :on-change="onChange"
    :before-upload="beforeUpload"
    :on-success="onSuccess"
    :on-error="onError"
    :on-remove="onRemove"
  >
    <el-upload-inner-button max-file-size="10MB" />
  </el-upload>
</template>

<script>
import { buildUploadUrl } from 'api/public-api';
import u from 'utils/utils';
import ElUploadInnerButton from 'Common/ElUploadInnerButton';
import { CUSTOM_HEADER } from 'api/axios';
import { mapMutations, mapState } from 'vuex';

// バリデーションやアップロードの成功失敗に依らず、ファイル追加時に、すべてのファイルが処理済みかどうかを判定する
// 処理済みの場合は、バリデーションエラーでキャンセルしたファイルやアップロード失敗したファイルはfileListから削除され、statusがsuccessのファイルのみになる
function allFileUploadsProcessed(fileList) {
  return fileList.every((file) => file.status !== 'ready');
}

// el-upload.uploadFiles から update されていない (value に反映されていない) ものを抽出
function filterFilesNotUpdated(fileList) {
  // value には response は反映させないことを利用
  return fileList.filter((file) => typeof file.response !== 'undefined');
}

export default {
  name: 'FbFile',

  components: { ElUploadInnerButton },

  props: {
    field: {
      type: Object,
      default: () => ({}),
    },

    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: CUSTOM_HEADER,
      promises: new Promise((resolve) => {
        resolve();
      }),
      resolves: {},
    };
  },

  computed: {
    ...mapState(['kappauthPreview']),
    fileList: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('update', value);
      },
    },
    action() {
      return buildUploadUrl(form, this.kappauthPreview);
    },
  },

  methods: {
    ...mapMutations(['setFileUploading']),

    onChange(file) {
      // onStart (ファイルが指定された直後) 時にのみ処理を継続する.
      // (el-upload.on-change は onStart, onSuccess, onError の各イベント直後に呼ばれる)
      if (file.status !== 'ready') {
        return;
      }

      this.setFileUploading(true);

      // 同時に1ファイルずつアップロードするために, promise オブジェクトを chain
      this.promises = this.promises.finally(
        () =>
          new Promise((resolve) => {
            // アップロード終了時に resolve を呼ぶ (次のファイルの処理を開始する) ために保持
            this.resolves[file.uid] = resolve;
            this.upload(file);
          }),
      );
    },

    beforeUpload(file) {
      if (file.size < 10485760) {
        // < 10MB
        return true;
      }

      u.warning(this, u.trans('The maximum file size is %size%', { size: '10MB' }));

      // before-upload で false を返すとイベントハンドラ(onSuccess/OnError)がトリガーされないためここで呼び出す
      this.startNextFileUpload(file);

      return false;
    },

    onSuccess(_response, file, fileList) {
      this.startNextFileUpload(file);
      this.postFileUploadProcess(fileList);
    },

    onError(error, file, fileList) {
      this.startNextFileUpload(file);

      const data = JSON.parse(error.message);
      u.error(this, data.error);
      this.postFileUploadProcess(fileList);
    },

    onRemove(file, fileList) {
      // バツボタンでファイル削除する際の処理
      if (file && u.has(file, 'fileKey')) {
        const removeFileKey = file.fileKey;

        this.$emit(
          'update',
          this.value.filter((f) => f.fileKey !== removeFileKey),
        );
        return;
      }

      // beforeUpload でのバリデーションエラー時にファイル削除される際の処理
      // file.fileKey がない場合なので、「未アップロードファイルの削除 = beforeUpload でのバリデーションエラー」とみなしている
      // beforeUpload では fileList を取得することができず、全ファイル処理済みか判定するのが困難なため、 onRemove で処理している
      this.postFileUploadProcess(fileList);
    },

    upload(file) {
      // UI 機能 (プログレスバー等) を利用するために, el-upload の内部メソッドによるアップロードを行う.
      this.$refs['el-upload'].$refs['upload-inner'].upload(file.raw);
    },

    startNextFileUpload(currentFile) {
      const { uid } = currentFile;

      const resolve = this.resolves[uid];
      if (typeof resolve === 'function') {
        resolve();
      }

      delete this.resolves[uid];
    },

    postFileUploadProcess(fileList) {
      if (allFileUploadsProcessed(fileList)) {
        this.attachFilesToRecord(fileList);
        this.setFileUploading(false);
      }
    },

    // 未アップロードのファイルがある状態で update すると以下の更新フローにより, 未アップロードのファイルが
    // uploadFiles から消える (アップロードされなくなる).
    // - this.value 更新 => el-upload.fileList 更新 => el-upload.uploadFiles 更新
    // value と uploadFiles の要素が同期していても問題ないように, 全ファイルのアップロード後に update する.
    attachFilesToRecord(fileList) {
      const newFiles = filterFilesNotUpdated(fileList).map((f) => ({
        fileKey: f.response.fileKey,
        name: f.response.name,
        size: f.response.size,
      }));

      this.$emit('update', [...this.value, ...newFiles]);
    },
  },
};
</script>
