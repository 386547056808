import { publicFormAxiosClient } from 'api/axios';
import { getUriFromRouteName } from 'utils/routing-utils';
function isPreview() {
    return window.location.pathname.indexOf('/private') === 0;
}
function buildUploadUrl(form, kappauthPreview = null) {
    if (isPreview()) {
        const data = { code: form.code, kappauthPreview };
        return getUriFromRouteName('app_preview_api_recipe_upload', data);
    }
    const data = { code: form.publicCode };
    return getUriFromRouteName('app_public_api_recipe_upload', data, formOrigin);
}
function buildSubmitUrl(form, kappauthPreview = null) {
    if (isPreview()) {
        return getUriFromRouteName('app_preview_api_recipe_submit', { code: form.code, kappauthPreview });
    }
    return getUriFromRouteName('app_public_api_recipe_submit', { code: form.publicCode }, formOrigin);
}
function submit(form, verifyToken, record, kappauthPreview = null) {
    return publicFormAxiosClient.post(buildSubmitUrl(form, kappauthPreview), { record, verifyToken }, { withCredentials: true });
}
export { buildUploadUrl, submit };
