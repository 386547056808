// This file is just for outside-form and is almost the same as form.js.
// The comments begins "OUTSIDE_FORM" means that the lines around are something different from form.js.
// See 'HTML Form' section in doc/Customize for the detail.
import Axios from 'axios';
import Vue from 'vue';
import { useElementUI } from 'ElementUI/outside-form';
// @ts-expect-error "Could not find a declaration file for module 'mint-ui'." Stop to use this abandoned project.
import { DatetimePicker, Popup } from 'mint-ui';
// OUTSIDE_FORM: Use specific routings for outside-form, here prohibit useless route definitions.
// import routes from 'js/form-routing'
import VeeValidate from 'vee-validate';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';
import VueGtm from 'vue-gtm';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/vue-quill-editor` if it ex... Remove this comment to see the full error message
import VueQuillEditor from 'vue-quill-editor';
import { createRouter } from 'routers/outside-form';
import dictionary from 'validator/dic';
import store from 'store/form';
import { trans, addFormTrans, setCurrentLocale } from 'filters/trans';
import validator from 'validator/index';
// ------------------------------------------------------------
// OUTSIDE_FORM: specific imports & definitions
import 'styles/outside-form.scss';
import 'styles/tailwind-without-base.css';
// @ts-expect-error ts-migrate(2339) FIXME: Property 'version' does not exist on type 'Window ... Remove this comment to see the full error message
window.version = new Date().toLocaleDateString();
// @ts-expect-error ts-migrate(2339) FIXME: Property 'auth' does not exist on type 'Window & t... Remove this comment to see the full error message
window.auth = false;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'formUser' does not exist on type 'Window... Remove this comment to see the full error message
window.formUser = { name: null, username: null };
// @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type 'Window & t... Remove this comment to see the full error message
window.data = document.getElementById('based-data');
// @ts-expect-error ts-migrate(2339) FIXME: Property 'code' does not exist on type 'Window & t... Remove this comment to see the full error message
window.code = window.data.getAttribute('code');
// @ts-expect-error ts-migrate(2339) FIXME: Property 'host' does not exist on type 'Window & t... Remove this comment to see the full error message
window.host = window.data.getAttribute('host');
// @ts-expect-error ts-migrate(2339) FIXME: Property 'locale' does not exist on type 'Window &... Remove this comment to see the full error message
window.locale = window.data.getAttribute('locale');
// @ts-expect-error ts-migrate(2339) FIXME: Property 'kviewer' does not exist on t... Remove this comment to see the full error message
window.kviewer = 'https://viewer.kintoneapp.com';
// @ts-expect-error ts-migrate(2339) FIXME: Property 'record' does not exist on type 'Window &... Remove this comment to see the full error message
window.record = {};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'fb' does not exist on type 'Window & typ... Remove this comment to see the full error message
window.fb = {
    events: {
        form: {
            created: [],
            mounted: [],
            confirm: [],
            submit: [],
        },
        step: {
            back: [],
            next: [],
        },
        confirm: {
            created: [],
            mounted: [],
            back: [],
            submit: [],
        },
        finish: {
            created: [],
            mounted: [],
        },
        fields: {},
        kviewer: {
            record: {
                mapped: [],
            },
            records: {
                fetch: [],
                mounted: [],
            },
        },
        addValidators: null,
    },
};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'form' does not exist on type 'Window & t... Remove this comment to see the full error message
window.form = {};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'fields' does not exist on type 'Window &... Remove this comment to see the full error message
window.fields = [];
// @ts-expect-error ts-migrate(2339) FIXME: Property 'plan' does not exist on type 'Window & t... Remove this comment to see the full error message
window.plan = 'trial';
// @ts-expect-error ts-migrate(2339) FIXME: Property 'formOrigin' does not exist on type 'Window'
window.formOrigin = null;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'kviewerViewHost' does not exist on type 'Window'
window.kviewerViewHost = null;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'kviewerViewCommonHost' does not exist on type 'Window'
window.kviewerViewCommonHost = null;
// @ts-expect-error ts-migrate(2339)
setCurrentLocale(locale);
const fetchFormData = function () {
    return (
    // versionCheckが出来ないので、HTML出力のフォームfetch時はaxiosを直接使用する
    Axios
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'host'.
        .get(`${host}/public/api/form/${code}`, { withCredentials: true })
        .then((res) => {
        /* eslint-disable no-global-assign */
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'plan'.
        plan = res.data.plan;
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
        form = res.data.form;
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
        if (typeof form !== 'undefined' && form.isTranslate) {
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
            addFormTrans(form.dictionary);
        }
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fields'.
        fields = res.data.fields;
        formOrigin = res.data.formOrigin;
        kviewerViewHost = res.data.kviewerViewHost;
        kviewerViewCommonHost = res.data.kviewerViewCommonHost;
        /* eslint-enable no-global-assign */
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fields'.
        fields.forEach((field) => {
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
            fb.events.fields[field.code] = {
                changed: [],
            };
            if (field.type === 'SUBTABLE') {
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                fb.events.fields[field.code].add = [];
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                fb.events.fields[field.code].remove = [];
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                fb.events.fields[field.code].fields = {};
                field.fields.forEach((tableField) => {
                    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                    fb.events.fields[field.code].fields[tableField.code] = {
                        changed: [],
                    };
                });
            }
        });
    })
        .catch((err) => {
        // @ts-expect-error ts-migrate(2304)
        const message = trans(err.response.data.error, {}, null, locale);
        const app = document.getElementById('app');
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        app.innerHTML = `<div class="ui negative message">${message}</div>`;
    }));
};
const router = createRouter();
// ------------------------------------------------------------
Vue.filter('trans', trans);
validator.init();
Vue.use(VeeValidate, {
    inject: false,
    delay: 1,
    events: 'input|blur|keyup',
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'locale'.
    locale,
    dictionary,
});
useElementUI(Vue);
Vue.component(DatetimePicker.name, DatetimePicker);
Vue.component(Popup.name, Popup);
Vue.use(VueQuillEditor);
Vue.use(VueScrollTo, {
    offset: -100,
});
// ステージング環境のドメインの変更には追従せずに一旦本番環境のドメインのみを指定.
// sentry/browser 版に移行する時にステージング環境でも有効にする.
if (window.location.hostname.includes('kintoneapp.com')) {
    Raven.config('https://aa594c0c19914c098a255946f59b25de@sentry.io/304045', {
        ignoreErrors: [
            // フォームを埋め込んだ元のコードの問題でありFBの問題ではないため, iframe に埋め込まれたフォームに対して iframe の外からJSでアクセスした際に発生する下記のメッセージのエラーを無視する.
            // > SecurityError: Failed to read a named property 'document' from 'Window': Blocked a frame with origin "(フォームのドメイン)" from accessing a cross-origin frame.
            /Blocked a frame with origin/,
            // Sentry SDK 内部の問題と思われるため, 下記のメッセージのエラーは無視する.
            // > Object Not Found Matching Id:5, MethodName:update, ParamCount:4
            // see: https://github.com/getsentry/sentry-javascript/issues/3440
            /Object Not Found Matching Id:\d+, MethodName:update, ParamCount:\d+/,
        ],
    })
        .addPlugin(RavenVue, Vue)
        .install();
}
window.onpageshow = function (event) {
    if (event.persisted) {
        window.location.reload();
    }
};
// OUTSIDE_FORM: Fetch the form data via Ajax using #app-data element.
fetchFormData().then(() => {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
    if (form.googleAnalytics && form.trackingId) {
        Vue.use(VueGtag, {
            config: {
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
                id: form.trackingId,
            },
        }, router);
    }
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
    if (form.isUseGoogleTagManager && form.googleTagManagerContainerId) {
        Vue.use(VueGtm, {
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
            id: form.googleTagManagerContainerId,
            vueRouter: router,
        });
    }
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
    if (form.isUseRecaptcha && RECAPTCHA_SITE_KEY !== 'changeMe') {
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'RECAPTCHA_SITE_KEY'.
        Vue.use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY });
    }
    /* eslint-disable no-new */
    new Vue({
        router,
        store,
        el: '#app',
        render: (h) => h('router-view'),
    });
});
