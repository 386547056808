import { PLANS } from 'const/plans';
import c from 'js/const';
/**
 * 回答保存プロセスの種類毎に選択可能な実行条件の種類
 */
export const IFT_TYPES_BY_BRIDGE_TYPE = {
    AdminNotificationBridge: ['all', 'success', 'new', 'edit', 'locale', 'field'],
    AutoIncrementBridge: ['all', 'success', 'new', 'locale', 'field'],
    AutoReplyBridge: ['all', 'error', 'success', 'new', 'edit', 'locale', 'field'],
    CalcBridge: ['all', 'field'],
    ConcatBridge: ['all', 'locale', 'field'],
    ErrorNotificationBridge: ['error'],
    KintoneBridge: ['all', 'error', 'success', 'new', 'edit', 'locale', 'field'],
    KmailerBridge: ['all', 'error', 'success', 'new', 'edit', 'locale', 'field'],
    ReplaceBridge: ['all', 'locale', 'field'],
};
export const IFT_TYPE_AVAILABLE_FIELDS_BY_FIELD = [
    'SINGLE_LINE_TEXT',
    'MULTI_LINE_TEXT',
    'NUMBER',
    'CHECK_BOX',
    'RADIO_BUTTON',
    'DROP_DOWN',
    'MULTI_SELECT',
    'RATE',
    'SLIDER',
];
export const AVAILABLE_FIELD_TYPES_BY_BRIDGE_TYPE = {
    CalcBridge: ['NUMBER', 'CALC', 'SUBTABLE', 'RATE', 'SLIDER'],
    ConcatBridge: ['SINGLE_LINE_TEXT'],
    ReplaceBridge: c.fieldTypes.map((field) => field.type),
};
export const BRIDGE_TYPES = {
    AdminNotificationBridge: 'Admin notification',
    AutoIncrementBridge: 'Auto number assignment',
    AutoReplyBridge: 'Auto reply bridge',
    CalcBridge: 'Number calculation',
    ConcatBridge: 'Text connect',
    ErrorNotificationBridge: 'Error notification',
    KintoneBridge: 'Save record to Kintone app',
    KmailerBridge: 'kMailer bridge',
    ReplaceBridge: 'Text replacement',
};
/**
 * メール系プロセスのモーダルサイズ判定に使用
 */
export const MAIL_BRIDGE_TYPES = [
    'AutoReplyBridge',
    'AdminNotificationBridge',
    'ErrorNotificationBridge',
    'KmailerBridge',
];
/**
 * プランごとに設定可能な各回答保存プロセスの最大数
 */
export const MAX_CONFIGURABLE_COUNT_BY_PLAN_AND_BRIDGE = {
    [PLANS.NONE]: {
        AdminNotificationBridge: 0,
        AutoIncrementBridge: 0,
        AutoReplyBridge: 0,
        CalcBridge: 0,
        ConcatBridge: 0,
        ErrorNotificationBridge: 0,
        KintoneBridge: 0,
        KmailerBridge: 0,
        ReplaceBridge: 0,
    },
    [PLANS.LIGHT]: {
        AdminNotificationBridge: 0,
        AutoIncrementBridge: 0,
        AutoReplyBridge: 0,
        CalcBridge: 0,
        ConcatBridge: 0,
        ErrorNotificationBridge: 1,
        KintoneBridge: 1,
        KmailerBridge: 0,
        ReplaceBridge: 0,
    },
    [PLANS.STANDARD]: {
        AdminNotificationBridge: 0,
        AutoIncrementBridge: 0,
        AutoReplyBridge: 1,
        CalcBridge: 0,
        ConcatBridge: 0,
        ErrorNotificationBridge: 1,
        KintoneBridge: 1,
        KmailerBridge: 0,
        ReplaceBridge: 0,
    },
    [PLANS.PREMIUM]: {
        AdminNotificationBridge: Infinity,
        AutoIncrementBridge: Infinity,
        AutoReplyBridge: Infinity,
        CalcBridge: Infinity,
        ConcatBridge: Infinity,
        ErrorNotificationBridge: Infinity,
        KintoneBridge: Infinity,
        KmailerBridge: Infinity,
        ReplaceBridge: Infinity,
    },
    [PLANS.PROFESSIONAL]: {
        AdminNotificationBridge: Infinity,
        AutoIncrementBridge: Infinity,
        AutoReplyBridge: Infinity,
        CalcBridge: Infinity,
        ConcatBridge: Infinity,
        ErrorNotificationBridge: Infinity,
        KintoneBridge: Infinity,
        KmailerBridge: Infinity,
        ReplaceBridge: Infinity,
    },
    [PLANS.ENTERPRISE]: {
        AdminNotificationBridge: Infinity,
        AutoIncrementBridge: Infinity,
        AutoReplyBridge: Infinity,
        CalcBridge: Infinity,
        ConcatBridge: Infinity,
        ErrorNotificationBridge: Infinity,
        KintoneBridge: Infinity,
        KmailerBridge: Infinity,
        ReplaceBridge: Infinity,
    },
    [PLANS.TRIAL]: {
        AdminNotificationBridge: Infinity,
        AutoIncrementBridge: Infinity,
        AutoReplyBridge: Infinity,
        CalcBridge: Infinity,
        ConcatBridge: Infinity,
        ErrorNotificationBridge: Infinity,
        KintoneBridge: Infinity,
        KmailerBridge: Infinity,
        ReplaceBridge: Infinity,
    },
};
