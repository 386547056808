<template>
  <tr>
    <table-field
      v-for="child in field.fields"
      :key="child.id"
      :table-field="field"
      :table-index="tableIndex"
      :table-record="tableRecord"
      :field="child"
    ></table-field>
    <td>
      <a v-if="isAddable" class="ui circular blue icon button" @click="add">
        <i class="plus icon"></i>
      </a>
      <a v-if="isRemovable" class="ui circular orange icon button" @click="remove">
        <i class="remove icon"></i>
      </a>
    </td>
  </tr>
</template>

<script>
import TableField from 'Field/TableField';

export default {
  name: 'TableRow',
  components: { TableField },
  props: ['field', 'tableRecord', 'tableIndex', 'isAddable', 'isRemovable'],
  methods: {
    add() {
      this.$emit('add');
    },
    remove() {
      this.$emit('remove', this.tableIndex);
    },
  },
};
</script>

<style scoped></style>
