<template>
  <div class="ui segment">
    <h2>{{ 'Results of post-submission steps' | trans }}</h2>
    <div class="ui warning message">
      {{ '*Displayed only when previewing' | trans }}
    </div>
    <table v-if="logs && logs.length > 0" class="ui celled table">
      <thead>
        <tr>
          <th>{{ 'Post-submission step name' | trans }}</th>
          <th class="text-center">{{ 'Result' | trans }}</th>
          <th>{{ 'Message' | trans }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(log, index) in logs" :key="index">
          <td>{{ getBridgeTypeLabel(log.bridge.type) | trans }}</td>
          <td class="text-center">{{ (log.status.charAt(0).toUpperCase() + log.status.slice(1)) | trans }}</td>
          <td>
            <div v-if="log.message">{{ log.message | trans }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { BRIDGE_TYPES } from 'const/bridge';

export default {
  name: 'ErrorLogs',

  computed: {
    logs() {
      return this.$store.state.logs;
    },
  },

  methods: {
    getBridgeTypeLabel(bridgeType) {
      return BRIDGE_TYPES[bridgeType];
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center !important;
}
</style>
