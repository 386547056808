<template>
  <div>
    <el-button plain class="mobile-dropdown" @click="visible = true">
      <div v-if="value && value.length > 0" class="ui labels" style="display: inline-block; width: 90%">
        <div v-for="v in value" :key="v" class="ui basic label">{{ translate(v) }}</div>
      </div>
      <div v-else style="display: inline-block; width: 90%">{{ 'Please select' | trans }}</div>
      <i class="el-icon-arrow-down" style="float: right"></i>
    </el-button>
    <mt-popup v-model="visible" position="bottom" class="mobile-popup-content">
      <fb-mobile-check-box :field="field" :value="value" @update="update"></fb-mobile-check-box>
    </mt-popup>
  </div>
</template>

<script>
import u from 'utils/utils';
import FbMobileCheckBox from 'MobileField/CheckBox';

export default {
  name: 'FbMultiSelect',
  components: { FbMobileCheckBox },
  props: ['field', 'value'],
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    visible() {
      u.scroll(this);
    },
  },
  methods: {
    update(value) {
      this.$emit('update', value);
    },
    translate(option) {
      if (this.$store.state.form.isTranslate) {
        return u.trans(option);
      }
      return option;
    },
  },
};
</script>

<style scoped>
.mobile-dropdown {
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}
.ui.basic.label {
  color: #606266;
  font-weight: normal;
}
.mobile-popup-content {
  width: 100%;
  height: 60%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
</style>
