import dayjs from 'dayjs';
import merge from 'deepmerge';
import fu from './field-utils';
import util, { parseQueryString } from './utils';
const q = parseQueryString(window.location.search);
const u = {
    createValueByStringData: (type, value) => {
        switch (type) {
            case 'MULTI_LINE_TEXT':
                return value.replace(/\\n/g, '\n');
            case 'CHECK_BOX':
            case 'MULTI_SELECT':
            case 'CASCADER':
                if (!value || value === '') {
                    return [];
                }
                return value.split(',');
            case 'FILE':
            case 'SUBTABLE':
                return JSON.parse(value);
            case 'RICH_TEXT':
                break;
            case 'DATE':
            case 'DATETIME': {
                const d = dayjs(value);
                if (d.isValid()) {
                    return d.toDate();
                }
                return value;
            }
            default:
                return value;
        }
        return undefined;
    },
    createValueByDefaultValue: (field) => {
        switch (field.type) {
            case 'SUBTABLE': {
                const defaultRecord = u.defaultTableRecord(field);
                if (field.minLength !== null && field.minLength > 1) {
                    const defaultRecords = [];
                    for (let i = 0; i < field.minLength; i += 1) {
                        defaultRecords.push(merge({}, defaultRecord));
                    }
                    return defaultRecords;
                }
                return [defaultRecord];
            }
            case 'CHECK_BOX':
            case 'MULTI_SELECT':
            case 'CASCADER':
                return field.defaultValue ? field.defaultValue : [];
            case 'FILE':
                return [];
            case 'NUMBER':
            case 'RATE':
            case 'SLIDER':
                return !field.defaultValue ? 0 : parseInt(field.defaultValue, 10);
            case 'DROP_DOWN':
                return field.defaultValue;
            case 'RADIO_BUTTON':
                if (field.options.indexOf(field.defaultValue) < 0) {
                    return field.options.length > 0 ? field.options[0] : '';
                }
                return field.defaultValue;
            case 'TIME':
                if (field.defaultValue === 'NOW') {
                    const d = new Date();
                    return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
                }
                break;
            case 'DATE': {
                if (field.defaultValue === 'Invalid date' || field.defaultValue === '' || !field.defaultValue) {
                    return '';
                }
                if (field.defaultValue === 'NOW') {
                    return new Date();
                }
                const d = dayjs(field.defaultValue);
                if (d.isValid()) {
                    return d.toDate();
                }
                break;
            }
            case 'DATETIME':
                if (field.defaultValue === 'NOW') {
                    return new Date();
                }
                break;
            default:
                break;
        }
        if (field.code && field.code !== '') {
            return field.defaultValue ? field.defaultValue : '';
        }
        return '';
    },
    generateValue: (field) => {
        if (field.code in q) {
            return u.createValueByStringData(field.type, q[field.code]);
        }
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'formUser'.
        if (typeof formUser !== 'undefined' && formUser.name && field.code === '__authenticationName__') {
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'formUser'.
            return formUser.name;
        }
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'formUser'.
        if (typeof formUser !== 'undefined' && formUser.username && field.code === '__authenticationUsername__') {
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'formUser'.
            return formUser.username;
        }
        // @ts-expect-error FIXME: 'formFormMailUser'.
        if (typeof formFormMailUser !== 'undefined' && field.code === '__kintoneAppAuthenticationEmail__') {
            // @ts-expect-error FIXME: 'formFormMailUser'.
            return formFormMailUser.email;
        }
        return u.createValueByDefaultValue(field);
    },
    mergeKviewerRecord: (fields, record, kViewerRecord) => {
        fields.forEach((field) => {
            if (util.has(kViewerRecord, field.code)) {
                const kvValue = kViewerRecord[field.code].value;
                if (field.type === 'SUBTABLE') {
                    record[field.code] = { value: [], type: field.type };
                    kvValue.forEach((kvTableRecord) => {
                        const tableRecord = u.createValueByDefaultValue(field)[0];
                        u.mergeKviewerRecord(field.fields, tableRecord.value, kvTableRecord.value);
                        tableRecord.id = kvTableRecord.id;
                        record[field.code].value.push(tableRecord);
                    });
                }
                else if (field.type === 'DATE' || field.type === 'DATETIME') {
                    if (!kvValue) {
                        record[field.code] = { value: '', type: field.type };
                    }
                    else {
                        const d = dayjs(kvValue);
                        if (d.isValid()) {
                            record[field.code] = { value: d.toDate(), type: field.type };
                        }
                        else {
                            record[field.code] = { value: '', type: field.type };
                        }
                    }
                }
                else {
                    record[field.code] = { value: kvValue, type: field.type };
                }
            }
        });
    },
    defaultTableRecord: (field) => {
        const tr = { value: {} };
        field.fields.forEach((child) => {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            tr.value[child.code] = { value: u.generateValue(child), type: child.type };
        });
        return tr;
    },
    fixingRecord: (fields, record) => {
        u.formatDate(record);
        u.splitCascaderData(fields, record);
    },
    formatDate: (record) => {
        Object.values(record).forEach((v) => {
            if (v.type === 'DATE' && util.isDateObject(v.value)) {
                v.value = dayjs(v.value).format('YYYY-M-D');
            }
            else if (v.type === 'DATETIME' && util.isDateObject(v.value)) {
                v.value = v.value.toISOString();
            }
            else if (v.type === 'SUBTABLE') {
                v.value.forEach((tableRecord) => {
                    u.formatDate(tableRecord.value);
                });
            }
        });
    },
    splitCascaderData: (fields, record) => {
        fields.forEach((field) => {
            if (field.type === 'CASCADER' && util.has(record, field.code)) {
                const depth = fu.depthOptions(field.options);
                for (let i = 0; i < depth; i += 1) {
                    const v = record[field.code].value.length > i ? record[field.code].value[i] : '';
                    record[`${field.code}_split_cascader_${i + 1}`] = { value: v, type: 'SINGLE_LINE_TEXT' };
                }
            }
            else if (field.type === 'SUBTABLE') {
                record[field.code].value.forEach((tableRecord) => {
                    u.splitCascaderData(field.fields, tableRecord.value);
                });
            }
        });
    },
};
export default u;
