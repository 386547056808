<template>
  <div>
    <div class="navigation" :class="{ expanded }">
      <div class="navigation-wrapper">
        <div class="navigation-content">
          <div v-if="expanded" class="navigation-content__main">
            <div class="p-4">
              <div class="navigation-content__main__title">
                {{ 'Previewing' | trans }}
              </div>

              <div class="navigation-content__main__description">
                {{ 'Only logged-in user can access this page.' | trans }}
              </div>
            </div>

            <div
              v-if="form.isUseMailAuthentication"
              class="flex items-center justify-between break-all border-0 border-t border-dashed border-[#e9e9e9] p-4"
            >
              <div>
                <p class="m-0 text-xs text-[#888888]">{{ 'Displaying user' | trans }}</p>
                <p>{{ kappauthPreview }}</p>
              </div>
              <i class="ui icon cog large cursor-pointer" @click="openUserSelectDialog = true" />
            </div>
          </div>
        </div>

        <div class="navigation-button" @click="toggle">
          <unfold-less-horizontal-icon v-if="expanded" :size="18" />
          <unfold-more-horizontal-icon v-else :size="18" />
        </div>
      </div>
    </div>
    <kappauth-preview-select-dialog
      v-if="openUserSelectDialog"
      :recipe-code="recipeCode"
      can-close
      @close="() => (openUserSelectDialog = false)"
    />
  </div>
</template>

<script>
import UnfoldMoreHorizontalIcon from 'vue-material-design-icons/UnfoldMoreHorizontal';
import UnfoldLessHorizontalIcon from 'vue-material-design-icons/UnfoldLessHorizontal';
import { mapState } from 'vuex';
import KappauthPreviewSelectDialog from 'Preview/KappauthPreviewSelectDialog';

export default {
  name: 'PreviewNavigation',

  components: {
    KappauthPreviewSelectDialog,
    UnfoldMoreHorizontalIcon,
    UnfoldLessHorizontalIcon,
  },

  data() {
    return {
      expanded: true,
      openUserSelectDialog: false,
    };
  },

  computed: {
    ...mapState(['form', 'kappauthPreview']),
    recipeCode() {
      return form.code;
    },
  },

  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  top: 8px;
  right: 8px;

  margin: 0;
  padding: 0;

  background-color: #ffffff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));

  // loading-mask の上に重ねる
  z-index: 3000;

  width: 32px;
  border-radius: 16px;

  // アイコンの配置
  .navigation-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .navigation-button {
      position: absolute;
      margin: 0;
      padding: 0;
      cursor: pointer;

      // text descender により SVG がボックスの下へずれるのを回避
      line-height: 0;

      top: 7px;
      right: 7px;
    }
  }

  &:not(.expanded) {
    height: 32px;

    &:hover {
      background-color: #f2f2f2;
    }
  }

  // テキスト表示時
  &.expanded {
    width: 344px;
    border-radius: 4px;

    .navigation-wrapper {
      .navigation-button {
        top: 16px;
        right: 16px;
      }
    }

    // テキストの配置
    .navigation-content {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 21px;

      &__main {
        &__title {
          font-weight: 700;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
