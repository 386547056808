<template>
  <a tabindex="0" :class="['ui teal labeled icon button fb-submit', { disabled }]" @click="post" @keydown.enter="post">
    <i class="check icon"></i>
    {{ 'Post' | trans }}
  </a>
</template>

<script>
export default {
  name: 'PostButton',
  props: {
    disabled: { type: Boolean, default: false },
  },
  methods: {
    post() {
      if (this.disabled) return;
      this.$emit('post');
    },
  },
};
</script>

<style scoped></style>
