<template>
  <a tabindex="0" :class="['ui orange labeled icon button fb-back', { disabled }]" @click="back" @keydown.enter="back">
    <i :class="[icon, 'icon']"></i>
    {{ 'Back' | trans }}
  </a>
</template>

<script>
export default {
  name: 'NextButton',
  props: {
    icon: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style scoped></style>
