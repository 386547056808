<template>
  <el-select
    v-model="val"
    class="w-full min-w-[60px]"
    filterable
    :clearable="!field.required"
    :disabled="!field.editable"
  >
    <el-option
      v-for="(option, key) in field.options"
      :key="key"
      :label="label(option)"
      :value="option"
      style="word-wrap: break-word; white-space: normal; height: auto; min-height: 34px"
    ></el-option>
  </el-select>
</template>

<script>
import isMobile from 'ismobilejs';
import u from 'utils/utils';

export default {
  name: 'FbDropDown',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : '';
      },
      set(value) {
        this.$emit('update', value);
        if (isMobile().apple.device) {
          this.$nextTick(() => {
            document.activeElement.blur();
          });
        }
      },
    },
  },
  methods: {
    label(option) {
      if (this.$store.state.form.isTranslate) {
        return u.trans(option);
      }
      return option;
    },
  },
};
</script>

<style scoped></style>
