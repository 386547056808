<template>
  <div class="ui vertical buttons">
    <el-button v-for="(option, key) in field.options" :key="key" class="mobile-button" @click="select(option)">
      <span :class="['el-radio__input', { 'is-checked': val === option }]">
        <span class="el-radio__inner"></span>
      </span>
      {{ translate(option) }}
    </el-button>
  </div>
</template>

<script>
import u from 'utils/utils';

export default {
  name: 'FbMobileRadioButton',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : '';
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  methods: {
    select(option) {
      this.val = option;
      this.$emit('close');
    },
    translate(option) {
      if (this.$store.state.form.isTranslate) {
        return u.trans(option);
      }
      return option;
    },
  },
};
</script>

<style scoped>
.ui.vertical.buttons {
  width: 100%;
}
.ui.vertical.buttons > .mobile-button:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}
.ui.vertical.buttons > .mobile-button {
  width: 100%;
  margin-left: 0;
  word-wrap: break-word;
  white-space: normal;
  text-align: left;
}
.ui.vertical.buttons > .mobile-button:not(:first-child):not(:last-child) {
  border-bottom: 0;
  border-radius: 0;
}
.ui.vertical.buttons > .mobile-button:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
