<template>
  <div class="ui vertical buttons">
    <el-button v-for="(option, key) in field.options" :key="key" class="mobile-button" @click="toggle(val, option)">
      <span :class="['el-checkbox__input', { 'is-checked': val.indexOf(option) >= 0 }]">
        <span class="el-checkbox__inner"></span>
      </span>
      {{ translate(option) }}
    </el-button>
  </div>
</template>

<script>
import u from 'utils/utils';

export default {
  name: 'FbMobileCheckBox',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : [];
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  methods: {
    toggle(val, option) {
      const index = val.indexOf(option);
      if (index >= 0) {
        val.splice(index, 1);
      } else {
        val.push(option);
      }
      this.$emit('update', val);
    },
    translate(option) {
      if (this.$store.state.form.isTranslate) {
        return u.trans(option);
      }
      return option;
    },
  },
};
</script>

<style scoped>
.ui.vertical.buttons {
  width: 100%;
}
.ui.vertical.buttons > .mobile-button:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}
.ui.vertical.buttons > .mobile-button {
  width: 100%;
  margin-left: 0;
  word-wrap: break-word;
  white-space: normal;
  text-align: left;
}
.ui.vertical.buttons > .mobile-button:not(:first-child):not(:last-child) {
  border-bottom: 0;
  border-radius: 0;
}
.ui.vertical.buttons > .mobile-button:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.el-checkbox__input {
  position: relative !important;
  margin-top: 0 !important;
}
</style>
