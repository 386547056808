<template>
  <div v-show="pageCount > 1">
    <div class="ui one column stackable center aligned page mt0 grid">
      <div class="column twelve wide">
        <el-pagination
          layout="prev, pager, next"
          :current-page="currentPage"
          :page-count="pageCount"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <div v-show="errors.has(`table-pager-${fieldCode}`)" class="el-form-item__error">
      {{ errors.first(`table-pager-${fieldCode}`) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TablePagination',
  inject: ['$validator'],
  props: {
    currentPage: { type: Number, default: 1 },
    pageCount: { type: Number, default: 1 },
    changePage: { type: Function, default: () => {} },
    fieldCode: { type: String, default: '' },
  },
};
</script>

<style scoped></style>
