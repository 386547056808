<template>
  <div v-if="isAvailable" class="row kviewer-lookup">
    <form @submit.prevent>
      <el-input
        v-model="val"
        class="kviewer-lookup-input"
        :disabled="!field.editable"
        :placeholder="'Search' | trans"
        @keyup.native.enter="searchRecords"
      >
        <template #append>
          <el-button
            v-if="isSearchable"
            class="kviewer-lookup-button"
            type="primary"
            icon="el-icon-search"
            @click="searchRecords"
          >
          </el-button>
        </template>
      </el-input>
      <el-dialog
        :title="getLabel(field.label)"
        custom-class="kviewer-lookup-modal"
        :visible.sync="open"
        top="0%"
        :width="isMobile ? '100%' : '80%'"
        :before-close="close"
      >
        <el-input
          v-model="val"
          class="kviewer-lookup-input"
          :disabled="!field.editable"
          :placeholder="'Search' | trans"
          icon="search"
        >
          <template #append>
            <el-button
              v-if="isSearchable"
              class="kviewer-lookup-button"
              type="primary"
              icon="el-icon-search"
              @click="searchRecords"
            ></el-button>
          </template>
        </el-input>
        <table class="ui celled unstackable table">
          <thead>
            <tr>
              <th width="70px"></th>
              <th v-for="code in lookup.labelFields" :key="code">
                {{ getKviewerLabel(code) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, index) in records" :key="index">
              <td style="text-align: center">
                <el-button type="primary" size="small" @click="select(record)">
                  {{ 'Select' | trans }}
                </el-button>
              </td>
              <td v-for="code in lookup.labelFields" :key="code">
                <value-view
                  v-if="Object.hasOwn(record, code) && Object.hasOwn(record[code], 'value')"
                  :field="{ type: record[code].type }"
                  :value="record[code].value"
                  :is-translate="false"
                  :is-error-log="false"
                ></value-view>
              </td>
            </tr>
          </tbody>
        </table>
        <el-pagination
          layout="prev, pager, next"
          :current-page="page"
          :total="total"
          :page-size="num"
          @current-change="changePage"
        ></el-pagination>
      </el-dialog>
    </form>
  </div>
</template>

<script>
import { Loading, Notification } from 'element-ui';
import u from 'utils/utils';
import fu from 'utils/field-utils';
import ku from 'utils/kviewer-utils';
import { fetchRecords as fetchViewRecords } from 'api/kviewer';
import { planGtEq } from 'utils/plan-utils';
import ValueView from 'Public/FieldView/ValueView';

export default {
  name: 'FbKviewerLookup',
  components: { ValueView },
  props: ['field', 'value', 'tableField'],
  data() {
    return {
      open: false,
      page: 1,
      num: 20,
      records: [],
      total: 0,
      isMobile: u.isMobile(),
    };
  },
  computed: {
    val: {
      get() {
        return this.value ? this.value : '';
      },
      set(value) {
        this.$emit('update', value);
      },
    },
    lookup() {
      return this.field.lookup;
    },
    kviewerFields() {
      return this.lookup.view.kintoneApp.fields;
    },
    isAvailable() {
      return this.field.isUseLookup && planGtEq(plan, 'premium');
    },
    isSearchable() {
      return !(this.lookup.isPerfectMatch && this.value === '');
    },
  },
  created() {
    if (!u.has(this.lookup, 'isPerfectMatch')) {
      this.lookup.isPerfectMatch = false;
    }
    if (this.value) {
      this.fetchRecords();
    }
  },
  methods: {
    searchRecords() {
      if (!this.isSearchable) {
        return;
      }
      this.open = true;
      this.page = 1;
      this.fetchRecords();
    },
    async fetchRecords() {
      const loadingInstance = Loading.service({ fllscreen: true, lock: true });
      await this.$nextTick(); // wait until loading mask element surely shown

      try {
        const viewCode = this.lookup.view?.viewCode;
        if (viewCode == null) {
          throw new Error('Failed to fetch');
        }

        const response = await fetchViewRecords(
          this.lookup.view.viewCode,
          this.page,
          form.kviewerSubdomain,
          this.createAdditionalFilters(),
        );

        const records = ku.changeKViewerRecordsToFbRecords(response.data.records);

        this.$store.commit('callEvents', {
          events: fb.events.kviewer.records.fetch,
          params: { records, field: this.field.code },
        });

        this.$nextTick(() => {
          this.$store.commit('callEvents', {
            events: fb.events.kviewer.records.mounted,
            params: { records, field: this.field.code },
          });
        });

        this.records = records;
        this.num = Number(response.data.num);
        this.total = Number(response.data.totalCount);
        if (this.value.length > 0 && records.length === 1) {
          this.select(records[0]);
        } else if (this.field.required && records.length > 0) {
          this.select(records[0]);
        }
      } catch (e) {
        if (u.pickErrorMessage(e)) {
          let errorMessage = u.trans("Can't get record information.");
          if (u.pickErrorMessage(e)) {
            errorMessage += `(${u.trans(u.pickErrorMessage(e))})`;
          }
          errorMessage += u.trans('Please give it some time and try again.');
          Notification.error({ title: u.trans('Error'), message: errorMessage });
        }
      } finally {
        loadingInstance.close();
      }
    },
    createAdditionalFilters() {
      if (this.value === '') {
        return [];
      }
      const filters = [];
      const self = this;
      const addFilter = (code) => {
        const field = self.kviewerFields.find((f) => f.code === code);
        if (!field) {
          return;
        }
        if (['SINGLE_LINE_TEXT', 'LINK'].indexOf(field.type) !== -1) {
          filters.push({
            with: 'or',
            field: code,
            sign: self.lookup.isPerfectMatch ? '=' : 'like',
            value: self.value,
          });
        } else if (['NUMBER', 'CALC', 'RECORD_NUMBER'].indexOf(field.type) !== -1) {
          filters.push({
            with: 'or',
            field: code,
            sign: '=',
            value: self.value,
          });
        }
      };
      if (this.lookup.searchField) {
        addFilter(this.lookup.searchField);
      } else {
        this.lookup.labelFields.forEach((code) => {
          addFilter(code);
        });
      }
      return filters;
    },
    getLabel(option) {
      if (this.$store.state.form.isTranslate) {
        return u.trans(option);
      }
      return option;
    },
    getKviewerLabel(code) {
      const labelField = this.kviewerFields.find((field) => field.code === code);
      if (!labelField) {
        return '';
      }
      return this.getLabel(labelField.label);
    },
    select(record) {
      this.lookup.fieldMappings.forEach((map) => {
        if (!u.has(record, map.relatedField)) {
          return;
        }
        if (this.tableField) {
          const field = fu.findFieldByCode(map.field, this.tableField.fields);
          if (!field) {
            return;
          }
          const vm = fu.findTableFieldVm(field, this.$parent.$parent.$children);
          if (!vm) {
            return;
          }
          vm.update(record[map.relatedField].value);
        } else {
          const field = fu.findFieldByCode(map.field, this.$store.state.fields);
          if (!field) {
            return;
          }
          const vm = fu.findFieldVm(field, this.$root.$children);
          if (!vm) {
            return;
          }
          vm.update(record[map.relatedField].value);
        }
      });
      this.close();
    },
    close() {
      this.open = false;
    },
    changePage(page) {
      if (!this.isSearchable) {
        return;
      }
      this.page = page;
      this.fetchRecords();
    },
  },
};
</script>
