<template>
  <div :class="{ fields: field.optionOrder === 'horizontal' }">
    <div v-for="(option, key) in field.options" :key="key" class="field">
      <el-radio
        v-model="val"
        class="radio"
        :label="option"
        :disabled="!field.editable"
        style="word-wrap: break-word; white-space: normal"
      >
        {{ label(option) }}
      </el-radio>
    </div>
  </div>
</template>

<script>
import u from '../../../utils/utils';

export default {
  name: 'FbRadioButton',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : '';
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  methods: {
    label(option) {
      if (this.$store.state.form.isTranslate) {
        return u.trans(option);
      }
      return option;
    },
  },
};
</script>

<style scoped>
.ui.form .fields {
  display: block;
  margin-bottom: 0;
}
.fields > .field {
  display: inline-block;
}
.field ::v-deep .el-radio {
  padding-left: 30px;
}
.field ::v-deep .el-radio__input {
  margin-left: -28px;
}
</style>
