import { mainAxiosClient } from 'api/axios';
import { getUriFromRouteName } from 'utils/routing-utils';
import { compressObject } from 'utils/compression-utils';
function fetchFormMailUsers(recipeId, limit = null, offset = null, excludeEmails = null, searchWords = null, sortKey = null, isOrderAsc = true) {
    return mainAxiosClient.get(getUriFromRouteName('api_form_mail_users', {
        recipeId,
        limit,
        offset,
        excludeEmails,
        searchWords,
        sortKey,
        isOrderAsc,
    }));
}
function addUser(recipeId, ffmuWithEmail) {
    return mainAxiosClient.post(getUriFromRouteName('api_form_mail_user_add', { recipeId }), {
        ffmuWithEmail,
    });
}
function removeUser(recipeId, ffmuWithEmail) {
    return mainAxiosClient.delete(getUriFromRouteName('api_form_mail_user_remove', {
        recipeId,
        ffmuWithEmail,
    }));
}
function updateUser(recipeId, updatedFfmuWithEmail, previousFfmuWithEmail) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_mail_user_edit', { recipeId }), {
        updatedFfmuWithEmail,
        previousFfmuWithEmail,
    });
}
async function addUsers(recipeId, ffmusWithEmail) {
    const compressedFfmusWithEmail = await compressObject(ffmusWithEmail);
    const formData = new FormData();
    formData.append('compressedFfmusWithEmail', compressedFfmusWithEmail, 'ffmusWithEmail.json.gz');
    const response = mainAxiosClient.post(getUriFromRouteName('api_form_mail_users_add', { recipeId }), formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
}
async function updateUsers(recipeId, ffmusWithEmail) {
    const compressedFfmusWithEmail = await compressObject(ffmusWithEmail);
    const formData = new FormData();
    formData.append('compressedFfmusWithEmail', compressedFfmusWithEmail, 'ffmusWithEmail.json.gz');
    const response = mainAxiosClient.post(getUriFromRouteName('api_form_mail_users_edit', { recipeId }), formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
}
export { fetchFormMailUsers, addUser, removeUser, updateUser, addUsers, updateUsers };
