<template>
  <div>
    <el-button size="small" type="primary">
      {{ 'Click to Upload' | trans }}
      <span v-if="moreOptions"> ...</span>
    </el-button>
    <div v-if="maxFileSize !== ''" class="el-upload__tip">
      <slot name="tip">{{ 'Size must be less than %size%' | trans({ size: maxFileSize }) }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ElUploadInnerButton',

  props: {
    moreOptions: {
      type: Boolean,
      default: true,
    },
    maxFileSize: {
      type: String,
      default: '',
    },
  },
};
</script>
