import { KV_PARENT_CODE_QUERY_KEY } from 'const/kviewer';
/** ルート名定数 */
const KV_ROUTE_SERVICE_API_TOKEN = 'api-token';
const KV_ROUTE_VIEW_RECORD = 'record';
const KV_ROUTE_VIEW_RECORDS = 'records';
const KV_ROUTE_VIEW_DETAIL = 'detail-view';
/**
 * サービス画面用の Origin(or Hostname) かどうかのフラグ.
 * (true => サービス画面, false => ビュー画面)
 */
const IS_SERVICE_ORIGIN_BY_ROUTE = {
    [KV_ROUTE_SERVICE_API_TOKEN]: true,
    [KV_ROUTE_VIEW_RECORD]: false,
    [KV_ROUTE_VIEW_RECORDS]: false,
    [KV_ROUTE_VIEW_DETAIL]: false,
};
function getOrigin(route, subdomain = undefined) {
    if (IS_SERVICE_ORIGIN_BY_ROUTE[route]) {
        return kviewerServiceHost;
    }
    // @deprecated 全てのフォームで kviewerToken と kviewerSubdomain が定義されるようになったら削除
    if (typeof subdomain === 'undefined' || subdomain === null) {
        return kviewerViewHost;
    }
    return `https://${subdomain}.${kviewerViewCommonHost}`;
}
function buildKviewerUrl(params) {
    switch (params.route) {
        case KV_ROUTE_SERVICE_API_TOKEN: {
            const url = new URL(getOrigin(params.route));
            url.pathname = `/private-external/api-token`;
            url.searchParams.append('fb_host', params.fbHost);
            url.searchParams.append('fb_id', params.fbId.toString());
            return url.toString();
        }
        case KV_ROUTE_VIEW_RECORD: {
            const url = new URL(getOrigin(params.route, params.subdomain));
            url.pathname = `/public/api/record/${params.viewCode}/${params.recordCode}`;
            if (typeof params.parentCode !== 'undefined') {
                url.searchParams.append(KV_PARENT_CODE_QUERY_KEY, params.parentCode);
            }
            return url.toString();
        }
        case KV_ROUTE_VIEW_RECORDS: {
            const url = new URL(getOrigin(params.route, params.subdomain));
            url.pathname = `/public/api/records/${params.viewCode}/${params.page}`;
            return url.toString();
        }
        case KV_ROUTE_VIEW_DETAIL: {
            const url = new URL(getOrigin(params.route, params.subdomain));
            url.pathname = `/public/${params.viewCode}/detail/${params.recordCode}`;
            return url.toString();
        }
        default: {
            throw new Error('unknown route');
        }
    }
}
export { KV_ROUTE_SERVICE_API_TOKEN, KV_ROUTE_VIEW_RECORD, KV_ROUTE_VIEW_RECORDS, KV_ROUTE_VIEW_DETAIL, buildKviewerUrl, };
