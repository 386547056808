<template>
  <div class="fixed left-0 top-0 z-[3001] h-screen w-screen bg-white bg-opacity-80">
    <div
      class="relative mx-auto box-border flex h-full max-w-screen-sm flex-col bg-white pt-8 shadow-[0_4px_16px_rgba(0,0,0,0.1)] md:my-8 md:h-auto md:max-h-[calc(100%-4rem)] md:rounded"
    >
      <p class="mx-4 my-0 text-xl font-bold md:mx-8">{{ 'User selection' | trans }}</p>
      <p class="mx-4 mb-8 mt-4 md:mx-8">{{ 'Preview the automatically generated views for each user.' | trans }}</p>
      <div class="relative mx-4 mb-4 md:mx-8">
        <div
          class="pointer-events-none absolute left-4 top-2 flex items-center [&_.material-design-icon__svg]:align-middle"
        >
          <magnify-icon />
        </div>
        <input
          type="search"
          class="block w-full rounded border border-solid border-[#e9e9e9] p-[10px] pl-12 text-sm focus:border-[#55a4ce] focus:outline-0"
          :placeholder="'Search by email address, etc.' | trans"
          @input="(e) => search(e.target.value)"
        />
      </div>
      <div ref="usersTableWrap" class="flex-1 overflow-y-auto md:mx-8" @scroll="usersTableScrolled">
        <div class="relative px-4 pb-8 md:px-0">
          <kappauth-preview-users-table :shown-users="users" @selectUser="selectUser" />
          <div v-if="!noMoreUsersToLoad" ref="usersTableLoader" class="flex justify-center py-4">
            <div
              class="size-6 animate-spin rounded-full border-4 border-solid border-[#cee5f1] border-t-[#55a4ce]"
            ></div>
          </div>
          <div
            v-if="isRefetching"
            class="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-white bg-opacity-80"
          >
            <div
              class="size-6 animate-spin rounded-full border-4 border-solid border-[#cee5f1] border-t-[#55a4ce]"
            ></div>
          </div>
          <div
            v-else-if="!filteredTotalUsersCount"
            class="border border-t-0 border-solid border-[#e9e9e9] bg-[#f9f9f9] p-4"
          >
            <kappauth-preview-select-error
              v-if="!totalUsersCount"
              :title="'No preview is available because no user is registered.' | trans"
              :message="
                'Please add users on page &quot;%recipeName% > User management&quot; of the FormBridge administration page.'
                  | trans({ recipeName })
              "
            ></kappauth-preview-select-error>
            <kappauth-preview-select-error
              v-else-if="searchWords.length"
              :title="'No results found for your search.' | trans"
              :message="'Please try again with different keywords.' | trans"
            ></kappauth-preview-select-error>
          </div>
        </div>
      </div>
      <div v-if="canClose" class="absolute right-8 top-8 cursor-pointer" @click="$emit('close')">
        <close-icon :size="16" />
      </div>
    </div>
  </div>
</template>

<script>
import MagnifyIcon from 'vue-material-design-icons/Magnify';
import CloseIcon from 'vue-material-design-icons/Close';
import recipeAPi from 'api/recipe';
import { fetchFormMailUsers } from 'api/form-mail-users';
import u from 'utils/utils';
import KappauthPreviewUsersTable from 'Preview/KappauthPreviewUsersTable';
import KappauthPreviewSelectError from 'Preview/KappauthPreviewSelectError';

const FETCH_USERS_LIMIT = 25;
const USERS_SORT_KEY = 'email';
const SCROLL_EVENT_INTERVAL = 250;
const SEARCH_EVENT_INTERVAL = 250;

export default {
  name: 'KappauthPreviewSelectDialog',
  components: { KappauthPreviewSelectError, KappauthPreviewUsersTable, MagnifyIcon, CloseIcon },
  props: {
    recipeCode: {
      type: String,
      required: true,
    },
    canClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      users: [],
      totalUsersCount: 0,
      filteredTotalUsersCount: 0,
      recipeId: '',
      recipeName: '',
      searchWords: [],
      isRefetching: true,
      isFetchingMoreUsers: false,
      fetchUsersPromiseChainer: (async () => {})(),
    };
  },
  computed: {
    noMoreUsersToLoad() {
      return this.filteredTotalUsersCount <= this.users.length;
    },
    currentUser() {
      const query = new URLSearchParams(window.location.search);
      return query.get('kappauthPreview');
    },
  },
  async created() {
    const {
      data: { recipe },
    } = await recipeAPi.fetchByCode(this.recipeCode);
    this.recipeId = recipe.id;
    this.recipeName = recipe.name;

    this.refetchUsers().finally(() => {
      this.loading = false;
    });
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  destroyed() {
    document.body.style.overflow = '';
  },
  methods: {
    chainFetchUsers(resolver) {
      // トリガーされた順に読み込み処理が実行されるよう, 実行関数をPromiseで数珠つなぎにする.
      this.fetchUsersPromiseChainer = this.fetchUsersPromiseChainer.finally(() => resolver());
    },

    async refetchUsers() {
      // loadMoreUsers() の実行をブロックするために, isRefetching は即時 true に設定する.
      this.isRefetching = true;

      this.chainFetchUsers(async () => {
        // キーワードの入力により refetchUsers が連続して呼ばれたとき, 先に完了したリクエスト
        // により isRefetching が false となる. 確実にロード中ステータスを反映するために
        // リクエスト実行前に再度 true に設定する.
        this.isRefetching = true;

        try {
          const {
            data: { ffmusWithEmail, totalUsersCount, filteredTotalUsersCount },
          } = await fetchFormMailUsers(this.recipeId, FETCH_USERS_LIMIT, null, null, this.searchWords, USERS_SORT_KEY);
          this.users = ffmusWithEmail;
          this.totalUsersCount = totalUsersCount;
          this.filteredTotalUsersCount = filteredTotalUsersCount;
        } catch (error) {
          u.error(this, error);
        } finally {
          this.isRefetching = false;
        }
      });
    },

    async fetchMoreUsers() {
      if (this.isRefetching || this.isFetchingMoreUsers) {
        return;
      }
      this.isFetchingMoreUsers = true;
      try {
        const {
          data: { ffmusWithEmail, totalUsersCount, filteredTotalUsersCount },
        } = await fetchFormMailUsers(
          this.recipeId,
          FETCH_USERS_LIMIT,
          this.users.length,
          null,
          this.searchWords,
          USERS_SORT_KEY,
        );
        this.users.push(...ffmusWithEmail);
        this.totalUsersCount = totalUsersCount;
        this.filteredTotalUsersCount = filteredTotalUsersCount;
      } catch (error) {
        u.error(this, error);
      } finally {
        this.isFetchingMoreUsers = false;
      }
    },

    usersTableScrolled: u.debounce(function _() {
      const wrapBottom = this.$refs.usersTableWrap?.getBoundingClientRect().bottom;
      const loaderTop = this.$refs.usersTableLoader?.getBoundingClientRect().top;

      if (loaderTop <= wrapBottom) {
        this.fetchMoreUsers();
      }
    }, SCROLL_EVENT_INTERVAL),
    scrollUsersTableTo(pixel) {
      this.$refs.usersTableWrap.scrollTop = pixel;
    },
    search: u.debounce(async function _(input) {
      this.searchWords = input.split(' ').filter((w) => w !== '');
      await this.refetchUsers();
      this.scrollUsersTableTo(0);
    }, SEARCH_EVENT_INTERVAL),

    selectUser(email) {
      if (email === this.currentUser) {
        this.$emit('close');
        return;
      }

      const url = new URL(window.location.href);
      url.searchParams.set('kappauthPreview', email);
      window.location.href = url.toString();
    },
  },
};
</script>
