<template>
  <button
    :id="id"
    :type="type"
    class="rounded border border-solid border-role-action bg-role-action px-4 py-2 text-sm font-bold text-white hover:cursor-pointer disabled:cursor-default disabled:opacity-30"
    :class="{ 'hover:bg-role-action-hover': !disabled }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
// デザインはkintoneAppUIのContainedButtonに対応している。プロパティは必要になり次第足していく。
// https://github.com/toyokumo/kintoneapp-ui/blob/main/src/button/ContainedButton.tsx

/**
 * Available events:
 *  - click
 */
export default {
  name: 'ActionButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
};
</script>
