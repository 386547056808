<template>
  <el-input
    v-model="val"
    :type="field.attributes.type"
    :disabled="!field.editable"
    :placeholder="placeholder | trans"
  ></el-input>
</template>

<script>
import u from '../../../utils/utils';

export default {
  name: 'FbSingleLineText',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : '';
      },
      set(value) {
        this.$emit('update', value);
      },
    },
    placeholder() {
      return u.has(this.field.attributes, 'placeholder') ? this.field.attributes.placeholder : '';
    },
  },
};
</script>

<style scoped></style>
