<template>
  <table
    class="min-w-full border-spacing-0 whitespace-nowrap break-all text-left font-normal md:w-full md:whitespace-normal"
  >
    <thead
      class="text-xs text-[#666666] [&_th:first-child]:border-l [&_th]:sticky [&_th]:top-0 [&_th]:z-10 [&_th]:border [&_th]:border-l-0 [&_th]:border-solid [&_th]:border-[#e9e9e9] [&_th]:bg-[#f9f9f9] [&_th]:p-4"
    >
      <tr>
        <th>{{ 'User' | trans }}</th>
      </tr>
    </thead>
    <tbody
      class="[&_td:first-child]:border-l [&_td]:border [&_td]:border-l-0 [&_td]:border-t-0 [&_td]:border-solid [&_td]:border-[#e9e9e9] [&_td]:px-4 [&_td]:py-3"
    >
      <tr
        v-for="user in shownUsers"
        :key="user.email"
        class="cursor-pointer hover:bg-[#efefef]"
        @click="() => $emit('selectUser', user.email)"
      >
        <td>{{ user.email }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'KappauthPreviewUsersTable',
  props: {
    shownUsers: {
      type: Array,
      required: true,
    },
  },
};
</script>
