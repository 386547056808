<template>
  <div v-if="showField && isMobile" class="field" :style="styles">
    <div v-if="showField" class="column field" :style="field.style">
      <label v-if="!field.noLabel" class="label">{{ getLabel(field.label) }}</label>
      <value-view
        v-if="field.type === 'SUBTABLE'"
        :field="field"
        :value="value"
        :is-translate="form.isTranslate"
        :is-error-log="isErrorLog"
        style="width: 100%; overflow-x: auto"
      ></value-view>
      <value-view
        v-else
        :field="field"
        :value="value"
        :is-translate="form.isTranslate"
        :is-error-log="isErrorLog"
      ></value-view>
    </div>
  </div>
  <div v-else-if="showField && !isMobile" class="field" :style="styles">
    <div class="two wide column">
      <label v-if="!field.noLabel" class="label">{{ getLabel(field.label) }}</label>
    </div>
    <value-view
      v-if="field.type === 'SUBTABLE'"
      :field="field"
      :value="value"
      :is-translate="form.isTranslate"
      :is-error-log="isErrorLog"
      class="fourteen wide column"
      style="width: 87%; overflow-x: auto"
    ></value-view>
    <value-view
      v-else
      :field="field"
      :value="value"
      :is-translate="form.isTranslate"
      :is-error-log="isErrorLog"
      class="fourteen wide column"
      style="width: 87%"
    ></value-view>
  </div>
</template>

<script>
import u from '../../utils/utils';
import fu from '../../utils/field-utils';
import ValueView from './FieldView/ValueView';

export default {
  name: 'FieldView',
  components: { ValueView },
  props: ['field', 'value', 'isErrorLog'],
  data() {
    return { isMobile: u.isMobile() };
  },
  computed: {
    styles() {
      let styles = u.isMobile() ? this.field.spStyle : this.field.style;
      if (Array.isArray(styles)) {
        styles = {};
      }
      if (this.field.type === 'SUBTABLE') {
        styles.width = '100%';
      }
      return styles;
    },
    showField() {
      return (
        fu.isShowField(this.field) &&
        this.field.type !== 'LABEL' &&
        this.field.type !== 'HR' &&
        this.field.type !== 'KVIEWER_LOOKUP'
      );
    },
    form() {
      if (u.has(this.$store.state, 'form')) {
        return this.$store.state.form;
      }
      return this.$store.state.recipe.form;
    },
  },
  methods: {
    getLabel(label) {
      if (this.form.isTranslate) {
        return u.trans(label);
      }
      return label;
    },
  },
};
</script>

<style scoped>
.field .column {
  word-break: break-all;
}
.field .label {
  font-weight: bold;
}
</style>
