var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"enter-active-class":"animated fadeIn"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showField),expression:"showField"}],class:[
      'column el-form-item',
      {
        field: _vm.field.code && _vm.field.type !== 'LABEL',
        required: _vm.field.required,
        error: _vm.errors.has(_vm.field.code),
        'subtable-field': _vm.field.type === 'SUBTABLE',
      },
    ],style:(_vm.styles)},[(_vm.field.label && !_vm.field.noLabel && _vm.field.type === 'LABEL')?_c('label',{staticClass:"ql-editor",domProps:{"innerHTML":_vm._s(_vm.label)}}):(_vm.field.label && !_vm.field.noLabel)?_c('label',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.field.code && _vm.field.type !== 'LABEL')?_c(_vm.type,{directives:[{name:"validate",rawName:"v-validate",value:(_vm.validations),expression:"validations"}],tag:"component",attrs:{"field":_vm.field,"value":_vm.value,"width":_vm.width,"data-vv-name":_vm.field.code},on:{"update":_vm.update},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.field.code)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has(_vm.field.code)),expression:"errors.has(field.code)"}],staticClass:"el-form-item__error"},[_vm._v("\n        "+_vm._s(_vm.errors.first(_vm.field.code))+"\n      ")]):_vm._e()]),_vm._v(" "),(_vm.field.help && _vm.field.help.length > 0)?_c('p',{staticClass:"help-content"},[_vm._v("\n      "+_vm._s(_vm._f("trans")(_vm.field.help))+"\n    ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }