<template>
  <el-cascader
    v-model="val"
    class="w-full min-w-[60px]"
    readonly="readonly"
    :options="field.options"
    :disabled="!field.editable"
    :clearable="true"
  ></el-cascader>
</template>

<script>
import u from 'utils/utils';

export default {
  name: 'FbCascader',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : [];
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  created() {
    if (this.$store.state.form.isTranslate) {
      this.transOptions(this.field.options);
    }
  },
  methods: {
    transOptions(options) {
      options.forEach((option) => {
        option.label = u.trans(option.value);
        if (u.has(option, 'children')) {
          this.transOptions(option.children);
        }
      });
    },
  },
};
</script>

<style>
.el-cascader-menu::-webkit-scrollbar {
  width: 5px;
}
.el-cascader-menu::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #eee;
}
.el-cascader-menu::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888;
}
</style>
