import dayjs from 'dayjs';
import { MessageBox } from 'element-ui';
import utils from './utils';
import { buildKviewerUrl, KV_ROUTE_VIEW_DETAIL } from './kviewer-url-utils';
// error: unknown を絞り込む用
const isKvErrorResponse = (data) => typeof data === 'object' && data != null && 'error' in data;
function changeKViewerRecordToFbRecord(kViewerRecord) {
    return Object.keys(kViewerRecord).reduce((record, code) => {
        const { type } = kViewerRecord[code];
        const { value } = kViewerRecord[code];
        if (!value || value === '') {
            record[code] = { value: '', type };
            return record;
        }
        switch (type) {
            case 'SUBTABLE':
                record[code] = {
                    value: value.map((tableRecord) => changeKViewerRecordToFbRecord(tableRecord)),
                    type: 'SUBTABLE',
                };
                break;
            case 'DATE':
            case 'DATETIME':
            case 'CREATED_TIME':
            case 'UPDATED_TIME': {
                const d = dayjs(value).toDate();
                record[code] = {
                    value: utils.isDateObject(d) ? d : '',
                    type: type === 'DATE' ? 'DATE' : 'DATETIME',
                };
                break;
            }
            case 'CHECK_BOX':
            case 'MULTI_SELECT':
                record[code] = {
                    value: Array.isArray(value) ? value : [],
                    type,
                };
                break;
            default:
                record[code] = { value, type };
                break;
        }
        return record;
    }, {});
}
function changeKViewerRecordsToFbRecords(kViewerRecords) {
    return kViewerRecords.map((kViewerRecord) => changeKViewerRecordToFbRecord(kViewerRecord));
}
function showMessageAndRedirectKViewer(viewCode, recordCode, subdomain) {
    const viewUrl = buildKviewerUrl({ route: KV_ROUTE_VIEW_DETAIL, viewCode, recordCode, subdomain });
    MessageBox.alert(utils.trans('The one time token of kViewer may have expired. Please go back to kViewer once and start editing again.'), utils.trans("Can't get record information."), {
        type: 'error',
        showClose: false,
        callback: () => {
            window.location.href = viewUrl;
        },
    });
}
function isShowMyPageSetting(availableToken, myPageViews, isUseMyPage) {
    // 現在マイページの利用があれば「Myページを利用」を表示する
    if (isUseMyPage) {
        return true;
    }
    if (availableToken && myPageViews.length > 0) {
        return true;
    }
    return false;
}
function isEnableSaveButton(availableToken, isFetched, isUseMyPage) {
    if (availableToken && !isFetched && !isUseMyPage) {
        return true;
    }
    return false;
}
export default {
    changeKViewerRecordsToFbRecords,
    changeKViewerRecordToFbRecord,
    showMessageAndRedirectKViewer,
    isKvErrorResponse,
    isShowMyPageSetting,
    isEnableSaveButton,
};
