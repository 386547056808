<template>
  <quill-editor v-model="val" :options="options"></quill-editor>
</template>

<script>
import c from 'js/const';

export default {
  name: 'FbRichText',
  props: ['field', 'value'],
  data() {
    return {
      options: c.richTextOptions,
    };
  },
  computed: {
    val: {
      get() {
        return this.value ? this.value : '';
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
};
</script>

<style scoped></style>
