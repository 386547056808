const KINTONE_URL_PATTERN = /^https:\/\/([a-z0-9-]+)\.(cybozu\.com|cybozu-dev\.com|kintone\.com|kintone-dev\.com|cybozu\.cn|cybozu-dev\.cn)(\/.*)?$/;
const APP_URL_PATTERN = /^https:\/\/([a-z0-9-]+)\.(cybozu\.com|cybozu-dev\.com|kintone\.com|kintone-dev\.com|cybozu\.cn|cybozu-dev\.cn)\/k\/(\d+)/;
const GUEST_APP_URL_PATTERN = /^https:\/\/([a-z0-9-]+)\.(cybozu\.com|cybozu-dev\.com|kintone\.com|kintone-dev\.com|cybozu\.cn|cybozu-dev\.cn)\/k\/guest\/(\d+)\/(\d+)/;
function isKintoneUrl(url) {
    return KINTONE_URL_PATTERN.test(url);
}
function isKintoneAppUrl(url) {
    return APP_URL_PATTERN.test(url) || GUEST_APP_URL_PATTERN.test(url);
}
function isOriginMatchedKintoneAppUrl(url, origin) {
    if (origin === null || typeof origin === 'undefined') {
        return false;
    }
    return url.startsWith(origin) && isKintoneAppUrl(url);
}
function decomposeUrl(url) {
    const guestAppInfo = url.match(GUEST_APP_URL_PATTERN);
    if (guestAppInfo) {
        return {
            subdomain: guestAppInfo[1],
            domain: guestAppInfo[2],
            appId: guestAppInfo[4],
            guestSpaceId: guestAppInfo[3],
        };
    }
    const appInfo = url.match(APP_URL_PATTERN);
    if (appInfo) {
        return {
            subdomain: appInfo[1],
            domain: appInfo[2],
            appId: appInfo[3],
            guestSpaceId: null,
        };
    }
    return false;
}
function composeUrl({ subdomain, domain, guestSpaceId, appId }) {
    const base = `https://${subdomain}.${domain}`;
    if (guestSpaceId) {
        return `${base}/k/guest/${guestSpaceId}/${appId}/`;
    }
    return `${base}/k/${appId}/`;
}
function composeApiTokenConfigUrl({ subdomain, domain, guestSpaceId, appId }) {
    const base = `https://${subdomain}.${domain}`;
    if (guestSpaceId) {
        return `${base}/k/guest/${guestSpaceId}/admin/app/apitoken?app=${appId}`;
    }
    return `${base}/k/admin/app/apitoken?app=${appId}`;
}
function composeWebhookConfigUrl({ subdomain, domain, guestSpaceId, appId }) {
    const base = `https://${subdomain}.${domain}`;
    if (guestSpaceId) {
        return `${base}/k/guest/${guestSpaceId}/admin/app/webhook?app=${appId}`;
    }
    return `${base}/k/admin/app/webhook?app=${appId}`;
}
function url2id(url) {
    if (typeof url !== 'string') {
        return null;
    }
    const decomposed = decomposeUrl(url);
    if (!decomposed) {
        return null;
    }
    return parseInt(decomposed.appId.toString(), 10);
}
function allFields(fields) {
    return fields.flatMap((field) => {
        if (field.type === 'SUBTABLE') {
            return [field, ...field.fields];
        }
        return field;
    });
}
function collectCodes(fields) {
    return allFields(fields).map((f) => f.code);
}
function generateNewFieldCode(code, existingFieldCodes) {
    if (!existingFieldCodes.includes(code)) {
        return [code, [...existingFieldCodes, code]];
    }
    const pattern = new RegExp(`^${code}_(\\d+)$`);
    const numbers = existingFieldCodes
        .map((field) => field.match(pattern))
        .filter((result) => result)
        .map((result) => parseInt(result[1], 10));
    numbers.sort();
    const number = numbers.reduce((n, m) => (n === m ? m + 1 : n), 0);
    const newCode = `${code}_${number}`;
    return [newCode, [...existingFieldCodes, newCode]];
}
// TODO: 以下は削除し, named-export のみにする (現状は後方互換性のためにのみ定義)
export default {
    decomposeUrl,
    composeUrl,
    composeApiTokenConfigUrl,
    url2id,
    allFields,
    collectCodes,
    generateNewFieldCode,
};
export { isKintoneUrl, decomposeUrl, isKintoneAppUrl, isOriginMatchedKintoneAppUrl, composeUrl, composeApiTokenConfigUrl, composeWebhookConfigUrl, url2id, allFields, collectCodes, generateNewFieldCode, };
