import Vue from 'vue';
import VueRouter from 'vue-router';
import PublicForm from 'components/PublicForm.vue';
import PublicFormConfirm from 'components/PublicFormConfirm.vue';
import PublicFormFinish from 'components/PublicFormFinish.vue';
Vue.use(VueRouter);
export function createRouter() {
    return new VueRouter({
        mode: 'hash',
        routes: [
            { path: '/', name: 'public_form', component: PublicForm },
            { path: '/confirm', name: 'public_form_confirm', component: PublicFormConfirm },
            { path: '/finish', name: 'public_form_finish', component: PublicFormFinish },
        ],
    });
}
