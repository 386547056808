import { PLANS } from './plans';
export const FORM_FEATURES_BY_KEY = {
    recipeDashboard: {
        component: 'recipe-dashboard',
        path: 'dashboard',
        label: 'Form overview',
        plan: PLANS.LIGHT,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    formVersion: {
        component: '', // 差分確認の処理にのみ使用しサイドナビでは設定不要のため空文字を設定
        path: '', // 差分確認の処理にのみ使用しサイドナビでは設定不要のため空文字を設定
        label: '', // 差分確認の処理にのみ使用しサイドナビでは設定不要のため空文字を設定
        plan: PLANS.LIGHT,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    formDesign: {
        component: 'form-design-setting',
        path: 'form-design',
        label: 'Form design',
        plan: PLANS.LIGHT,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: true,
        hasNextJsPage: true,
    },
    spLayout: {
        component: 'sp-layout-setting',
        path: 'sp-layout',
        label: 'Fields for smartphones',
        plan: PLANS.STANDARD,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    branch: {
        component: 'branch-setting',
        path: 'branch',
        label: 'Conditional branching',
        plan: PLANS.STANDARD,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    step: {
        component: 'step-setting',
        path: 'step',
        label: 'Form with multiple steps',
        plan: PLANS.STANDARD,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    formDescription: {
        component: 'form-description-setting',
        path: 'form-description',
        label: 'Form description',
        plan: PLANS.LIGHT,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    confirm: {
        component: 'confirm-setting',
        path: 'confirm',
        label: 'Confirm page',
        plan: PLANS.LIGHT,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    bridges: {
        component: 'bridges-setting',
        path: 'bridges',
        label: 'Automatic post-processing',
        plan: PLANS.LIGHT,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: true,
        hasNextJsPage: false,
    },
    finish: {
        component: 'finish-setting',
        path: 'finish',
        label: 'Exit page',
        plan: PLANS.LIGHT,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    formMailAuth: {
        component: 'form-mail-auth-setting',
        path: 'form-mail-auth',
        label: 'User management',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: true,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    formPasswordProtection: {
        component: 'form-password-protection-setting',
        path: 'form-password-protection',
        label: 'Password protection',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    formSimpleAuth: {
        component: 'form-simple-auth-setting',
        path: 'form-simple-auth',
        label: 'Simple access authentication',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    ipRestriction: {
        component: 'ip-restriction-setting',
        path: 'ip-restriction',
        label: 'IP restriction',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    submissionRestriction: {
        component: 'submission-restriction-setting',
        path: 'submission-restriction',
        label: 'Submission restriction',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    error: {
        component: 'error-setting',
        path: 'error',
        label: 'Error screen',
        plan: PLANS.LIGHT,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    temporarySave: {
        component: 'temporary-save-setting',
        path: 'temporary-save',
        label: 'Temporal storing of answers',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    translation: {
        component: 'translation-setting',
        path: 'translation',
        label: 'Multilingualization',
        plan: PLANS.STANDARD,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    jsCssCustomization: {
        component: 'js-css-customization-setting',
        path: 'js-css-customization',
        label: 'JavaScript/CSS customization',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    resourceRestriction: {
        component: 'resource-restriction-setting',
        path: 'resource-restriction',
        label: 'Loadable resources restriction',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    botBlocking: {
        component: 'bot-blocking-setting',
        path: 'bot-blocking',
        label: 'Bot blocking',
        plan: PLANS.LIGHT,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    kviewer: {
        component: 'kviewer-setting',
        path: 'kviewer',
        label: 'kViewer integration',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    kmailer: {
        component: 'kmailer-setting',
        path: 'kmailer',
        label: 'kMailer integration',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    printCreator: {
        component: 'print-creator-setting',
        path: 'print-creator',
        label: 'Print Creator integration',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    accessAnalytics: {
        component: 'access-analysis-setting',
        path: 'access-analysis',
        label: 'Google Analytics integration',
        plan: PLANS.PREMIUM,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: false,
    },
    myNumberCardIdentityVerification: {
        component: '', // Next.jsページなのでVue.jsのコンポーネント名は不要
        path: 'my-number-card-identity-verification',
        label: 'Verify identity with My Number Card',
        plan: PLANS.ENTERPRISE,
        displayContentForInvalidPlan: false,
        hasSettingInSideNav: false,
        hasNextJsPage: true,
    },
};
const FORM_FEATURES_MAP = {
    basic: {
        'Form overview': [FORM_FEATURES_BY_KEY.recipeDashboard],
        'Basic structure': [
            FORM_FEATURES_BY_KEY.formDesign,
            FORM_FEATURES_BY_KEY.spLayout,
            FORM_FEATURES_BY_KEY.branch,
            FORM_FEATURES_BY_KEY.step,
            FORM_FEATURES_BY_KEY.formDescription,
        ],
        'Answer flows': [FORM_FEATURES_BY_KEY.confirm, FORM_FEATURES_BY_KEY.bridges, FORM_FEATURES_BY_KEY.finish],
        'Access restrictions': [
            FORM_FEATURES_BY_KEY.formMailAuth,
            FORM_FEATURES_BY_KEY.formPasswordProtection,
            FORM_FEATURES_BY_KEY.formSimpleAuth,
            FORM_FEATURES_BY_KEY.ipRestriction,
            FORM_FEATURES_BY_KEY.myNumberCardIdentityVerification,
        ],
    },
    advanced: {
        'Form details': [
            FORM_FEATURES_BY_KEY.submissionRestriction,
            FORM_FEATURES_BY_KEY.error,
            FORM_FEATURES_BY_KEY.temporarySave,
            FORM_FEATURES_BY_KEY.translation,
            FORM_FEATURES_BY_KEY.jsCssCustomization,
            FORM_FEATURES_BY_KEY.resourceRestriction,
            FORM_FEATURES_BY_KEY.botBlocking,
        ],
        'External linkages': [
            FORM_FEATURES_BY_KEY.kviewer,
            FORM_FEATURES_BY_KEY.printCreator,
            FORM_FEATURES_BY_KEY.accessAnalytics,
        ],
    },
};
export const FEATURES_BY_BASIC_GROUPS = FORM_FEATURES_MAP.basic;
export const FEATURES_BY_ADVANCED_GROUPS = FORM_FEATURES_MAP.advanced;
export const BASIC_FEATURES = Object.keys(FEATURES_BY_BASIC_GROUPS).reduce((a, k) => a.concat(FEATURES_BY_BASIC_GROUPS[k]), []);
const ADVANCED_FEATURES = Object.keys(FEATURES_BY_ADVANCED_GROUPS).reduce((a, k) => a.concat(FEATURES_BY_ADVANCED_GROUPS[k]), []);
export const FORM_FEATURES = [...BASIC_FEATURES, ...ADVANCED_FEATURES];
