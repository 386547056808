import { getUriFromRouteName } from 'utils/routing-utils';
export default (routeName, params = {}, fqdn = undefined) => {
    try {
        return getUriFromRouteName(routeName, params, fqdn);
    }
    catch {
        return {
            name: routeName,
            params,
        };
    }
};
