const c = {
    scrollOptions: {
        easing: 'ease',
        offset: -30,
    },
    richTextOptions: {
        modules: {
            toolbar: [
                [
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    { color: [] },
                    { background: [] },
                    { align: [] },
                    { indent: '-1' },
                    { indent: '+1' },
                    { header: 1 },
                    { header: 2 },
                    { size: ['small', false, 'large', 'huge'] },
                    { list: 'ordered' },
                    { list: 'bullet' },
                    'link',
                    'clean',
                ],
            ],
        },
    },
    labelRichTextOptions: {
        modules: {
            toolbar: [
                [
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    { color: [] },
                    { background: [] },
                    { align: [] },
                    { indent: '-1' },
                    { indent: '+1' },
                    { header: 1 },
                    { header: 2 },
                    { size: ['small', false, 'large', 'huge'] },
                    { list: 'ordered' },
                    { list: 'bullet' },
                    'link',
                    'image',
                    'clean',
                ],
            ],
        },
    },
    fieldTypes: [
        { type: 'LABEL', icon: 'Label', label: 'Label' },
        { type: 'SINGLE_LINE_TEXT', icon: 'AlphabeticalVariant', label: 'Single line text' },
        { type: 'RICH_TEXT', icon: 'FormatColorText', label: 'Rich text' },
        { type: 'MULTI_LINE_TEXT', icon: 'TextIcon', label: 'Multi-line text' },
        { type: 'NUMBER', icon: 'Numeric', label: 'Number' },
        { type: 'CALC', icon: 'CalculatorVariant', label: 'Calculated field' },
        { type: 'RADIO_BUTTON', icon: 'RadioboxMarked', label: 'Radio button' },
        { type: 'CHECK_BOX', icon: 'CheckboxMarked', label: 'Check box' },
        { type: 'MULTI_SELECT', icon: 'FormatListBulleted', label: 'Multi-choice' },
        { type: 'DROP_DOWN', icon: 'MenuDown', label: 'Drop-down' },
        { type: 'DATE', icon: 'CalendarMonth', label: 'Date' },
        { type: 'TIME', icon: 'ClockOutline', label: 'Time' },
        { type: 'DATETIME', icon: 'Calendar', label: 'Date and time' },
        { type: 'FILE', icon: 'Paperclip', label: 'Attachment' },
        { type: 'SUBTABLE', icon: 'CalendarViewMonth', label: 'Table' },
        { type: 'KVIEWER_LOOKUP', icon: 'Eye', label: 'kViewer lookup' },
        { type: 'RATE', icon: 'Star', label: 'Rate' },
        { type: 'SLIDER', icon: 'LinearScale', label: 'Slider' },
        { type: 'CASCADER', icon: 'Schema', label: 'Cascader' },
        { type: 'HR', icon: 'Minus', label: 'Border' },
    ],
    tableFieldTypes: [
        { type: 'SINGLE_LINE_TEXT', icon: 'ABC', isCustomIcon: true, label: 'Single line text' },
        { type: 'RICH_TEXT', icon: 'pencil', label: 'Rich text' },
        { type: 'MULTI_LINE_TEXT', icon: 'content', label: 'Multi-line text' },
        { type: 'NUMBER', icon: '123', isCustomIcon: true, label: 'Number' },
        { type: 'CALC', icon: '+-*/', isCustomIcon: true, label: 'Calculated field' },
        { type: 'RADIO_BUTTON', icon: 'circle', label: 'Radio button' },
        { type: 'CHECK_BOX', icon: 'checkmark box', label: 'Check box' },
        { type: 'MULTI_SELECT', icon: 'unordered list', label: 'Multi-choice' },
        { type: 'DROP_DOWN', icon: 'chevron down', label: 'Drop-down' },
        { type: 'DATE', icon: 'calendar', label: 'Date' },
        { type: 'TIME', icon: 'clock outline', label: 'Time' },
        { type: 'DATETIME', icon: ['calendar', 'clock outline'], isMultiIcon: true, label: 'Date and time' },
        { type: 'FILE', icon: 'attach', label: 'Attachment' },
        { type: 'KVIEWER_LOOKUP', icon: 'eye', label: 'kViewer lookup' },
        { type: 'RATE', icon: 'star outline', label: 'Rate' },
        { type: 'SLIDER', icon: 'exchange alternate', label: 'Slider' },
        { type: 'CASCADER', icon: 'sitemap', label: 'Cascader' },
    ],
    protocols: [
        { type: 'text', protocol: 'text', label: 'Text' },
        { type: 'password', protocol: 'password', label: 'Password' },
        { type: 'url', protocol: 'WEB', label: 'URL' },
        { type: 'email', protocol: 'MAIL', label: 'Email' },
        { type: 'tel', protocol: 'CALL', label: 'Telephone number' },
    ],
    syncList: {
        LABEL: ['label', 'code'],
        SINGLE_LINE_TEXT: ['label', 'noLabel', 'required', 'attributes', 'minLength', 'maxLength', 'defaultValue', 'code'],
        RICH_TEXT: ['label', 'noLabel', 'required', 'defaultValue', 'code'],
        MULTI_LINE_TEXT: ['label', 'noLabel', 'required', 'defaultValue', 'code'],
        NUMBER: ['label', 'noLabel', 'minValue', 'maxValue', 'defaultValue', 'code'],
        CALC: ['label', 'noLabel', 'expression', 'code'],
        RADIO_BUTTON: ['label', 'noLabel', 'options', 'defaultValue', 'code'],
        CHECK_BOX: ['label', 'noLabel', 'required', 'options', 'defaultValue', 'code'],
        MULTI_SELECT: ['label', 'noLabel', 'required', 'options', 'defaultValue', 'code'],
        DROP_DOWN: ['label', 'noLabel', 'required', 'options', 'defaultValue', 'code'],
        DATE: ['label', 'noLabel', 'required', 'defaultValue', 'code'],
        TIME: ['label', 'noLabel', 'required', 'defaultValue', 'code'],
        DATETIME: ['label', 'noLabel', 'required', 'defaultValue', 'code'],
        FILE: ['label', 'noLabel', 'code'],
        HR: ['code'],
        SUBTABLE: ['label', 'fields', 'code'],
    },
    diffList: {
        // FB側に LINK はない. SINGLE_LINE_TEXT が LINK を兼ねている.
        SINGLE_LINE_TEXT: ['type', 'required', 'minLength', 'maxLength', 'protocol'],
        // MULTI_LINE_TEXT, RICH_TEXT, KVIEWER_LOOKUP は min/maxLength はFB上では常に未設定だが,
        // SINGLE_LINE_TEXT に保存できるかどうか調べるために min/maxLength を定義しておくと便利.
        MULTI_LINE_TEXT: ['type', 'required', 'minLength', 'maxLength'],
        RICH_TEXT: ['type', 'required', 'minLength', 'maxLength'],
        KVIEWER_LOOKUP: ['type', 'required', 'minLength', 'maxLength'],
        NUMBER: ['type', 'required', 'minValue', 'maxValue'],
        CALC: ['type', 'required'],
        DATE: ['type', 'required'],
        TIME: ['type', 'required'],
        DATETIME: ['type', 'required'],
        FILE: ['type', 'required'],
        RADIO_BUTTON: ['type', 'options'],
        CHECK_BOX: ['type', 'required', 'options'],
        MULTI_SELECT: ['type', 'required', 'options'],
        DROP_DOWN: ['type', 'required', 'options'],
        SUBTABLE: ['type', 'fields'],
        RATE: ['type', 'required', 'minValue', 'maxValue'],
        SLIDER: ['type', 'required', 'minValue', 'maxValue'],
        CASCADER: ['type', 'required', 'options'],
    },
    tabs: {
        light: [
            { tab: 'general', icon: ['settings'], label: 'General Settings' },
            { tab: 'fields', icon: ['block layout'], label: 'Fields composition' },
            { tab: 'kintone-bridges', icon: ['align justify'], label: 'kintone app' },
            { tab: 'error-notification-bridges', icon: ['alarm'], label: 'Error notification' },
            { tab: 'finish', icon: ['flag checkered'], label: 'Completion page' },
            { tab: 'error', icon: ['exclamation triangle'], label: 'Error page' },
        ],
        standard: [
            { tab: 'auto-reply-bridges', icon: ['send'], label: 'Auto reply bridge' },
            { tab: 'sp-layout', icon: ['mobile alternate'], label: 'Mobile layout' },
            { tab: 'step', icon: ['forward'], label: 'Step form' },
            { tab: 'branch', icon: ['share alternate'], label: 'Branch form' },
            { tab: 'translation', icon: ['translate'], label: 'Translation' },
        ],
        premium: [
            { tab: 'bridges', icon: ['chain'], label: 'Post-submission step' },
            { tab: 'kviewer', icon: ['eye'], label: 'kViewer' },
            { tab: 'print-creator', icon: ['file text'], label: 'Print Creator' },
            { tab: 'kmailer', icon: ['envelope'], label: 'kMailer' },
            { tab: 'security', icon: ['lock'], label: 'Security' },
        ],
        premium2: [
            { tab: 'temporary-save', icon: ['pause'], label: 'Temporary Save' },
            { tab: 'auto-retry', icon: ['redo'], label: 'Auto retry' },
            { tab: 'google', icon: ['google'], label: 'Access Analysis' },
            { tab: 'customize', icon: ['configure'], label: 'Customize' },
        ],
    },
    bridgeTypes: [
        { value: 'KintoneBridge', label: 'Save record to Kintone app' },
        { value: 'AutoReplyBridge', label: 'Auto reply bridge' },
        { value: 'KmailerBridge', label: 'kMailer bridge' },
        { value: 'ErrorNotificationBridge', label: 'Error notification' },
        { value: 'AdminNotificationBridge', label: 'Admin notification' },
        { value: 'CalcBridge', label: 'Number calculation' },
        { value: 'ConcatBridge', label: 'Text connect' },
        { value: 'ReplaceBridge', label: 'Text replacement' },
        { value: 'AutoIncrementBridge', label: 'Auto number assignment' },
    ],
    iftTypes: [
        { value: 'all', label: 'Always run' },
        { value: 'error', label: 'When an error occurs in the executed processes' },
        { value: 'success', label: 'When all executed processes are successful' },
        { value: 'new', label: 'When creating a record' },
        { value: 'edit', label: 'When updating' },
        { value: 'locale', label: 'According to the language settings of the submitter' },
        { value: 'field', label: 'According to the field value' },
    ],
    bridgeSigns: {
        default: [
            { value: '=', label: '= (Equal to)' },
            { value: '!=', label: '≠(Unequal to)' },
            { value: '>', label: '> (Greater than)' },
            { value: '<', label: '< (Less than)' },
            { value: 'IN', label: 'In' },
            { value: 'NOT_IN', label: 'Not in' },
        ],
        string: [
            { value: '=', label: '= (Equal to)' },
            { value: '!=', label: '≠(Unequal to)' },
            { value: 'IN', label: 'In' },
            { value: 'NOT_IN', label: 'Not in' },
        ],
        number: [
            { value: '=', label: '= (Equal to)' },
            { value: '!=', label: '≠(Unequal to)' },
            { value: '>', label: '> (Greater than)' },
            { value: '<', label: '< (Less than)' },
        ],
        select: [
            { value: 'IN', label: 'In' },
            { value: 'NOT_IN', label: 'Not in' },
        ],
    },
    branchConditionFieldType: ['RADIO_BUTTON', 'DROP_DOWN', 'CHECK_BOX', 'MULTI_SELECT', 'CASCADER'],
    ruleSigns: {
        '=': '= (Equal to)',
        '!=': '≠(Unequal to)',
        In: 'In',
        'Not in': 'Not in',
    },
    mobileFieldsType: ['TIME', 'DATE', 'DATETIME', 'RADIO_BUTTON', 'CHECK_BOX', 'DROP_DOWN', 'MULTI_SELECT', 'SUBTABLE'],
    reservedFieldCode: [
        '__title__',
        '__posted_at__',
        '__errorMessage__',
        '__errorPostUrl__',
        '__auto_increment__',
        '__auto_increment_by_month__',
        '__auto_increment_by_day__',
        '__kMailerMailId__',
        '__kintoneBridgeRecordId__1',
        '__kintoneBridgeRecordUrl__1',
        '__kintoneBridgeRecordId__2',
        '__kintoneBridgeRecordUrl__2',
        '__kintoneBridgeRecordId__3',
        '__kintoneBridgeRecordUrl__3',
        '__kViewerMyPageRecordCode__',
        '__kViewerMyPageUrl__',
        '__myNumberCardVerifiedName__',
        '__myNumberCardVerifiedAddress__',
        '__myNumberCardVerifiedBirthday__',
        '__myNumberCardVerifiedGender__',
    ],
    scheduleSyncCycleTypes: ['daily', 'weekday', 'weekly', 'monthly'],
    endOfMonth: 'End of month',
    daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    // オフピーク，0~8, 20~23
    offPeakScheduleSyncHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 20, 21, 22, 23],
    notificationOffset: 89, // フォーム管理画面のヘッダーから8pxずらした値
    sortDirection: {
        asc: 'ASC',
        desc: 'DESC',
    },
};
export default c;
