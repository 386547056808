import { Notification, MessageBox } from 'element-ui';
import scroll from 'vue-scrollto';
import isMobile from 'ismobilejs';
import xssFilter from 'dompurify';
import { trans as t, transOrDefault } from '../filters/trans';
import c from '../const';
const MAX_USAGE_LINKS_SIZE = 10;
xssFilter.addHook('afterSanitizeAttributes', (node) => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
    }
});
export function parseQueryString(query) {
    const params = new URLSearchParams(query);
    return [...params.keys()].reduce((obj, key) => ({ ...obj, [key]: params.getAll(key).length === 1 ? params.get(key) : params.getAll(key) }), {});
}
const q = parseQueryString(window.location.search);
const u = {
    query: (key) => {
        if (key in q) {
            return q[key];
        }
        return undefined;
    },
    escape: (value) => xssFilter.sanitize(value),
    trans: (val, params = {}, locale = null) => 
    // TODO: u.trans ではなく trans を使うようにする
    t(val, params, locale),
    usageLinks: (featureName) => Array(MAX_USAGE_LINKS_SIZE)
        .fill(null)
        .map((_, idx) => ({
        text: `usageLinks.${featureName}.item${idx}.text`,
        href: `usageLinks.${featureName}.item${idx}.href`,
    }))
        .filter((link) => !!transOrDefault(link.text, false)),
    isMobile: () => isMobile().phone,
    changeTitle(subtitle) {
        document.title = `${u.trans(subtitle)} | ${u.trans('service.name')}`;
    },
    notify(type, title, message) {
        Notification({
            title: t(title),
            message: t(message),
            type,
            customClass: 'break-all',
        });
    },
    success(vm, message, params = {}) {
        Notification.success({
            title: t('Success'),
            message: t(message, params),
            offset: c.notificationOffset,
            duration: 3000,
            customClass: 'break-all',
        });
        if (typeof user !== 'undefined' && typeof vm.$gtag !== 'undefined' && user.plan === 'trial') {
            vm.$gtag.event(t(message, params), { event_category: 'Success' });
        }
    },
    warning(vm, message) {
        Notification.warning({
            title: t('Warning'),
            message: t(message),
            offset: c.notificationOffset,
            customClass: 'break-all',
        });
        if (typeof user !== 'undefined' && typeof vm.$gtag !== 'undefined' && user.plan === 'trial') {
            vm.$gtag.event(t(message), { event_category: 'Warning' });
        }
    },
    info(vm, message) {
        Notification.info({
            title: t('Information'),
            message: t(message),
            offset: c.notificationOffset,
            customClass: 'break-all',
        });
        if (typeof user !== 'undefined' && typeof vm.$gtag !== 'undefined' && user.plan === 'trial') {
            vm.$gtag.event(t(message), { event_category: 'Information' });
        }
    },
    error(vm, errObj, options = {}) {
        const message = u.pickErrorMessage(errObj);
        Notification.error({
            title: t('Error'),
            message: t(message),
            offset: c.notificationOffset,
            customClass: 'break-all',
            ...options,
        });
        if (typeof user !== 'undefined' && typeof vm.$gtag !== 'undefined' && user.plan === 'trial') {
            vm.$gtag.event(t(message), { event_category: 'Error' });
        }
    },
    async confirm(options) {
        const defaultOptions = {
            dangerouslyUseHTMLString: true,
            confirmButtonText: u.trans('Activate'),
            confirmButtonClass: 'ui blue small button',
            cancelButtonText: u.trans('Cancel'),
            cancelButtonClass: 'ui basic small button',
            type: 'warning',
        };
        try {
            await MessageBox.confirm('', { ...defaultOptions, ...options });
            return true;
        }
        catch {
            return false;
        }
    },
    pickErrorMessage: (errObj) => {
        if (typeof errObj === 'string') {
            return errObj;
        }
        const resp = errObj.response;
        if (resp && resp.data && resp.data.error) {
            return resp.data.error;
        }
        if (resp && resp.error) {
            return resp.error;
        }
        return errObj.message;
    },
    scroll: (vm) => {
        scroll.scrollTo(vm.$el, 500, {
            easing: 'ease',
            offset: -30,
            force: false,
        });
    },
    isUnique: (arr) => arr.filter((x, i, self) => self.indexOf(x) === i && i !== self.lastIndexOf(x)).length === 0,
    // FIXME: DateTime,File,Tableなどのフィールドは適切に文字列に変換されない
    replaceValues: (str, record) => Object.entries(record).reduce((replacedStr, [fieldCode, value]) => replacedStr.replaceAll(`{{${fieldCode}}}`, String(value.value)), str),
    generateUrl: (baseUrl, params = {}) => {
        const queryParams = [];
        if (Object.keys(params).length > 0) {
            const add = (key, value) => {
                // if value is a function then call it and assign it's return value as value
                let val = typeof value === 'function' ? value() : value;
                // change null to empty string
                val = val === null ? '' : val;
                queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
            };
            const buildQueryParams = (prefix, paras, ad) => {
                const rbracket = /\[\]$/;
                if (paras instanceof Array) {
                    paras.forEach((val, i) => {
                        if (rbracket.test(prefix)) {
                            add(prefix, val);
                        }
                        else {
                            buildQueryParams(`${prefix}[${typeof val === 'object' ? i : ''}]`, val, ad);
                        }
                    });
                }
                else if (typeof paras === 'object') {
                    Object.entries(paras).forEach(([name, param]) => {
                        buildQueryParams(`${prefix}[${name}]`, param, ad);
                    });
                }
                else {
                    ad(prefix, paras);
                }
            };
            Object.entries(params).forEach(([prefix, param]) => {
                buildQueryParams(prefix, param, add);
            });
            return `${baseUrl}?${queryParams.join('&').replace(/%20/g, '+')}`;
        }
        return baseUrl;
    },
    sleep: (milisec) => new Promise((resolve) => {
        setTimeout(resolve, milisec);
    }),
    sleepBy: (callback, milisec) => new Promise((resolve) => {
        const timer = setInterval(async () => {
            if (await callback()) {
                clearInterval(timer);
                resolve();
            }
        }, milisec);
    }),
    has(obj, prop) {
        return Object.prototype.hasOwnProperty.call(obj, prop);
    },
    generateUniqueKey() {
        return new Date().getTime().toString() + Math.floor(Math.random() * 10).toString();
    },
    provideUniqueKey(arr) {
        const key = 'uniqueKey';
        return arr.map((element) => {
            if (!u.has(element, key)) {
                element[key] = u.generateUniqueKey();
            }
            return element;
        });
    },
    debounce: (callback, interval = 1000) => {
        let timer;
        return function _(...args) {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                callback.apply(
                // @ts-expect-error 呼び出し元のコンテキストに準ずるため, any を許容
                this, args);
            }, interval);
        };
    },
    kebabToUpperCamel: (string) => string
        .split('-')
        .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
        .join(''),
    isUrl: (value) => {
        try {
            // eslint-disable-next-line no-new
            new URL(value);
            return true;
        }
        catch {
            return false;
        }
    },
    deepCopy(data) {
        return JSON.parse(JSON.stringify(data));
    },
    isDateObject(input) {
        return input instanceof Date || Object.prototype.toString.call(input) === '[object Date]';
    },
    isNumbersArrayEqual(arrayA, arrayB) {
        return JSON.stringify([...arrayA].sort()) === JSON.stringify([...arrayB].sort());
    },
};
export default u;
