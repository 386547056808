<template>
  <div v-show="show" class="row">
    <public-field v-for="field in row" :key="field.id" :field="field" />
  </div>
</template>

<script>
import fu from '../../utils/field-utils';
import PublicField from './PublicField';

export default {
  name: 'FieldRow',
  components: { PublicField },
  props: ['row'],
  computed: {
    show() {
      return this.row.some((field) => fu.isShowField(field));
    },
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 1em;
}
</style>
