<template>
  <el-input v-model.number="val" :disabled="!field.editable"></el-input>
</template>

<script>
export default {
  name: 'FbCalc',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? Number(this.value) : 0;
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  mounted() {
    this.$el.getElementsByTagName('input')[0].style.textAlign = 'right';
  },
};
</script>

<style scoped></style>
