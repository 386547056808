<template>
  <div>
    <form-title :form="form" />
    <preview-navigation v-if="isPreview" />
    <div class="ui segment">
      <div class="mb1">
        <div v-if="form.isFinishHtml" v-html="finishHtml"></div>
        <div v-else>
          <div class="ui center aligned grid">
            <i class="massive olive check icon"></i>
          </div>
          <div class="ui center aligned grid">
            <h2>{{ finishMessage | trans }}</h2>
          </div>
        </div>
        <div class="wrapper">
          <my-page :form="form" :record="record"></my-page>
        </div>
        <div class="wrapper">
          <print-creator v-if="form.isUsePrintCreator" :form="form" :record="record"></print-creator>
        </div>
      </div>
    </div>
    <error-logs v-if="isPreview"></error-logs>
  </div>
</template>

<script>
import ErrorLogs from 'Public/ErrorLogs';
import PrintCreator from 'Public/PrintCreator';
import MyPage from 'Public/MyPage';
import FormTitle from 'Public/Header/FormTitle';
import PreviewNavigation from 'Public/Header/PreviewNavigation';
import su from '../utils/storage-utils';
import u from '../utils/utils';
import { getPathType } from '../utils/url-utils';

export default {
  name: 'PublicFormFinish',
  components: { MyPage, PrintCreator, ErrorLogs, FormTitle, PreviewNavigation },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.form.googleAnalytics && vm.$store.state.form.trackingId) {
        vm.$gtag.event(`submit: ${to.params.code}`, {
          event_category: 'Form',
        });
      }
    });
  },
  computed: {
    form() {
      return this.$store.state.form;
    },
    record() {
      return this.$store.state.record;
    },
    finishHtml() {
      return u.replaceValues(this.form.finishHtml, this.record);
    },
    finishMessage() {
      return u.replaceValues(this.form.finishMessage, this.record);
    },
    isPreview() {
      return getPathType(window.location.pathname) === 'private';
    },
    canUseStorage() {
      return su.canUse('IndexedDB') || su.canUse('localStorage') || false;
    },
  },
  created() {
    this.$store.commit('callEvents', { events: fb.events.finish.created });
  },
  mounted() {
    this.$store.commit('callEvents', { events: fb.events.finish.mounted });
    this.$scrollTo('body');
    if (this.$store.state.submitted && this.$store.state.form.temporarySave) {
      su.remove({ key: this.$store.state.form.code }, this.canUseStorage);
    }
  },
};
</script>

<style scoped>
.wrapper > .ui {
  margin-top: 3em;
}
.mb1 {
  margin-bottom: 1em;
}
</style>
