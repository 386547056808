<template>
  <button
    class="ui button"
    :class="[color, { labeled, disabled, icon: shouldIconClassAppended }]"
    :type="type"
    @click="$emit('click')"
  >
    <i class="icon" :class="icon"></i>
    <template v-if="hasLabel">
      {{ label | trans }}
    </template>
    <span v-if="moreOptions"> ...</span>
  </button>
</template>

<script>
/**
 * Available events:
 *  - click
 */
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: '',
    },
    labeled: {
      type: Boolean,
      default: false,
    },
    moreOptions: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasLabel() {
      return this.label !== null;
    },
    shouldIconClassAppended() {
      // - アイコンのみの表示(ラベルなし)の場合は `.icon` をつけて左右 padding を狭める
      // - 背景色が強制的に白にされる(Semantic UIのスタイル定義)のを回避するため、
      //   `.labeled` をつけるときは必ず `.icon` を付けるようにする
      // - ラベル文字数が大きい場合は icon を付けてボタン表示幅を狭める
      return !this.hasLabel || this.labeled || this.label.length > 10;
    },
  },
};
</script>
