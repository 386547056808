<template>
  <td :class="['field', { required: field.required, error: errors.has(vName) }]" :style="[styles, showField]">
    <component
      :is="type"
      v-if="field.code"
      v-model="value"
      v-validate="validations"
      :field="field"
      :table-field="tableField"
      :value="value"
      :is-mobile="isMobile"
      :width="'100%'"
      :data-vv-name="vName"
      @update="update"
    ></component>
    <div v-if="field.code" v-show="errors.has(vName)" class="el-form-item__error">
      {{ errors.first(vName) }}
    </div>
  </td>
</template>

<script>
import u from 'utils/utils';
import fu from 'utils/field-utils';
import FbSingleLineText from 'Field/SingleLineText';
import FbNumber from 'Field/Number';
import FbCalc from 'Field/Calc';
import FbMultiLineText from 'Field/MultiLineText';
import FbRichText from 'Field/RichText';
import FbCheckBox from 'Field/CheckBox';
import FbRadioButton from 'Field/RadioButton';
import FbDropDown from 'Field/DropDown';
import FbMultiSelect from 'Field/MultiSelect';
import FbTime from 'Field/Time';
import FbDate from 'Field/Date';
import FbDatetime from 'Field/Datetime';
import FbTableFile from 'Field/TableField/File';
import FbKviewerLookup from 'Field/KviewerLookup';
import FbRate from 'Field/Rate';
import FbSlider from 'Field/Slider';
import FbCascader from 'Field/Cascader';
import FbMobileTime from 'MobileField/Time';
import FbMobileDate from 'MobileField/Date';
import FbMobileDatetime from 'MobileField/Datetime';
import FbMobileCheckBox from 'MobileField/CheckBox';
import FbMobileRadioButton from 'MobileField/RadioButton';
import FbMobileDropDown from 'MobileField/DropDown';
import FbMobileMultiSelect from 'MobileField/MultiSelect';

export default {
  name: 'TableField',
  components: {
    FbSingleLineText,
    FbNumber,
    FbCalc,
    FbMultiLineText,
    FbRichText,
    FbCheckBox,
    FbRadioButton,
    FbDropDown,
    FbMultiSelect,
    FbTime,
    FbDate,
    FbDatetime,
    FbTableFile,
    FbKviewerLookup,
    FbRate,
    FbSlider,
    FbCascader,
    FbMobileTime,
    FbMobileDate,
    FbMobileDatetime,
    FbMobileCheckBox,
    FbMobileRadioButton,
    FbMobileDropDown,
    FbMobileMultiSelect,
  },
  inject: ['$validator'],
  props: ['tableField', 'tableIndex', 'field', 'tableRecord'],
  computed: {
    value: {
      get() {
        return this.tableRecord.value[this.field.code].value;
      },
      set(value) {
        this.update(value);
      },
    },
    styles() {
      return u.isMobile() ? this.field.spStyle : this.field.style;
    },
    vName() {
      // A field code of the field inside of the sub table could be duplicated with the code
      // of normal field (i.e. top level field) or field inside of another sub table.
      // For assuring the uniqueness of VM name, etc., especially in validation, here makes the
      // string from the field code which the table properties has been appended as a prefix.
      return `${this.tableField.code}-${this.tableIndex}-${this.field.code}`;
    },
    showField() {
      if (fu.isShowTableField(this.field)) {
        return {};
      }
      return { display: 'none !important' };
    },
    type() {
      const type = this.field.type.toLowerCase().replace(/_/g, '-');
      if (type === 'file') {
        return `fb-table-${type}`;
      }
      return `fb-${this.isMobile ? 'mobile-' : ''}${type}`;
    },
    validations() {
      const validations = {};
      this.field.validations.forEach((v) => {
        if (v.rule === 'url' && !u.has(v.params, 'require_protocol')) {
          v.params = { require_protocol: true };
        }
        validations[v.rule] = v.params ? v.params : true;
      });
      return {
        rules: validations,
      };
    },
    isMobile() {
      return this.field.isMobileStyle && u.isMobile();
    },
  },
  methods: {
    update(value) {
      if (!this.$el) {
        return;
      }
      this.$store.dispatch('updateTableField', {
        tableCode: this.tableField.code,
        tableIndex: this.tableIndex,
        code: this.field.code,
        type: this.field.type,
        value,
        vm: this,
      });
      this.$validator.validate(this.vName, this.value);
    },
  },
};
</script>

<style scoped>
.el-form-item__error {
  position: inherit;
}
</style>
