import Translator from 'bazinga-translator';
import enJson from '../../../shared/generated/translations/messages/en.json';
import cnJson from '../../../shared/generated/translations/messages/cn.json';
import jaJson from '../../../shared/generated/translations/messages/ja.json';
const jsonStr = JSON.stringify({
    translations: { ...jaJson.translations, ...enJson.translations, ...cnJson.translations },
});
Translator.fromJSON(jsonStr);
/**
 * why: transとuserTransがなぜ分かれているか
 * フィールド設定等でユーザーが登録した項目などシステム側のtransをかけたくない場合がある
 * その際にシステム辞書と分けて辞書を作ることでシステム側とは別に表示を切り分けられる様にしている
 *
 * domain=null system辞書を優先で取得しなければuser辞書を取得する
 * domain=messages system辞書
 * domain=USER user辞書
 */
export const trans = (val, params = {}, locale = null, domain = null) => 
// @ts-expect-error ts-migrate(2339)
Translator.trans(val, params, domain, locale);
/**
 * transのラッパー関数
 * 公開フォームのフィールド名等ユーザーが登録したラベル表示を利用したい際に利用する
 * こちらはユーザー辞書専用なのでシステム辞書は参照しない
 * 例：system辞書 Post => 回答 user辞書 Post => Post
 * ユーザーとしてはフィールド名「Post」が「回答」に変換されることは意図しないので userTrans を使う。
 */
export const userTrans = (val, params = {}, locale = null) => trans(val, params, locale, 'USER');
export const transOrDefault = (val, defaultVal) => {
    const s = trans(val);
    return s !== val ? s : defaultVal;
};
export const addTransDictionary = (key, message, locale = null, domain = null) => {
    // @ts-expect-error ts-migrate(2339)
    Translator.add(key, message, domain, locale);
};
const isUserType = (dict, item) => (item.domain && item.domain === 'USER') || dict.map((o) => o.key).indexOf(item.key) === -1;
export const addFormTrans = (dict) => {
    dict.forEach((item) => {
        Object.keys(item.message).forEach((lang) => {
            if (item.message[lang] && item.message[lang] !== '') {
                if (isUserType(dict, item)) {
                    addTransDictionary(item.key, item.message[lang], lang, 'USER');
                }
                addTransDictionary(item.key, item.message[lang], lang);
            }
        });
    });
};
// 公開フォーム/プレビュー/エラーページの場合はユーザー定義の辞書を登録する
// @ts-expect-error ts-migrate(2339)
if (typeof form !== 'undefined' && form.isTranslate) {
    // @ts-expect-error ts-migrate(2339)
    addFormTrans(form.dictionary);
}
/*
  Translator.locale について
  サーバー側で返した <html lang="ja"> によって決まる。
  document.documentElement.lang で取得。ない場合は Translator.fallback = en が使われる。
 */
export const getCurrentLocale = () => Translator.locale;
export const setCurrentLocale = (locale) => {
    Translator.locale = locale;
};
