<template>
  <div v-if="canUseMyPage" class="ui center aligned mt3 my-page grid">
    <a :href="record.__kViewerMyPageUrl__.value" class="ui labeled icon teal big button fb-my-page" rel="noreferrer">
      <i class="home icon"></i>
      {{ 'My page' | trans }}
    </a>
  </div>
</template>

<script>
import kviewerApi from 'api/kviewer';
import u from '../../utils/utils';

export default {
  name: 'MyPage',
  props: ['form', 'record'],
  data() {
    return {
      canUseMyPage: false,
    };
  },
  created() {
    if (!this.form.isUseMyPage) return;
    const requiredKeys = [
      '__kViewerViewCode__',
      '__kViewerRecordCode__',
      '__kViewerSubdomain__',
      '__kViewerMyPageUrl__',
    ];

    if (requiredKeys.some((key) => !u.has(this.record, key))) {
      return;
    }

    const viewCode = this.record.__kViewerViewCode__.value;
    const recordCode = this.record.__kViewerRecordCode__.value;
    const subdomain = this.record.__kViewerSubdomain__.value;

    kviewerApi
      .checkRecord(viewCode, recordCode, subdomain)
      .then(() => {
        this.canUseMyPage = true;
      })
      .catch((e) => {
        if (e.response.status !== 404) {
          this.canUseMyPage = true;
          return;
        }
        this.canUseMyPage = false;
      });
  },
};
</script>

<style scoped></style>
