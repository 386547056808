import Vue from 'vue';
import AddExternalResourceDialog from 'Preview/AddExternalResourceDialog.vue';
const csp = {
    isPreviewExternalResource(form) {
        return form.isUseExternalResource && window.location.pathname.indexOf('/private') === 0;
    },
    addSecurityPolicyViolationEvent(vm) {
        window.addEventListener('securitypolicyviolation', (e) => {
            const DialogComponent = Vue.extend(AddExternalResourceDialog);
            const dialogVm = new DialogComponent({
                el: document.createElement('div'),
                propsData: {
                    formCode: vm.form.code,
                    violatedDirective: e.violatedDirective,
                    blockedUri: e.blockedURI,
                },
            });
            document.body.appendChild(dialogVm.$el);
            Vue.nextTick(() => {
                // @ts-expect-error ts-migrate(2339)
                dialogVm.visible = true;
            });
        });
    },
};
export default csp;
