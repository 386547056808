import { publicFormCorsAxiosClient, mainAxiosClient } from 'api/axios';
import u from 'utils/utils';
import { getUriFromRouteName } from 'utils/routing-utils';
import { buildKviewerUrl, KV_ROUTE_VIEW_RECORD, KV_ROUTE_VIEW_RECORDS } from 'utils/kviewer-url-utils';
import { KV_PARENT_CODE_QUERY_KEY } from 'const/kviewer';
function fetchViews(form, viewType) {
    return mainAxiosClient.get(getUriFromRouteName('api_kviewer_views', {
        token: form.kviewerToken,
        viewType,
    }));
}
function fetchSubdomain(token) {
    return mainAxiosClient.get(getUriFromRouteName('api_kviewer_subdomain', { token }));
}
function fetchRecord(viewCode, recordCode, subdomain = undefined) {
    const params = { route: KV_ROUTE_VIEW_RECORD, viewCode, recordCode, subdomain };
    const kViewerParentCode = u.query(KV_PARENT_CODE_QUERY_KEY);
    if (kViewerParentCode && typeof kViewerParentCode === 'string') {
        params.parentCode = kViewerParentCode;
    }
    return publicFormCorsAxiosClient.get(buildKviewerUrl(params));
}
/**
 * 詳細ページの存在確認リクエストメソッド
 *
 * レスポンスが 404 でなければ対象のページが存在する, と判定する(i.e. ビューに簡易認証が設定されている場合401が返る).
 */
function checkRecord(viewCode, recordCode, subdomain = undefined) {
    return publicFormCorsAxiosClient.get(buildKviewerUrl({ route: KV_ROUTE_VIEW_RECORD, viewCode, recordCode, subdomain }), 
    // デフォルトの設定(withCredentials: true)では以下の問題が発生する
    //   - ビューに簡易認証が設定されている: authorization ヘッダが返ってくることで basic 認証情報の入力ダイアログが表示される.
    //   - ビューにkintoneApp認証が設定されている: kintoneApp サーバに allow-credentials が設定されていないため CORS エラーになる.
    // 詳細ページの存在確認のためのリクエストとして上記を回避するために false にしてリクエストを投げる.
    { withCredentials: false });
}
function fetchRecords(viewCode, page = 1, subdomain = undefined, additionalFilters = []) {
    const baseUrl = buildKviewerUrl({ route: KV_ROUTE_VIEW_RECORDS, viewCode, page, subdomain });
    const url = u.generateUrl(baseUrl, { additionalFilters });
    return publicFormCorsAxiosClient.get(url);
}
// TODO: 以下は削除し named-export のみにする (互換性のためにのみ定義を残している)
export default {
    fetchViews,
    fetchRecord,
    checkRecord,
};
export { fetchViews, fetchSubdomain, fetchRecord, checkRecord, fetchRecords };
