import util from './utils';
import { isNumberField } from './calc-utils';
import { kintoneHtmlToQuillHtml } from './rich-text-utils';
import c from '../const';
export const types = {
    noValue: ['LABEL', 'HR'],
};
const systemValueFields = [
    {
        label: util.trans('Title of form'),
        type: 'SINGLE_LINE_TEXT',
        code: '__title__',
    },
    {
        label: util.trans('Submitter language setting'),
        type: 'SINGLE_LINE_TEXT',
        code: '__locale__',
    },
    {
        label: util.trans('Submitter ip address'),
        type: 'SINGLE_LINE_TEXT',
        code: '__client_ip__',
    },
    {
        label: util.trans('Submitter referer'),
        type: 'SINGLE_LINE_TEXT',
        code: '__client_referer__',
    },
    {
        label: util.trans('Submitter OS'),
        type: 'SINGLE_LINE_TEXT',
        code: '__client_os__',
    },
    {
        label: util.trans('Submitter browser'),
        type: 'SINGLE_LINE_TEXT',
        code: '__client_browser__',
    },
    {
        label: util.trans('Submitter user agent'),
        type: 'SINGLE_LINE_TEXT',
        code: '__client_user_agent__',
    },
    {
        label: util.trans('Submitted at'),
        type: 'DATETIME',
        code: '__posted_at__',
    },
];
export function fieldsToFieldRows(fields) {
    const rows = [];
    fields.forEach((field) => {
        let stored = false;
        rows.forEach((row) => {
            if (row[0].rowCount === field.rowCount) {
                row.push(field);
                stored = true;
            }
        });
        if (!stored) {
            rows.push([field]);
        }
    });
    return rows;
}
export function sortFields(rows) {
    const sortedRows = rows.map((row) => {
        const sortedRow = row.map((field) => {
            const sortedField = { ...field };
            if (field.fields !== undefined) {
                sortedField.fields = [...field.fields].sort((f1, f2) => f1.columnCount - f2.columnCount);
            }
            return sortedField;
        });
        sortedRow.sort((f1, f2) => f1.columnCount - f2.columnCount);
        return sortedRow;
    });
    sortedRows.sort((row1, row2) => row1[0].rowCount - row2[0].rowCount);
    return sortedRows;
}
function hasFieldCode(code, fields) {
    for (let i = 0, l = fields.length; i < l; i += 1) {
        if (fields[i].code === code) {
            return true;
        }
    }
    return false;
}
function isUniqueCode(code, fields) {
    let match = 0;
    for (let i = 0, l = fields.length; i < l; i += 1) {
        if (fields[i].code === code) {
            match += 1;
        }
    }
    return match <= 1;
}
export function generateFieldCode(type, fields) {
    let code = type.toLowerCase();
    while (true) {
        if (!hasFieldCode(code, fields)) {
            return code;
        }
        code += '_1';
    }
}
function generateMergeField(kintoneField, fbFields) {
    if (hasFieldCode(kintoneField.code, fbFields)) {
        return generateFieldCode(kintoneField.type, fbFields);
    }
    return kintoneField.code;
}
export function generateField(type, label, fields) {
    const field = {
        type,
        code: generateFieldCode(type, fields),
        label: util.trans(label),
        rowCount: 0,
        columnCount: 0,
        noLabel: false,
        hide: false,
        required: false,
        editable: true,
        maxValue: null,
        minValue: null,
        maxLength: 0,
        minLength: 0,
        defaultValue: '',
        options: [],
        optionOrder: 'horizontal',
        expression: '',
        protocol: '',
        help: '',
        step: 5,
        isUseLookup: false,
        lookup: {
            view: {},
            labelFields: [],
            fieldMappings: [],
        },
        kviewerRecords: [],
        style: { width: '300px' },
        spStyle: {},
        attributes: {},
        validations: [],
        fields: [],
        fieldPosition: null,
    };
    switch (type) {
        case 'LABEL':
            break;
        case 'SINGLE_LINE_TEXT':
            field.attributes = { type: 'text' };
            break;
        case 'MULTI_LINE_TEXT':
            field.attributes = { rows: 5 };
            break;
        case 'NUMBER':
            // @ts-expect-error ts-migrate(2322) FIXME: Type '0' is not assignable to type 'string'.
            field.defaultValue = 0;
            break;
        case 'CALC':
            // @ts-expect-error ts-migrate(2322) FIXME: Type '0' is not assignable to type 'string'.
            field.defaultValue = 0;
            field.editable = false;
            break;
        case 'SLIDER':
            // @ts-expect-error ts-migrate(2322) FIXME: Type '0' is not assignable to type 'string'.
            field.defaultValue = 0;
            // @ts-expect-error ts-migrate(2322) FIXME: Type '0' is not assignable to type 'null'.
            field.minValue = 0;
            // @ts-expect-error ts-migrate(2322) FIXME: Type '100' is not assignable to type 'null'.
            field.maxValue = 100;
            field.step = 10;
            break;
        case 'RADIO_BUTTON':
            field.required = true;
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
            field.validations = [{ rule: 'required', params: {} }];
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
            field.options = ['sample1', 'sample2'];
            break;
        case 'RATE':
            field.required = true;
            // @ts-expect-error ts-migrate(2322) FIXME: Type '3' is not assignable to type 'string'.
            field.defaultValue = 3;
            // @ts-expect-error ts-migrate(2322) FIXME: Type '1' is not assignable to type 'null'.
            field.minValue = 1;
            // @ts-expect-error ts-migrate(2322) FIXME: Type '5' is not assignable to type 'null'.
            field.maxValue = 5;
            break;
        case 'CHECK_BOX':
        case 'MULTI_SELECT':
        case 'DROP_DOWN':
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
            field.options = ['sample1', 'sample2'];
            break;
        case 'CASCADER':
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'never[]' is not assignable to type 'string'.
            field.defaultValue = [];
            break;
        case 'FILE':
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
            field.validations = [{ rule: 'ext', params: [] }];
            break;
        case 'SUBTABLE':
            // @ts-expect-error ts-migrate(2741) FIXME: Property 'width' is missing in type '{}' but requi... Remove this comment to see the full error message
            field.style = {};
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
            field.maxLength = null;
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
            field.minLength = null;
            break;
        case 'HR':
            field.noLabel = true;
            field.style = { width: '100%' };
            break;
        case 'KVIEWER_LOOKUP':
            field.isUseLookup = true;
            break;
        default:
            break;
    }
    return field;
}
function createFieldByKintoneField(kintoneField, fields) {
    let field = fields.find((f) => f.code === kintoneField.code);
    ['type', 'code', 'label', 'expression', 'protocol'].forEach((key) => {
        if (util.has(kintoneField, key)) {
            kintoneField[key] = kintoneField[key] ? kintoneField[key] : '';
        }
        else {
            kintoneField[key] = null;
        }
    });
    if (!Array.isArray(kintoneField.options)) {
        kintoneField.options = [];
    }
    switch (kintoneField.type) {
        case 'SUBTABLE': {
            kintoneField.noLabel = true;
            let childFields = [];
            if (field) {
                childFields = field.fields;
            }
            kintoneField.fields.forEach((f) => {
                createFieldByKintoneField(f, childFields);
            });
            break;
        }
        case 'SINGLE_LINE_TEXT':
            kintoneField.attributes = { type: 'text' };
            break;
        case 'LINK':
            kintoneField.type = 'SINGLE_LINE_TEXT';
            kintoneField.attributes = { type: '' };
            if (kintoneField.protocol === 'WEB') {
                kintoneField.attributes.type = 'url';
            }
            if (kintoneField.protocol === 'MAIL') {
                kintoneField.attributes.type = 'email';
            }
            if (kintoneField.protocol === 'CALL') {
                kintoneField.attributes.type = 'tel';
            }
            break;
        case 'NUMBER':
            if (kintoneField.defaultValue !== null) {
                kintoneField.defaultValue = Number(kintoneField.defaultValue).toString();
            }
            break;
        case 'DATE':
        case 'TIME':
        case 'DATETIME':
            if (kintoneField.defaultNowValue) {
                kintoneField.defaultValue = 'NOW';
            }
            break;
        case 'CHECK_BOX':
        case 'MULTI_SELECT':
            if (!Array.isArray(kintoneField.defaultValue)) {
                kintoneField.defaultValue = [];
            }
            break;
        case 'LABEL':
            kintoneField.code = generateFieldCode(kintoneField.type, fields);
            kintoneField.label = kintoneHtmlToQuillHtml(kintoneField.label);
            break;
        case 'HR':
            kintoneField.code = generateFieldCode(kintoneField.type, fields);
            break;
        default:
            break;
    }
    if (field) {
        kintoneField.mergeField = field.code;
    }
    else {
        field = generateField(kintoneField.type, kintoneField.label, fields);
        kintoneField.mergeField = null;
    }
    Object.keys(field).forEach((key) => {
        if (!util.has(kintoneField, key)) {
            kintoneField[key] = field[key];
        }
    });
    return kintoneField;
}
function mergeField(field, pullField) {
    if (!field || !util.has(field, 'type') || !util.has(c.syncList, field.type)) {
        return;
    }
    if (util.has(pullField, 'type') && field.type !== pullField.type) {
        return;
    }
    c.syncList[field.type].forEach((key) => {
        if (util.has(field, key) && util.has(pullField, key)) {
            if (key === 'fields') {
                pullField.fields.forEach((childPullField) => {
                    const childField = field.fields.find((f) => f.code === childPullField.code);
                    if (childField) {
                        mergeField(childField, childPullField);
                    }
                    else {
                        childPullField.rowCount = 0;
                        childPullField.columnCount = field.fields.length;
                        field.fields.push(childPullField);
                    }
                });
            }
            else {
                field[key] = pullField[key];
            }
        }
    });
}
export const findFieldByCode = (code, fields) => fields.find((field) => field.code === code);
function findFieldVm(field, $vms) {
    let targetVM;
    $vms.forEach((childVM) => {
        if (targetVM) {
            return;
        }
        if (childVM.$options.name === 'PublicField' && field.code === childVM.field.code) {
            targetVM = childVM;
            return;
        }
        if (childVM.$children.length > 0) {
            const vm = findFieldVm(field, childVM.$children);
            if (vm) {
                targetVM = vm;
            }
        }
    });
    return targetVM;
}
const findTableFieldVm = (field, $vms) => $vms.find((childVM) => childVM.$options.name === 'TableField' && field.code === childVM.field.code);
function findFieldOrTableFieldVmByCodes(fieldCodes, $vms) {
    let targetVM;
    $vms.forEach((childVM) => {
        if (targetVM) {
            return;
        }
        if ((childVM.$options.name === 'PublicField' && fieldCodes.includes(childVM.field.code)) ||
            (childVM.$options.name === 'TableField' && fieldCodes.includes(childVM.vName))) {
            targetVM = childVM;
            return;
        }
        if (childVM.$children.length > 0) {
            const vm = findFieldOrTableFieldVmByCodes(fieldCodes, childVM.$children);
            if (vm) {
                targetVM = vm;
            }
        }
    });
    return targetVM;
}
function findVmByCode(code, $vms, vmName = 'PublicField') {
    let targetVM;
    $vms.forEach((childVM) => {
        if (targetVM) {
            return;
        }
        if (childVM.$options.name === vmName && code === childVM.field.code) {
            targetVM = childVM;
            return;
        }
        if (childVM.$children.length > 0) {
            const vm = findVmByCode(code, childVM.$children, vmName);
            if (vm) {
                targetVM = vm;
            }
        }
    });
    return targetVM;
}
function isShowField(field) {
    const style = util.isMobile() ? field.spStyle : field.style;
    return !field.hide && field.show && !('display' in style && style.display === 'none');
}
function isShowTableField(field) {
    const style = util.isMobile() ? field.spStyle : field.style;
    return !field.hide && !('display' in style && style.display === 'none');
}
function mergeAuthenticationFields(fields) {
    fields.push({
        label: util.trans('Authentication name'),
        type: 'SINGLE_LINE_TEXT',
        code: '__authenticationName__',
    });
    fields.push({
        label: util.trans('Authentication username'),
        type: 'SINGLE_LINE_TEXT',
        code: '__authenticationUsername__',
    });
}
function mergeMyNumberCardAuthenticationFields(fields) {
    fields.push({
        label: util.trans('Name of My Number Card'),
        type: 'SINGLE_LINE_TEXT',
        code: '__myNumberCardVerifiedName__',
    });
    fields.push({
        label: util.trans('Address of My Number Card'),
        type: 'SINGLE_LINE_TEXT',
        code: '__myNumberCardVerifiedAddress__',
    });
    fields.push({
        label: util.trans('Birthdate of My Number Card'),
        type: 'SINGLE_LINE_TEXT',
        code: '__myNumberCardVerifiedBirthdate__',
    });
    fields.push({
        label: util.trans('Gender of My Number Card'),
        type: 'SINGLE_LINE_TEXT',
        code: '__myNumberCardVerifiedGender__',
    });
}
function mergeKintoneAppAuthenticationFields(fields) {
    fields.push({
        label: util.trans('User management setting Email'),
        type: 'SINGLE_LINE_TEXT',
        code: '__kintoneAppAuthenticationEmail__',
    });
}
function mergeBridgeResultFields(fields, form, bridges, index) {
    let kintoneBridgeCount = 1;
    let autoIncrementAdded = false;
    for (let i = 0, l = index; i >= 0 && i < l; i += 1) {
        switch (bridges[i].type) {
            case 'KintoneBridge':
                fields.push({
                    label: util.trans('Kintone record number'),
                    type: 'NUMBER',
                    code: `__kintoneBridgeRecordId__${kintoneBridgeCount}`,
                });
                fields.push({
                    label: util.trans('Kintone record URL'),
                    type: 'SINGLE_LINE_TEXT',
                    code: `__kintoneBridgeRecordUrl__${kintoneBridgeCount}`,
                });
                kintoneBridgeCount += 1;
                if (form.isUseMyPage) {
                    fields.push({
                        label: util.trans('My page URL'),
                        type: 'SINGLE_LINE_TEXT',
                        code: '__kViewerMyPageUrl__',
                    });
                }
                break;
            case 'AutoIncrementBridge':
                if (autoIncrementAdded) {
                    break;
                }
                autoIncrementAdded = true;
                fields.push({
                    label: util.trans('Auto assigned number by month'),
                    type: 'SINGLE_LINE_TEXT',
                    code: '__auto_increment_by_month__',
                });
                fields.push({
                    label: util.trans('Auto assigned number by day'),
                    type: 'SINGLE_LINE_TEXT',
                    code: '__auto_increment_by_day__',
                });
                fields.push({
                    label: util.trans('Auto assigned number'),
                    type: bridges[i].autoIncrementBridge.isZeroPadding ? 'SINGLE_LINE_TEXT' : 'NUMBER',
                    code: '__auto_increment__',
                });
                break;
            case 'KmailerBridge':
                fields.push({
                    label: util.trans('kMailer mail id'),
                    type: 'SINGLE_LINE_TEXT',
                    code: '__kMailerMailId__',
                });
                break;
            default:
                break;
        }
    }
}
function depthOptions(options) {
    let depth = 1;
    options.forEach((option) => {
        if (Array.isArray(option.children)) {
            depth = Math.max(depthOptions(option.children) + 1, depth);
        }
    });
    return depth;
}
function mergeSplitCascaderFields(fields) {
    fields.forEach((field) => {
        if (field.type === 'CASCADER') {
            const depth = depthOptions(field.options);
            for (let i = 1; i <= depth; i += 1) {
                fields.push({
                    label: `${field.label}(${util.trans('Level-%depth%', { depth: i })})`,
                    type: 'SINGLE_LINE_TEXT',
                    code: `${field.code}_split_cascader_${i}`,
                });
            }
        }
        else if (field.type === 'SUBTABLE') {
            mergeSplitCascaderFields(field.fields);
        }
    });
}
function mergeSystemFields(fields, form, bridge, bridges, recipe) {
    if (!fields || !form) {
        return [];
    }
    let flds = JSON.parse(JSON.stringify(fields));
    flds = flds.concat(systemValueFields);
    mergeSplitCascaderFields(flds);
    if (form.isUseAuthentication) {
        mergeAuthenticationFields(flds);
    }
    else if (form.isUseMailAuthentication) {
        mergeKintoneAppAuthenticationFields(flds);
    }
    if (recipe.isUseMynumberAuthentication && ['KintoneBridge', 'AutoReplyBridge'].includes(bridge.type)) {
        mergeMyNumberCardAuthenticationFields(flds);
    }
    const index = bridges.findIndex((b) => (bridge.id ? b.id === bridge.id : b.sort === bridge.sort));
    if (index > 0) {
        flds.push({
            label: util.trans('Error message'),
            type: 'SINGLE_LINE_TEXT',
            code: '__errorMessage__',
        });
        flds.push({
            label: util.trans('URL for error history'),
            type: 'SINGLE_LINE_TEXT',
            code: '__errorPostUrl__',
        });
    }
    if (bridge && bridges.length > 0 && index > 0) {
        mergeBridgeResultFields(flds, form, bridges, index);
    }
    return flds;
}
const canUseDestinationField = (field) => ['LABEL', 'HR', 'CALC'].indexOf(field.type) === -1;
/**
 * 公開フォーム表示の実行時に値の決まるプロパティを runtimeProps 以下にセットする
 */
export function setupRuntimeProps(field) {
    if (!util.has(field, 'runtimeProps')) {
        field.runtimeProps = {
            isNumberField: isNumberField(field),
        };
    }
    if (field.type === 'SUBTABLE') {
        field.fields.forEach(setupRuntimeProps);
    }
}
/**
 * メールアドレスの選択に利用可能なフィールドを返す
 */
export function collectEmailFields(fields) {
    return fields.filter((field) => field.type === 'SINGLE_LINE_TEXT' && field.protocol === 'MAIL');
}
/**
 * メールアドレスの選択に利用可能なフィールドが存在するかどうかを返す
 */
export function emailFieldExists(fields) {
    return fields.some((field) => field.type === 'SINGLE_LINE_TEXT' && field.protocol === 'MAIL');
}
/**
 * px を number 化したフィールドの幅を収集する
 */
export function collectWidths(fields) {
    const fieldsInRow = fields.fields;
    if (fieldsInRow !== undefined) {
        return collectWidths(fieldsInRow);
    }
    return fields.flatMap((f) => {
        if (f.style?.width) {
            return Number(f.style.width.replace('px', ''));
        }
        return 0;
    });
}
/**
 * fields.rowCount, fields.columnCount をもとにfieldsを昇順で並び替えを行う (非破壊的)
 * @param fields
 * @returns ソート後のField[]
 */
export function sortFlattenFields(fields) {
    return [...fields].sort((a, b) => {
        if (a.rowCount === b.rowCount) {
            return a.columnCount - b.columnCount;
        }
        return a.rowCount - b.rowCount;
    });
}
/**
 * fields.fieldPosition.rowIndex, fields.fieldPosition.columnIndex をもとにfieldsを昇順で並び替えを行う (非破壊的)
 * @param fields
 * @returns ソート後のField[]
 */
export function sortFlattenFieldsForV2(fields) {
    return [...fields].sort((a, b) => {
        if (a.fieldPosition?.rowIndex === b.fieldPosition?.rowIndex) {
            return (a.fieldPosition?.columnIndex ?? 0) - (b.fieldPosition?.columnIndex ?? 0);
        }
        return (a.fieldPosition?.rowIndex ?? 0) - (b.fieldPosition?.rowIndex ?? 0);
    });
}
/**
 * fields.rowCount, fields.columnCount をもとにフィールドの並びを再現した2次元配列を作成する
 * SUBTABLE など fields がネストしているケースに対して再帰的な処理は行わないため, その表示時
 * に個別に並び替えの対応を行う必要がある.
 */
export function make2DFields(fields) {
    const sortedFields = sortFlattenFields(fields);
    const rows = [];
    sortedFields.forEach((field) => {
        const { rowCount } = field;
        if (rows[rowCount] === undefined) {
            rows[rowCount] = [];
        }
        rows[rowCount].push(field);
    });
    // rowCount/columnCount に飛びがある場合は詰める
    return rows.filter((row) => row != null).map((row) => row.filter((field) => field != null));
}
/**
 * フィールドの2次元配列を元にrowCount/columnCountを更新, もしくは新規フィールドを追加したフィールドの配列を返す
 */
export function updateOrCreateFieldsRowAndColumnCountBy2DFields(rows, fields) {
    return rows.flatMap((fieldsInRow, rowCount) => fieldsInRow.flatMap((fieldInRow, columnCount) => {
        const field = fieldInRow.icon ? generateField(fieldInRow.type, fieldInRow.label, fields) : fieldInRow;
        field.rowCount = rowCount;
        field.columnCount = columnCount;
        return field;
    }));
}
/**
 * フィールド全体でテーブルフィールドの子フィールドを一意に識別するためのコードを生成
 *
 * テーブルフィールドの子フィールドのフィールドコードは同じテーブルフィールド内で一意な文字列であるが, それ以外の
 * フィールドとの一意性は保証されない. 全フィールドでテーブルフィールドの子フィールドを一意に識別するためにテーブル
 * フィールドのコードと組み合わせた文字列を生成する.
 */
export function buildTableChildFieldCode(tableField, columnField) {
    // フィールドコードには `_` 以外の記号が利用不可であるため, `---` (3つの連続である理由は特になし)で2つのフィールドコードを結合
    return `${tableField.code}---${columnField.code}`;
}
export function isTableChildField(fieldCode) {
    // NOTE: この判定はbuildTableChildFieldCodeで生成されるコードの形式であることに依存している
    return fieldCode.includes('---');
}
export function getTableParentFieldCode(fieldCode) {
    // NOTE: この判定はbuildTableChildFieldCodeで生成されるコードの形式であることに依存している
    return fieldCode.split('---')[0];
}
export function findFieldIncludingTableChildFieldByCode(code, fields) {
    for (const field of fields) {
        if (field.code === code) {
            return field;
        }
        if (field.type === 'SUBTABLE') {
            const childField = field.fields.find((f) => buildTableChildFieldCode(field, f) === code);
            if (childField) {
                return childField;
            }
        }
    }
    return null;
}
export function getInitialLabel(field) {
    const fieldType = c.fieldTypes.find((ft) => ft.type === field.type);
    if (fieldType == null) {
        return null;
    }
    return fieldType.label;
}
export default {
    fieldsToFieldRows,
    sortFields,
    hasFieldCode,
    isUniqueCode,
    generateFieldCode,
    generateMergeField,
    createFieldByKintoneField,
    mergeField,
    generateField,
    findFieldByCode,
    findFieldVm,
    findTableFieldVm,
    findFieldOrTableFieldVmByCodes,
    findVmByCode,
    isShowField,
    isShowTableField,
    mergeSystemFields,
    systemValueFields,
    mergeAuthenticationFields,
    mergeKintoneAppAuthenticationFields,
    mergeBridgeResultFields,
    mergeSplitCascaderFields,
    depthOptions,
    canUseDestinationField,
};
