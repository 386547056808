<template>
  <div class="ui stackable grid">
    <field-row v-for="(row, index) in rows" :key="index" :row="row"></field-row>
  </div>
</template>

<script>
import fu from 'utils/field-utils';
import FieldRow from 'Public/FieldRow';

export default {
  $_veeValidate: {
    validator: 'new',
  },
  name: 'StepFields',
  components: { FieldRow },
  props: ['step'],
  computed: {
    rows() {
      const fields = [];
      this.$store.state.fields.forEach((field) => {
        if (this.step.fields.indexOf(field.code) >= 0) {
          fields.push(field);
        }
      });
      return fu.fieldsToFieldRows(fields);
    },
  },
};
</script>

<style scoped></style>
