<template>
  <div>
    <div class="field">
      <el-steps ref="step" :active="active" process-status="finish" finish-status="success">
        <el-step
          v-for="(step, index) in form.steps"
          :key="index"
          :title="step.title | trans"
          :description="step.description | trans"
        >
        </el-step>
      </el-steps>
    </div>
    <div class="field">
      <keep-alive>
        <step-fields
          v-for="(step, index) in activeSteps"
          :ref="'stepFields'"
          :key="index"
          :step="step"
          :active="active"
        ></step-fields>
      </keep-alive>
    </div>
    <back-button v-if="active > 0" :icon="'arrow left'" :disabled="fileUploading" @back="back"></back-button>
    <next-button v-if="!isFinished" :disabled="fileUploading" @next="next"></next-button>
    <confirm-button
      v-if="isFinished && form.isUseConfirm"
      :disabled="fileUploading"
      @confirm="confirm"
    ></confirm-button>
    <post-button
      v-if="isFinished && !form.isUseConfirm"
      :disabled="posted || fileUploading"
      @post="submit"
    ></post-button>
    <temporary-save :active="active" :disabled="fileUploading" @next="next"></temporary-save>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import u from '../../utils/utils';
import fu from '../../utils/field-utils';
import StepFields from './Step/StepFields';
import TemporarySave from './TemporarySave';
import PostButton from './Button/PostButton';
import ConfirmButton from './Button/ConfirmButton';
import NextButton from './Button/NextButton';
import BackButton from './Button/BackButton';

export default {
  $_veeValidate: { validator: 'new' },
  name: 'StepForm',
  components: { StepFields, TemporarySave, PostButton, ConfirmButton, NextButton, BackButton },
  data() {
    return {
      active: 0,
      step: 100,
      form: {},
    };
  },
  computed: {
    isFinished() {
      return this.active === this.form.steps.length - 1;
    },
    activeSteps() {
      return this.form.steps.filter((_, index) => this.active === index);
    },
    ...mapState(['fileUploading']),
    ...mapGetters(['posted']),
  },
  created() {
    this.form = this.$store.state.form;
  },
  methods: {
    getActiveVm() {
      return this.$refs.stepFields[0];
    },
    scroll(vm) {
      const v = fu.findFieldOrTableFieldVmByCodes(
        this.errors.items.map((i) => i.field),
        vm.$children,
      );

      if (v) {
        u.scroll(v);
      }
    },
    back() {
      if (this.active > 0) {
        this.$store.commit('callEvents', { events: fb.events.step.back });
        this.active -= 1;
        this.$scrollTo('body');
        if (this.form.googleAnalytics && this.form.trackingId) {
          this.$gtag.event(this.form.steps[this.active].title, {
            event_category: 'StepBack',
          });
        }
      }
    },
    async next() {
      const vm = this.getActiveVm();
      const valid = await vm.$validator.validateAll().catch(() => false);
      if (valid) {
        this.$store.commit('callEvents', { events: fb.events.step.next });
        this.active += 1;
        this.$scrollTo('body');
        if (this.form.googleAnalytics && this.form.trackingId) {
          this.$gtag.event(this.form.steps[this.active].title, {
            event_category: 'StepNext',
          });
        }
      } else {
        this.scroll(vm);
      }
    },
    async confirm() {
      const vm = this.getActiveVm();
      const valid = await vm.$validator.validateAll().catch(() => false);
      if (valid) {
        this.$store.commit('callEvents', { events: fb.events.form.confirm });
        this.$emit('confirm');
      } else {
        this.scroll(vm);
      }
    },
    async submit() {
      const vm = this.getActiveVm();
      this.$store.commit('callEvents', { events: fb.events.form.submit });
      const valid = await vm.$validator.validateAll().catch(() => false);
      if (valid) {
        this.$emit('submit');
      } else {
        this.scroll(vm);
      }
    },
  },
};
</script>

<style scoped></style>
