import dayjs from 'dayjs';
export function getNowDateTimeObject() {
    return dayjs();
}
export function createDateTimeObject(date) {
    return dayjs(date);
}
export function isBefore(lhd, rhd) {
    return lhd.isBefore(rhd);
}
export function isAfter(lhd, rhd) {
    return lhd.isAfter(rhd);
}
export function formatDateWithValidation(rawDate, format) {
    const date = createDateTimeObject(rawDate);
    if (!date.isValid()) {
        return '';
    }
    return date.format(format);
}
export function formatYYYYMMDDHHmm(rawDate) {
    return formatDateWithValidation(rawDate, 'YYYY-MM-DD HH:mm');
}
export function formatTimeWithZeroPadding(rawDate) {
    return formatDateWithValidation(rawDate, 'HH:mm');
}
