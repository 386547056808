import { PLANS, PLAN_SCORES } from 'const/plans';
import { FORM_FEATURES } from 'const/form-features';
function planGtEq(lhdPlan, rhdPlan) {
    const lhdScore = PLAN_SCORES[lhdPlan];
    const rhdScore = PLAN_SCORES[rhdPlan];
    return lhdScore >= rhdScore;
}
function userPlanGtEq(rhdPlan) {
    return planGtEq(user.plan, rhdPlan);
}
function userPlanLt(rhdPlan) {
    return !userPlanGtEq(rhdPlan);
}
const FORM_FEATURES_BY_NAME = FORM_FEATURES.reduce((m, f) => m.set(f.component, f), new Map());
function componentName2plan(componentName) {
    if (!FORM_FEATURES_BY_NAME.has(componentName)) {
        console.error(`Unkown component ${componentName} has been specified.`);
    }
    const feature = FORM_FEATURES_BY_NAME.get(componentName);
    if (!feature) {
        return PLANS.NONE;
    }
    return feature.plan;
}
export { planGtEq, userPlanGtEq, userPlanLt, componentName2plan };
