import Quill from 'quill';
import Delta from 'quill-delta';
const applyAttributes = (delta, attributes) => delta.compose(new Delta().retain(delta.length(), attributes));
const kintoneOptions = {
    modules: {
        clipboard: {
            matchers: [
                ['strike', (node, delta) => applyAttributes(delta, { strike: true })],
                ['[color]', (node, delta) => applyAttributes(delta, { color: node.getAttribute('color') })],
                [
                    '[style*="background-color"]',
                    (node, delta) => {
                        const color = window.getComputedStyle(node).getPropertyValue('background-color');
                        return applyAttributes(delta, { background: color });
                    },
                ],
                [
                    'blockquote',
                    (node, delta) => {
                        // インデントを+1する
                        delta.ops = delta.ops.map((op) => {
                            if (op.attributes === undefined) {
                                op.attributes = {};
                            }
                            if (op.attributes.indent === undefined) {
                                op.attributes.indent = 0;
                            }
                            op.attributes.indent = Number(op.attributes.indent) + 1;
                            // kintoneでのblockquoteは、quillでのblockquote（引用）ではないのでfalseにする
                            op.attributes.blockquote = false;
                            return op;
                        });
                        return delta;
                    },
                ],
                ['[size="1"]', (node, delta) => applyAttributes(delta, { size: 'small' })],
                ['[size="2"]', (node, delta) => applyAttributes(delta, { size: false })],
                ['[size="4"]', (node, delta) => applyAttributes(delta, { size: 'large' })],
                ['[size="6"]', (node, delta) => applyAttributes(delta, { size: 'huge' })],
            ],
        },
    },
};
const htmlToDelta = (html, options) => {
    const div = document.createElement('div');
    div.style.display = 'none';
    document.body.appendChild(div);
    div.innerHTML = html;
    const quill = new Quill(div, options);
    const delta = quill.getContents();
    div.remove();
    return delta;
};
const deltaToQuillHtml = (delta) => {
    const div = document.createElement('div');
    const quill = new Quill(div);
    quill.setContents(delta);
    return quill.root.innerHTML;
};
export const kintoneHtmlToQuillHtml = (kintoneHtml) => {
    const delta = htmlToDelta(kintoneHtml, kintoneOptions);
    return deltaToQuillHtml(delta);
};
