<template>
  <subtable :field="field"></subtable>
</template>

<script>
import Subtable from 'Field/Subtable';

export default {
  name: 'FbMobileSubtable',
  components: { Subtable },
  props: ['field'],
};
</script>

<style scoped></style>
