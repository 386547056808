<template>
  <el-input-number
    ref="number"
    v-model.number="val"
    class="w-full min-w-[120px]"
    :debounce="1000"
    :disabled="!field.editable"
  ></el-input-number>
</template>

<script>
export default {
  name: 'FbNumber',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? Number(this.value) : 0;
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  mounted() {
    const input = this.$refs.number.$refs.input.$el.getElementsByTagName('input')[0];
    input.setAttribute('pattern', '\\d*');
  },
};
</script>

<style scoped></style>
