<template>
  <action-button
    :type="type"
    class="relative"
    :class="{ 'disabled:!opacity-100': loading }"
    :disabled="disabled || loading"
    @click="$emit('click')"
  >
    <div v-if="loading" class="absolute left-1/2 top-1/2 flex justify-center" style="transform: translate(-50%, -50%)">
      <progress-indicator-icon spin fill-color="#FFFFFF" :size="16" />
    </div>

    <div :class="{ invisible: loading }">
      <check-icon :size="16" class="relative top-0.5" />
      <span class="ml-2 inline-block leading-5">{{ label | trans }}</span>
    </div>
  </action-button>
</template>

<script>
import CheckIcon from 'vue-material-design-icons/Check';
import ProgressIndicatorIcon from 'Common/CustomIcon/ProgressIndicator';
import ActionButton from './ActionButton';

/**
 * Available events:
 *  - click
 */
export default {
  name: 'SaveButton',

  components: { CheckIcon, ProgressIndicatorIcon, ActionButton },

  props: {
    type: {
      type: String,
      default: 'submit',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Save',
    },
  },
};
</script>
