<template>
  <div class="rounded-lg bg-white p-4">
    <p class="mb-5">
      <i class="ui icon exclamation circle large text-[#E9B223]" />
      <span class="align-middle font-bold">
        {{ title }}
      </span>
    </p>
    <p>
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'KappauthPreviewSelectError',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
