<template>
  <div class="ui center aligned mt3 print-creator grid">
    <el-select v-if="form.ledgerSheets && form.ledgerSheets.length > 1" v-model="sheetId">
      <el-option v-for="sheet in form.ledgerSheets" :key="sheet.id" :value="sheet.id" :label="sheet.title"></el-option>
    </el-select>

    <form target="_blank" :action="actionUrl" method="post" rel="noopener noreferrer">
      <input type="hidden" name="record" :value="printCreatorData" />
      <input type="hidden" name="sheetId" :value="sheetId" />
      <input type="hidden" name="code" :value="form.publicCode" />
      <button type="submit" class="ui orange button fb-print">{{ 'Output' | trans }}</button>
    </form>
  </div>
</template>

<script>
import Router from 'filters/router';
import { getPathType } from 'utils/url-utils';
import u from '../../utils/utils';

export default {
  name: 'PrintCreator',
  props: {
    form: {
      type: Object,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    const sheets = this.form.ledgerSheets;
    return {
      sheetId: sheets && sheets.length > 0 ? sheets[0].id : null,
      printCreatorData: '',
    };
  },
  computed: {
    isPreview() {
      return getPathType(window.location.pathname) === 'private';
    },
    actionUrl() {
      if (!this.form.isUsePrintCreator) {
        return '';
      }

      if (this.isPreview) {
        return Router('app_preview_api_printcreator_output');
      }
      return Router('app_public_api_printcreator_output');
    },
  },
  mounted() {
    this.setPrintCreatorData();
  },
  methods: {
    setPrintCreatorData() {
      const replaceType = (val) => {
        if (u.has(val, 'type')) {
          switch (val.type) {
            case 'KVIEWER_LOOKUP':
              val.type = 'SINGLE_LINE_TEXT';
              break;
            case 'RATE':
            case 'SLIDER':
              val.type = 'NUMBER';
              break;
            case 'CASCADER':
              val.type = 'MULTI_SELECT';
              break;
            case 'SUBTABLE':
              val.value.forEach((tr) => {
                Object.keys(tr.value).forEach((key) => {
                  replaceType(tr.value[key]);
                });
              });
              break;
            default:
              break;
          }
        }
      };
      const record = JSON.parse(JSON.stringify(this.record));
      Object.keys(record).forEach((key) => {
        replaceType(record[key]);
      });
      // eslint-disable-next-line no-undef
      const username = this.form.isUseMailAuthentication ? formFormMailUser.email : formUser.name;
      this.printCreatorData = JSON.stringify({
        record,
        recordId: u.has(record, '__kintoneBridgeRecordId__1') ? record.__kintoneBridgeRecordId__1.value : 0,
        user: {
          language: locale,
          name: username || '',
        },
      });
    },
  },
};
</script>

<style scoped></style>
