import Routing from '@toyokumo/fos-router';
import webRoutes from '../../../shared/generated/routes/web.json';
import publicFormRoutes from '../../../shared/generated/routes/public-form.json';
Routing.setRoutingData({ ...webRoutes, routes: { ...webRoutes.routes, ...publicFormRoutes.routes } });
/**
 * ルート定義の host パラメータを空にした状態で, 与えられた処理を実行する
 */
function withEmptyingHosttokens(routeName, proc) {
    const route = Routing.getRoute(routeName);
    const reserved = route.hosttokens;
    route.hosttokens = [];
    const result = proc();
    route.hosttokens = reserved;
    return result;
}
/**
 * ルート名文字列に対応する URI を返す
 *
 * routeName に未登録のルート名が指定された場合, 例外を投げる
 */
function getUriFromRouteName(routeName, params, origin) {
    // ルート定義に host パラメータが指定されていた場合, Routing.generate は absolute パラメータの値に関係なく
    // 絶対 URL を出力する. ルート定義に関係なく, URL パスのみを出力するために, Routing.generate を呼び出す際に
    // 一時的に host パラメータの値を空にする.
    return withEmptyingHosttokens(routeName, () => {
        // サービス管理画面と公開フォームのドメインが異なり絶対URLのFQDN部分は引数で渡すため, 第3引数は常に false にして相対URIを取得する
        const uri = Routing.generate(routeName, params, false);
        return origin ? origin + uri : uri;
    });
}
export { getUriFromRouteName };
