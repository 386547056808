import { Button, Cascader, Checkbox, DatePicker, Dialog, Input, InputNumber, MessageBox, Option, Pagination, Radio, Rate, Select, Step, Steps, TimePicker, TimeSelect, Upload, } from 'element-ui';
import elementLocale from 'element-ui/lib/locale';
import elementLocaleEn from 'element-ui/lib/locale/lang/en';
import elementLocaleJa from 'element-ui/lib/locale/lang/ja';
import elementLocaleCn from 'element-ui/lib/locale/lang/zh-CN';
const useElementUI = (receivedVue) => {
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'locale'.
    switch (locale) {
        case 'ja':
            elementLocale.use(elementLocaleJa);
            break;
        case 'cn':
            elementLocale.use(elementLocaleCn);
            break;
        default:
            elementLocale.use(elementLocaleEn);
    }
    receivedVue.use(Dialog);
    receivedVue.use(Steps);
    receivedVue.use(Step);
    receivedVue.use(Select);
    receivedVue.use(Option);
    receivedVue.use(Button);
    receivedVue.use(TimeSelect);
    receivedVue.use(TimePicker);
    receivedVue.use(Input);
    receivedVue.use(Rate);
    receivedVue.use(Radio);
    receivedVue.use(InputNumber);
    receivedVue.use(Pagination);
    receivedVue.use(Upload);
    receivedVue.use(DatePicker);
    receivedVue.use(Checkbox);
    receivedVue.use(Cascader);
    receivedVue.prototype.$confirm = MessageBox.confirm;
};
export { useElementUI };
