import { mainAxiosClient } from 'api/axios';
import { getUriFromRouteName } from 'utils/routing-utils';
const modes = {
    // TODO: 他のパスのmodeもform.tsに記述していく
    PASSWORD_PROTECTION: 'passwordProtection',
};
function put(recipeId, form, type) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_edit', { recipeId, type }), form);
}
function fetchFields(recipeId) {
    return mainAxiosClient.get(getUriFromRouteName('api_form_fields', { recipeId }));
}
function fetchDeployedFields(recipeId) {
    return mainAxiosClient.get(getUriFromRouteName('api_deployed_form_fields', { recipeId }));
}
function putFields(recipeId, fields) {
    return mainAxiosClient.put(getUriFromRouteName('api_fields_edit', { recipeId }), { fields });
}
function putSpLayout(recipeId, fields) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_edit', { recipeId, type: 'mobileLayout' }), {
        fields,
    });
}
function putKviewer(recipeId, form) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_edit_kviewer', { recipeId }), form);
}
function addExternalResource(code, directive, host) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_add_external_resource'), {
        code,
        directive,
        host,
    });
}
function fetchFormUsers(recipeId) {
    return mainAxiosClient.get(getUriFromRouteName('api_form_users', { recipeId }));
}
export function putFormDesign(recipeId, form) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_put_form_design', { recipeId }), { form });
}
export function fetchCustomizeFileAttribute(recipeId) {
    return mainAxiosClient.get(getUriFromRouteName('api_js_css_customization_attributes_show', { recipeId }));
}
export function putEnabledJsCssCustomization(recipeId, enabled) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_put_enabled_js_css_customization', { recipeId }), { enabled });
}
export function putJsCssCustomization(recipeId, url) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_add_js_css_customization', { recipeId }), {
        url,
    });
}
export function putErrorJsCssCustomization(recipeId, url) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_add_error_js_css_customization', { recipeId }), { url });
}
export function deleteJsCssCustomization(recipeId, url, customizationType) {
    return mainAxiosClient.delete(getUriFromRouteName('api_form_delete_js_css_customization', { recipeId, url, customizationType }));
}
export function deleteErrorJsCssCustomization(recipeId, url, customizationType) {
    return mainAxiosClient.delete(getUriFromRouteName('api_form_delete_error_js_css_customization', { recipeId, url, customizationType }));
}
export function putUpdatedAtByMemberOperation(recipeId) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_put_updated_at_by_member_operation', { recipeId }));
}
export function putResourceRestrictionEnable(recipeId, enabled) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_put_enabled_resource_restriction', { recipeId }), { enabled });
}
export function putExternalResources(recipeId, externalResources, allowedOrigins) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_put_external_resources', { recipeId }), {
        externalResources,
        allowedOrigins,
    });
}
export function putUiVersion(recipeId, uiVersion) {
    return mainAxiosClient.put(getUriFromRouteName('api_form_put_ui_version', { recipeId }), {
        uiVersion,
    });
}
// TODO: 以下は削除し named-export のみにする (互換性のためにのみ定義を残している)
export default {
    put,
    fetchFields,
    fetchDeployedFields,
    putFields,
    putSpLayout,
    putKviewer,
    addExternalResource,
};
export { put, fetchFields, fetchDeployedFields, putFields, putSpLayout, putKviewer, addExternalResource, fetchFormUsers, modes, };
