<template>
  <div class="row scroll-x">
    <table :class="['ui table', { 'collapsing celled unstackable': !(isMobile && field.isMobileStyle) }]">
      <thead>
        <tr>
          <th
            v-for="child in fieldsShowingChild"
            :key="child.code"
            :class="['field', { required: child.required }]"
            :style="isMobile ? child.spStyle : child.style"
          >
            <label v-if="child.label && !child.noLabel">{{ label(child.label) }}</label>
            <p v-if="child.help && child.help.length > 0" class="help-content">
              {{ child.help | trans }}
            </p>
          </th>
          <th style="width: 50px"></th>
        </tr>
      </thead>
      <tbody>
        <table-row
          v-for="(tableRecord, relativeIndex) in currentValue"
          :key="toTableIndex(relativeIndex)"
          :field="field"
          :table-record="tableRecord"
          :table-index="toTableIndex(relativeIndex)"
          :is-addable="isAddable(toTableIndex(relativeIndex))"
          :is-removable="isRemovable()"
          @add="add"
          @remove="remove(toTableIndex(relativeIndex))"
        ></table-row>
        <tr v-if="value.length === 0">
          <td>
            <button class="ui circular blue icon button" type="button" @click="add">
              <i class="plus icon"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <table-pagination
      :current-page="currentPage"
      :page-count="pageCount"
      :change-page="changePage"
      :field-code="field.code"
    />
  </div>
</template>

<script>
import u from 'utils/utils';
import fu from 'utils/field-utils';
import TableRow from 'Public/Field/TableRow';
import TablePagination from 'Public/Field/TablePagination';
import { Validator } from 'vee-validate';
import validator from 'js/validator';

export default {
  name: 'FbSubtable',
  components: { TableRow, TablePagination },
  inject: ['$validator'],
  props: ['field'],
  data() {
    return {
      value: this.$store.state.record[this.field.code].value,
      isMobile: u.isMobile(),
      currentPage: 1,
      unsubscribe: null,
      maxPageSize: 20,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.value.length / this.maxPageSize);
    },
    currentOffset() {
      return (this.currentPage - 1) * this.maxPageSize;
    },
    currentPageSize() {
      const restSize = this.value.length - this.currentOffset;
      return Math.min(this.maxPageSize, restSize);
    },
    currentValue() {
      const begin = this.currentOffset;
      const end = this.currentOffset + this.currentPageSize;
      return this.value.slice(begin, end);
    },
    fieldsShowingChild() {
      return this.field.fields.filter(this.isShowChild);
    },
  },
  created() {
    validator.addPagerValidation(`table-pager-${this.field.code}`, this.maxPageSize, '', async (record) => {
      const valids = await Promise.all(
        this.field.fields.map((child) => {
          const val = record.value[child.code].value;
          const validations = {};
          child.validations.forEach((v) => {
            if (v.rule === 'url' && !u.has(v.params, 'require_protocol')) {
              v.params = { require_protocol: true };
            }
            validations[v.rule] = v.params ? v.params : true;
          });
          return new Validator().verify(val, validations);
        }),
      );
      return valids.every(({ valid }) => valid);
    });

    this.$validator.attach({
      name: `table-pager-${this.field.code}`,
      rules: `table-pager-${this.field.code}`,
      getter: () => this.value,
    });

    this.unsubscribe = this.$store.subscribeAction((action) => {
      if (action.type === 'updateTableField') {
        this.$nextTick(() => {
          this.$validator.validateAll();
        });
      }
    });
  },
  destroyed() {
    this.unsubscribe();
    this.$validator.detach(`table-pager-${this.field.code}`);
  },
  methods: {
    label(label) {
      if (this.$store.state.form.isTranslate) {
        return u.trans(label);
      }
      return label;
    },
    toTableIndex(relativeIndex) {
      return this.currentOffset + relativeIndex;
    },
    add() {
      this.$store.dispatch('addTableRecord', {
        code: this.field.code,
        type: this.field.type,
        fields: this.field.fields,
        vm: this,
      });
      this.changePage(this.pageCount);
    },
    remove(tableIndex) {
      this.$store.dispatch('removeTableRecord', {
        code: this.field.code,
        type: this.field.type,
        index: tableIndex,
        vm: this,
      });
      const removedCurrentPage = this.currentPage >= this.pageCount;
      if (removedCurrentPage) {
        this.changePage(this.pageCount);
      }
    },
    isAddable(tableIndex) {
      return (
        this.value.length === tableIndex + 1 &&
        (this.field.maxLength === null || this.value.length < this.field.maxLength)
      );
    },
    isRemovable() {
      return this.value.length > this.field.minLength || this.field.minLength === null;
    },
    isShowChild(child) {
      return fu.isShowTableField(child);
    },
    changePage(page) {
      this.currentPage = page;
      this.$nextTick(() => {
        this.$validator.validateAll();
      });
    },
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 1em;
}
th {
  white-space: nowrap;
}
.help-content {
  color: #48576a;
  font-size: 0.9rem;
}
.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
  /*-webkit-overflow-scrolling: touch;*/
}
.scroll-x::-webkit-scrollbar {
  height: 5px;
}
.scroll-x::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #eee;
}
.scroll-x::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888;
}
</style>
