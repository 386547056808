<template>
  <transition enter-active-class="animated fadeIn">
    <div
      v-show="showField"
      :class="[
        'column el-form-item',
        {
          field: field.code && field.type !== 'LABEL',
          required: field.required,
          error: errors.has(field.code),
          'subtable-field': field.type === 'SUBTABLE',
        },
      ]"
      :style="styles"
    >
      <label v-if="field.label && !field.noLabel && field.type === 'LABEL'" class="ql-editor" v-html="label"></label>
      <label v-else-if="field.label && !field.noLabel">{{ label }}</label>
      <transition name="fade">
        <component
          :is="type"
          v-if="field.code && field.type !== 'LABEL'"
          v-model="value"
          v-validate="validations"
          :field="field"
          :value="value"
          :width="width"
          :data-vv-name="field.code"
          @update="update"
        ></component>
      </transition>
      <transition name="fade">
        <div v-if="field.code" v-show="errors.has(field.code)" class="el-form-item__error">
          {{ errors.first(field.code) }}
        </div>
      </transition>
      <p v-if="field.help && field.help.length > 0" class="help-content">
        {{ field.help | trans }}
      </p>
    </div>
  </transition>
</template>

<script>
import 'animate.css/source/_base.css';
import 'animate.css/source/fading_entrances/fadeIn.css';
import FbSingleLineText from 'Field/SingleLineText';
import FbNumber from 'Field/Number';
import FbCalc from 'Field/Calc';
import FbMultiLineText from 'Field/MultiLineText';
import FbRichText from 'Field/RichText';
import FbCheckBox from 'Field/CheckBox';
import FbRadioButton from 'Field/RadioButton';
import FbDropDown from 'Field/DropDown';
import FbMultiSelect from 'Field/MultiSelect';
import FbKviewerLookup from 'Field/KviewerLookup';
import FbTime from 'Field/Time';
import FbTimeSelect from 'Field/TimeSelect';
import FbDate from 'Field/Date';
import FbDatetime from 'Field/Datetime';
import FbFile from 'Field/File';
import FbSubtable from 'Field/Subtable';
import FbHr from 'Field/Hr';
import FbRate from 'Field/Rate';
import FbSlider from 'Field/Slider';
import FbCascader from 'Field/Cascader';
import FbMobileTime from 'MobileField/Time';
import FbMobileDate from 'MobileField/Date';
import FbMobileDatetime from 'MobileField/Datetime';
import FbMobileCheckBox from 'MobileField/CheckBox';
import FbMobileRadioButton from 'MobileField/RadioButton';
import FbMobileDropDown from 'MobileField/DropDown';
import FbMobileMultiSelect from 'MobileField/MultiSelect';
import FbMobileSubtable from 'MobileField/Subtable';
import { calc, detectNumberFieldWithRuntimeProps } from 'utils/calc-utils';
import { userTrans } from 'filters/trans';
import fu from 'utils/field-utils';
import u from 'utils/utils';
import c from '../../const';

export default {
  name: 'PublicField',
  components: {
    FbSingleLineText,
    FbNumber,
    FbCalc,
    FbMultiLineText,
    FbRichText,
    FbCheckBox,
    FbRadioButton,
    FbDropDown,
    FbMultiSelect,
    FbKviewerLookup,
    FbTime,
    FbTimeSelect,
    FbDate,
    FbDatetime,
    FbFile,
    FbSubtable,
    FbHr,
    FbRate,
    FbSlider,
    FbCascader,
    FbMobileTime,
    FbMobileDate,
    FbMobileDatetime,
    FbMobileCheckBox,
    FbMobileRadioButton,
    FbMobileDropDown,
    FbMobileMultiSelect,
    FbMobileSubtable,
  },
  inject: ['$validator'],
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        if (!u.has(this.$store.state.record, this.field.code)) {
          return '';
        }
        return this.$store.state.record[this.field.code].value;
      },
      set(value) {
        this.update(value);
      },
    },
    styles() {
      return u.isMobile() ? this.field.spStyle : this.field.style;
    },
    form() {
      return this.$store.state.form;
    },
    label() {
      if (this.form.isTranslate) {
        return userTrans(this.field.label);
      }
      return this.field.label;
    },
    showField() {
      return fu.isShowField(this.field);
    },
    type() {
      return `fb-${this.isMobile ? 'mobile-' : ''}${this.field.type.toLowerCase().replace(/_/g, '-')}`;
    },
    validations() {
      const validations = {};
      this.field.validations.forEach((v) => {
        // TODO: Remove
        if (v.rule === 'url' && !u.has(v.params, 'require_protocol')) {
          v.params = { require_protocol: true };
        }
        validations[v.rule] =
          (Array.isArray(v.params) && v.params.length > 0) || (!Array.isArray(v.params) && v.params != null)
            ? v.params
            : true;
      });
      return {
        rules: validations,
      };
    },
    isMobile() {
      return this.field.isMobileStyle && c.mobileFieldsType.indexOf(this.field.type) >= 0 && u.isMobile();
    },
    width() {
      return u.isMobile() ? '100%' : `calc(${this.field.style.width} - 2rem)`;
    },
  },
  created() {
    if (u.has(fb.events.fields, this.field.code) && u.has(fb.events.fields[this.field.code], 'created')) {
      this.$store.commit('callEvents', { events: fb.events.fields[this.field.code].created });
    }
  },
  mounted() {
    if (u.has(fb.events.fields, this.field.code) && u.has(fb.events.fields[this.field.code], 'mounted')) {
      this.$store.commit('callEvents', { events: fb.events.fields[this.field.code].mounted });
    }
    if (detectNumberFieldWithRuntimeProps(this.field) || this.field.type === 'SUBTABLE') {
      calc(this.field, this.$store.state.fields, this.$store.state.record);
    }
    if (c.branchConditionFieldType.indexOf(this.field.type) >= 0) {
      this.$store.commit('updateBranch');
    }
  },
  methods: {
    update(value) {
      if (!this.$el) {
        return;
      }
      this.$store.dispatch('updateField', {
        code: this.field.code,
        type: this.field.type,
        value,
        vm: this,
        validations: this.field.validations,
      });
      this.$validator.validate(this.field.code, this.value);
    },
  },
};
</script>

<style scoped>
.ui.grid > .row > .column {
  margin-bottom: 0.5em;
}
.el-form-item__error {
  position: static;
}
.column.field.el-form-item.subtable-field {
  width: 100%;
}
.help-content {
  color: #48576a;
  font-size: 0.9rem;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
