<template>
  <a
    tabindex="0"
    :class="['ui teal labeled icon button fb-confirm', { disabled }]"
    @click="confirm"
    @keydown.enter="confirm"
  >
    <i class="check icon"></i>
    {{ 'Confirm' | trans }}
  </a>
</template>

<script>
export default {
  name: 'ConfirmButton',
  props: {
    disabled: { type: Boolean, default: false },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style scoped></style>
