<template>
  <div class="kapp-auth-userpage-logout" :class="{ isHideCopyright }">
    <p>
      {{ 'Access is restricted by the provider.' | trans }}
      <a class="logout" :href="logoutUrl">
        {{ 'Click here to log out' | trans }}
      </a>
    </p>

    <p>
      <a class="to-user-page" :href="userPageUrl">
        {{ 'Find other pages from the list' | trans }}
        <i class="ui chevron circle right icon"></i>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LogoutButton',
  props: {
    isHideCopyright: { type: Boolean, default: false },
  },
  computed: {
    userPageUrl() {
      return `${ACCOUNT_KINTONEAPP_URL}/`;
    },
    logoutUrl() {
      return `${ACCOUNT_KINTONEAPP_URL}/logout`;
    },
  },
};
</script>

<style lang="scss" scoped>
.kapp-auth-userpage-logout {
  max-width: 640px;

  /* footerが14pxのmaring-topをもつため, footer表示時はmargin合計が40pxとなるようにmargin-bottomを設定*/
  margin: 40px auto calc(40px - 1rem);

  padding: 16px;
  border-radius: 4px;
  background-color: #fff;

  text-align: center;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: #999;

  &.isHideCopyright {
    margin-bottom: 40px;
  }

  p {
    margin: 16px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: #0079d2;
    cursor: pointer;

    &.to-user-page {
      line-height: 20px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
</style>
