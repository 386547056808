<template>
  <el-time-select v-model="val" :picker-options="pickerOptions" :disabled="!field.editable"></el-time-select>
</template>

<script>
export default {
  name: 'FbTimeSelect',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update', value);
      },
    },
    pickerOptions() {
      if (this.field.pickerOptions) {
        return this.field.pickerOptions;
      }
      return {
        start: '00:00',
        end: '23:30',
        step: '00:30',
      };
    },
  },
  mounted() {
    this.$el.getElementsByTagName('input')[0].setAttribute('readonly', 'readonly');
  },
};
</script>

<style scoped></style>
