<template>
  <a tabindex="0" :class="['ui blue labeled icon button fb-next', { disabled }]" @click="next" @keydown.enter="next">
    <i class="arrow right icon"></i>
    {{ 'Next' | trans }}
  </a>
</template>

<script>
export default {
  name: 'NextButton',
  props: {
    disabled: { type: Boolean, default: false },
  },
  methods: {
    next() {
      this.$emit('next');
    },
  },
};
</script>

<style scoped></style>
