import { render, staticRenderFns } from "./RadioButton.vue?vue&type=template&id=056bb9ff&scoped=true"
import script from "./RadioButton.vue?vue&type=script&lang=js"
export * from "./RadioButton.vue?vue&type=script&lang=js"
import style0 from "./RadioButton.vue?vue&type=style&index=0&id=056bb9ff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.26.10__css-loader@7.1.2_webp_cfab7bb86b407303d16e493b6ab0642c/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056bb9ff",
  null
  
)

export default component.exports