import { getUriFromRouteName } from './routing-utils';
function renameTo(current, to) {
    if (current.indexOf('public_form') === 0) {
        if (to === '/' || to === '') {
            return 'public_form';
        }
        return `public_form_${to}`;
    }
    if (current.indexOf('private_form') === 0) {
        if (to === '/' || to === '') {
            return 'private_form';
        }
        return `private_form_${to}`;
    }
    return to;
}
/**
 * 公開フォームの URL 生成 (スキームから始まる絶対 URL)
 * 全角文字を含む場合、パーセントエンコーディングせずに表示する。
 */
function buildPublicFormUrl(params) {
    const encodedURL = getUriFromRouteName('app_public_recipe_show', params, formOrigin);
    return decodeURI(encodedURL);
}
function getPathType(path) {
    if (path.indexOf('/public') === 0) {
        return 'public';
    }
    if (path.indexOf('/private') === 0) {
        return 'private';
    }
    if (path.indexOf('/') === 0) {
        return 'edit';
    }
    return null;
}
/**
 * VueRouterのハッシュモードのURLをヒストリーモードのURLに変換する関数
 */
export function pathWithoutVueRouterHash(to) {
    const { hash } = to;
    if (!hash.startsWith('#/'))
        return { path: to.path, hash: to.hash, query: to.query };
    const { path, query } = to;
    // 値の形式
    // path: /hoge/fuga ※rootパス (/)のときだけ末尾はスラッシュになる
    // hash: #/foo/bar
    const filteredPath = `${path.replace(/\/$/, '')}${hash.replace(/^#\//, '/')}`;
    return {
        path: filteredPath,
        hash: '',
        query,
    };
}
export function removeQueryParams(url, keys) {
    const urlObj = new URL(url);
    keys.forEach((key) => urlObj.searchParams.delete(key));
    return urlObj.toString();
}
/** @deprecated named exports に置き換える */
export default { renameTo };
export { renameTo, buildPublicFormUrl, getPathType };
