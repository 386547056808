<template>
  <div>
    <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--datetime" @click="open">
      <input
        type="text"
        :value="value"
        autocomplete="off"
        :name="field.code"
        class="el-input__inner"
        disabled="disabled"
      />
      <span class="el-input__prefix"><i class="el-input__icon el-icon-time"></i></span>
      <span class="el-input__suffix"
        ><span class="el-input__suffix-inner"><i class="el-input__icon"></i></span
      ></span>
    </div>
    <mt-datetime-picker
      ref="picker"
      v-model="val"
      type="time"
      :cancel-text="'Cancel' | trans"
      :confirm-text="'OK' | trans"
      :hour-format="'{value} hour' | trans"
      :minute-format="'{value} minute' | trans"
    ></mt-datetime-picker>
  </div>
</template>

<script>
export default {
  name: 'FbMobileTime',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
  methods: {
    open() {
      this.$refs.picker.open();
    },
  },
};
</script>

<style scoped></style>
