/**
 * WebStorage が利用可能か
 *
 * ブラウザの設定で Cookie が不許可であるなど様々な要因で利用できない可能性があるため, 利用可能かを検査する.
 * see: https://gist.github.com/paulirish/5558557
 */
function webStorageAvailable(type) {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return (e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            typeof storage !== 'undefined' &&
            storage !== null &&
            storage.length !== 0);
    }
}
const Package = {
    localStorage: {
        get: (payload) => new Promise((resolve, reject) => {
            try {
                const value = localStorage.getItem(payload.key);
                if (value) {
                    resolve(JSON.parse(value));
                }
            }
            catch {
                const error = new Error('Cannot get value.');
                reject(error);
            }
        }),
        set: (payload) => new Promise((resolve, reject) => {
            try {
                localStorage.setItem(payload.key, JSON.stringify(payload.value));
                resolve();
            }
            catch {
                const error = new Error('Cannot save value.');
                reject(error);
            }
        }),
        remove: (payload) => new Promise((resolve, reject) => {
            try {
                localStorage.removeItem(payload.key);
                resolve();
            }
            catch {
                const error = new Error('Cannot use localStorage.');
                reject(error);
            }
        }),
        canUse: () => {
            if (localStorage) {
                return 'localStorage';
            }
            return false;
        },
    },
    IndexedDB: {
        open: (mode) => new Promise((resolve, reject) => {
            const request = indexedDB.open('form-bridge');
            request.onerror = (_) => {
                const SupportError = new Error('Cannot use IndexedDB.');
                reject(SupportError);
            };
            request.onupgradeneeded = (event) => {
                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                const db = event.target.result;
                db.createObjectStore('temporary-save', { keyPath: 'key' });
            };
            request.onsuccess = (event) => {
                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                const db = event.target.result;
                const transaction = db.transaction('temporary-save', mode);
                const objectStore = transaction.objectStore('temporary-save');
                resolve(objectStore);
            };
        }),
        get: (payload) => new Promise((resolve, reject) => {
            Package.IndexedDB.open('readonly')
                .then((objectStore) => {
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                const request = objectStore.get(payload.key);
                request.onsuccess = (event) => {
                    const value = event.target.result;
                    if (value) {
                        resolve(value.value);
                    }
                    else {
                        const err = new Error('Cannot get value.');
                        reject(err);
                    }
                };
                request.onerror = (_) => {
                    const SupportError = new Error('Cannot use IndexedDB.');
                    reject(SupportError);
                };
            })
                .catch((error) => {
                reject(error);
            });
        }),
        set: (payload) => new Promise((resolve, reject) => {
            Package.IndexedDB.open('readwrite')
                .then((objectStore) => {
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                const request = objectStore.put(payload);
                request.onerror = (event) => {
                    if (event.target.error.name === 'QuotaExceededError') {
                        const StorageError = new Error('Storage maximum size is reached.');
                        reject(StorageError);
                    }
                    else if (event.target.error.message.indexOf('The serialized value is too large') !== -1) {
                        const SerializedError = new Error('Forms are too many to save.');
                        reject(SerializedError);
                    }
                    else {
                        const SupportError = new Error('Cannot use IndexedDB.');
                        reject(SupportError);
                    }
                };
                request.onsuccess = (_) => {
                    resolve();
                };
            })
                .catch((error) => {
                reject(error);
            });
        }),
        remove: (payload) => new Promise((resolve, reject) => {
            Package.IndexedDB.open('readwrite')
                .then((objectStore) => {
                // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                const request = objectStore.delete(payload.key);
                request.onsuccess = (_) => {
                    resolve();
                };
                request.onerror = (_) => {
                    const SupportError = new Error('Cannot use IndexedDB.');
                    reject(SupportError);
                };
            })
                .catch((error) => {
                reject(error);
            });
        }),
        canUse: () => {
            if (indexedDB) {
                return 'IndexedDB';
            }
            return false;
        },
    },
};
const u = {
    get: (payload, type) => new Promise((resolve, reject) => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        Package[type]
            .get(payload)
            .then((value) => {
            resolve(value);
        })
            .catch((error) => {
            reject(error);
        });
    }),
    set: (payload, type) => new Promise((resolve, reject) => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        Package[type]
            .set(payload)
            .then(() => {
            resolve();
        })
            .catch((error) => {
            reject(error);
        });
    }),
    remove: (payload, type) => new Promise((resolve, reject) => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        Package[type]
            .remove(payload)
            .then(() => {
            resolve();
        })
            .catch((error) => {
            reject(error);
        });
    }),
    canUse: (type) => 
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    Package[type].canUse(),
};
export default u;
export { webStorageAvailable };
