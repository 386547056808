<template>
  <div class="ui stackable grid">
    <field-row v-for="(row, index) in rows" :key="index" :row="row"></field-row>
    <confirm-button v-if="form.isUseConfirm" :disabled="fileUploading" @confirm="confirm"></confirm-button>
    <post-button v-else :disabled="posted || fileUploading" @post="submit"></post-button>
    <temporary-save :disabled="fileUploading"></temporary-save>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import u from '../../utils/utils';
import fu from '../../utils/field-utils';
import FieldRow from './FieldRow';
import TemporarySave from './TemporarySave';
import PostButton from './Button/PostButton';
import ConfirmButton from './Button/ConfirmButton';

export default {
  $_veeValidate: {
    validator: 'new',
  },
  name: 'NormalFields',
  components: { FieldRow, TemporarySave, PostButton, ConfirmButton },
  data() {
    return {
      form: {},
      rows: [],
    };
  },
  computed: {
    ...mapState(['fileUploading']),
    ...mapGetters(['posted']),
  },
  created() {
    this.form = this.$store.state.form;
    this.rows = this.$store.state.fieldRows;
  },
  methods: {
    scroll() {
      const vm = fu.findFieldOrTableFieldVmByCodes(
        this.errors.items.map((i) => i.field),
        this.$children,
      );

      if (vm) {
        u.scroll(vm);
      }
    },
    async confirm() {
      const valid = await this.$validator.validateAll().catch(() => false);
      if (valid) {
        this.$store.commit('callEvents', { events: fb.events.form.confirm });
        this.$emit('confirm');
      } else {
        this.scroll();
      }
    },
    async submit() {
      this.$store.commit('callEvents', { events: fb.events.form.submit });
      const valid = await this.$validator.validateAll().catch(() => false);
      if (valid) {
        this.$emit('submit');
      } else {
        this.scroll();
      }
    },
  },
};
</script>

<style scoped>
.ui.grid {
  margin: 1em 0em;
}
</style>
