import Vue from 'vue';
import { Parser } from 'expr-eval';
import util from './utils';
const exprParser = new Parser();
exprParser.consts = {};
/**
 * 計算に利用できるフィールドか否かを判定
 */
export function isNumberField(field) {
    if (['SINGLE_LINE_TEXT', 'RADIO_BUTTON', 'DROP_DOWN'].includes(field.type)) {
        // XXX: field.configuredValidations は main:d2b1a1 時点で存在しなさそう.
        const validations = field.configuredValidations || field.validations || [];
        return validations.some((validator) => validator.rule === 'numeric');
    }
    return ['NUMBER', 'CALC', 'SLIDER', 'RATE'].includes(field.type);
}
/**
 * 計算に利用できるフィールドか否かを判定
 *
 * 判定キャッシュがある場合はそれを利用する.
 */
export function detectNumberFieldWithRuntimeProps(field) {
    // field.runtimeProps.isNumberField:
    //   フォーム初期化時の設定で判定された計算で利用できるフィールドか否かのフラグのキャッシュ.
    //   https://github.com/toyokumo/form-bridge/blob/d2b1a1/frontend/assets/js/utils/field-utils.ts#L598-L611
    if (util.has(field, 'runtimeProps')) {
        return field.runtimeProps.isNumberField;
    }
    return isNumberField(field);
}
function collectValuesInTable(tableCode, code, record) {
    const values = [];
    const tableValue = record[tableCode].value;
    tableValue.forEach((table) => {
        values.push(table.value[code].value);
    });
    return values;
}
function sum(tableCode, _code, record, values) {
    if (record[tableCode].value.length === 0) {
        return 0;
    }
    return values.reduce((a, b) => a + b);
}
function avg(tableCode, _code, record, values) {
    if (record[tableCode].value.length === 0) {
        return 0;
    }
    return values.reduce((a, b) => a + b) / values.length;
}
/**
 * テーブルフィールドに対する特殊な演算子に対応するため expr を書き換える
 *
 * 演算子:
 * - count(tableCode): テーブルフィールド tableCode のレコード数
 * - sum(tableCode.code): テーブルフィールド tableCode 内の code フィールドの合計
 * - avg(tableCode.code): テーブルフィールド tableCode 内の code フィールドの平均
 * - max(tableCode.code): テーブルフィールド tableCode 内の code フィールドの最大値
 * - min(tableCode.code): テーブルフィールド tableCode 内の code フィールドの最小値
 */
function tableExpressions(expr, tableFields, record) {
    let exp = expr;
    tableFields.forEach((table) => {
        exp = exp.replace(new RegExp(`count\\(${table.code}\\)`, 'g'), record[table.code].value.length);
        table.fields.forEach((f) => {
            if (detectNumberFieldWithRuntimeProps(f)) {
                const values = collectValuesInTable(table.code, f.code, record).map((val) => Number(val));
                exp = exp.replace(new RegExp(`sum\\(${table.code}\\.${f.code}\\)`, 'g'), sum(table.code, f.code, record, values));
                exp = exp.replace(new RegExp(`avg\\(${table.code}\\.${f.code}\\)`, 'g'), avg(table.code, f.code, record, values));
                exp = exp.replace(new RegExp(`max\\(${table.code}\\.${f.code}\\)`, 'g'), Math.max.apply(null, values));
                exp = exp.replace(new RegExp(`min\\(${table.code}\\.${f.code}\\)`, 'g'), Math.min.apply(null, values));
            }
        });
    });
    return exp;
}
/**
 * テーブル内フィールドの計算フィールドの値を計算/更新する
 *
 * 呼び出されると tableFields に指定されたすべてのテーブルフィールドについてその内部の計算フィールドの値を計算/更新する.
 * テーブル内フィールドの計算フィールドの計算は同じ行内のフィールドのみを対象とする.
 */
function calcTable(tableFields, record, loop = 1) {
    tableFields.forEach((field) => {
        // XXX: record に値が存在しないケース... 考慮しなくて良さそう.
        if (!util.has(record, field.code)) {
            return;
        }
        record[field.code].value.forEach((table) => {
            field.fields.forEach((child) => {
                // eslint-disable-next-line no-use-before-define
                calc(child, field.fields, table.value, loop);
            });
        });
    });
}
/**
 * 数値計算フィールドの更新関数
 *
 * 値の更新されたフィールドが計算フィールドの式に指定されていたらその式を再計算して計算フィールドの値を更新する.
 * すべてのフィールドの更新時にこの関数を呼び出す想定.
 */
export function calc(updateField, fields, record, loop = 1) {
    // 計算フィールドの値を更新したときこの関数を再帰的に呼び出す(他の計算フィールドの式で参照されている可能性).
    // loop/nextLoop はその再帰呼び出しの深さのカウントであり, ここでインクリメント & 打ち切り処理を行っている.
    const nextLoop = loop + 1;
    if (loop > 100) {
        return;
    }
    // 値が更新されたフィールドが数値系かテーブルではないときは何もしない
    if (updateField.type !== 'SUBTABLE' && !detectNumberFieldWithRuntimeProps(updateField)) {
        return;
    }
    const calcFields = fields.filter((f) => f.type === 'CALC');
    const numberFields = fields.filter(detectNumberFieldWithRuntimeProps);
    const tableFields = fields.filter((f) => f.type === 'SUBTABLE');
    if (updateField.type === 'SUBTABLE') {
        // 更新対象がテーブルフィールドの場合すべてのテーブル内の計算フィールドの値を更新する
        // (XXX: updatedField 以外のテーブルフィールドについて計算は不要に思えるがこのようにしている理由は不明)
        calcTable(tableFields, record, nextLoop);
    }
    calcFields.forEach((calcField) => {
        let expr = calcField.expression;
        // 数値計算式に更新されたフィールドの値が含まれていなければ何もしない
        if (!expr || expr.indexOf(updateField.code) === -1) {
            return;
        }
        // 式に出現するフィールドの値を収集
        const scope = {};
        numberFields.forEach((field) => {
            scope[field.code] = Number(record[field.code].value);
        });
        if (tableFields.length > 0) {
            expr = tableExpressions(expr, tableFields, record);
        }
        const { value } = record[calcField.code];
        try {
            Vue.set(record[calcField.code], 'value', exprParser.evaluate(expr, scope));
        }
        catch {
            // 計算に失敗したら強制的に0をセット！
            Vue.set(record[calcField.code], 'value', 0);
        }
        // 値が変更されていたら再度計算処理を実施(他の計算フィールドの式で参照されている可能性)
        if (value !== record[calcField.code].value) {
            calc(calcField, fields, record, nextLoop);
        }
    });
}
export default {
    isNumberField,
    calc,
    collectValuesInTable,
    sum,
    avg,
    calcTable,
    tableExpressions,
};
