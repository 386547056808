<template>
  <el-rate
    v-model="val"
    :max="field.maxValue"
    :show-text="true"
    :texts="field.options"
    :disabled="!field.editable"
  ></el-rate>
</template>

<script>
export default {
  name: 'FbRate',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? Number(this.value) : parseInt(this.field.maxValue / 2, 10);
      },
      set(value) {
        this.$emit('update', value);
      },
    },
  },
};
</script>

<style scoped></style>
