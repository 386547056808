<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <el-dialog
    :visible.sync="visible"
    class="!z-[3050] bg-[rgba(255,255,255,0.85)]"
    top="32px"
    width="640px"
    custom-class="max-h-[calc(100vh-4rem)] flex flex-col mb-0 tracking-normal"
    :modal="false"
    :append-to-body="false"
    @closed="handleClosed"
  >
    <!-- eslint-enable -->
    <template #title>
      <h3 class="m-0 text-[20px]">{{ 'Do you want to allow resources to be loaded?' | trans }}</h3>
    </template>

    <div class="text-[14px] text-black">
      {{
        'Reading of "%blockedOrigin%" is restricted. If you allow it, it will be added to "Allowed resources" in "Resource loading restrictions".'
          | trans({ blockedOrigin })
      }}
    </div>

    <template #footer>
      <save-button class="!text-[14px]" label="Permit" :loading="isSaving" @click="handleClickSave" />
    </template>
  </el-dialog>
</template>

<script>
import api from 'api/form';
import u from 'utils/utils';
import SaveButton from 'Common/SaveButton';

export default {
  name: 'AddExternalResourceDialog',

  components: {
    SaveButton,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    formCode: {
      type: String,
      required: true,
    },

    blockedUri: {
      type: String,
      required: true,
    },

    violatedDirective: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
    };
  },

  computed: {
    blockedOrigin() {
      try {
        return new URL(this.blockedUri).origin;
      } catch {
        return '';
      }
    },
  },

  methods: {
    async handleClickSave() {
      this.isSaving = true;
      try {
        await api.addExternalResource(this.formCode, this.violatedDirective, this.blockedOrigin);
      } catch (e) {
        u.error(this, e);
        return;
      } finally {
        this.isSaving = false;
        // eslint-disable-next-line vue/no-mutating-props
        this.visible = false;
      }

      u.success(this, 'Added "%blockedOrigin%" to allowed resources.', { blockedOrigin: this.blockedOrigin });
    },

    handleClosed() {
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__header {
    padding: 32px 32px 16px;
  }

  .el-dialog__headerbtn {
    top: 32px;
    right: 32px;
    .el-dialog__close {
      color: black;
    }
  }

  .el-dialog__body {
    padding: 0 32px;
  }

  .el-dialog__footer {
    padding: 32px;
  }
}
</style>
