import utils from 'utils/utils';
import { ucfirst } from 'utils/string-utils';
const PLANS = {
    NONE: 'none',
    LIGHT: 'light',
    STANDARD: 'standard',
    PREMIUM: 'premium',
    PROFESSIONAL: 'professional',
    ENTERPRISE: 'enterprise',
    TRIAL: 'trial',
};
const PLAN_SCORES = {
    [PLANS.NONE]: 0,
    [PLANS.LIGHT]: 10,
    [PLANS.STANDARD]: 20,
    [PLANS.PREMIUM]: 30,
    [PLANS.PROFESSIONAL]: 40,
    [PLANS.ENTERPRISE]: 40,
    [PLANS.TRIAL]: 40,
};
function translate(str) {
    return utils.trans(ucfirst(str));
}
const PLAN_TRANSLATES = {
    [PLANS.NONE]: '',
    [PLANS.LIGHT]: translate(PLANS.LIGHT),
    [PLANS.STANDARD]: translate(PLANS.STANDARD),
    [PLANS.PREMIUM]: translate(PLANS.PREMIUM),
    [PLANS.PROFESSIONAL]: translate(PLANS.PROFESSIONAL),
    [PLANS.ENTERPRISE]: translate(PLANS.ENTERPRISE),
    [PLANS.TRIAL]: translate(PLANS.TRIAL),
};
export { PLANS, PLAN_SCORES, PLAN_TRANSLATES };
