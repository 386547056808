<template>
  <div>
    <div class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--datetime" @click="open">
      <input
        type="text"
        :value="val | format"
        autocomplete="off"
        :name="field.code"
        class="el-input__inner"
        disabled="disabled"
      />
      <span class="el-input__prefix"><i class="el-input__icon el-icon-time"></i></span>
      <span class="el-input__suffix"
        ><span class="el-input__suffix-inner"><i class="el-input__icon"></i></span
      ></span>
    </div>
    <mt-datetime-picker
      ref="picker"
      v-model="val"
      type="datetime"
      :cancel-text="'Cancel' | trans"
      :confirm-text="'OK' | trans"
      :year-format="'{value} year' | trans"
      :month-format="'{value} month' | trans"
      :date-format="'{value} day' | trans"
      :hour-format="'{value} hour' | trans"
      :minute-format="'{value} minute' | trans"
      :start-date="between.min"
      :end-date="between.max"
      class="mobile-datetime-content"
    ></mt-datetime-picker>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'FbMobileDatetime',
  filters: {
    format(value) {
      if (!value || value === '') {
        return '';
      }
      return dayjs(value).format('YYYY-MM-DD HH:mm');
    },
  },
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return dayjs(this.value).isValid() ? dayjs(this.value).toDate() : '';
      },
      set(value) {
        this.$emit('update', value);
      },
    },
    betweenValidation() {
      return this.field.validations.find((v) => v.rule === 'datetime_between');
    },
    between() {
      if (this.betweenValidation && this.betweenValidation.params.length >= 2) {
        const min = dayjs(this.betweenValidation.params[0]).toDate();
        const max = dayjs(this.betweenValidation.params[1]).toDate();
        return { min, max };
      }
      const min = new Date();
      min.setFullYear(min.getFullYear() - 80, 0, 1);
      const max = new Date();
      max.setFullYear(max.getFullYear() + 40, 11, 31);
      return { min, max };
    },
  },
  methods: {
    open() {
      if (!this.value || this.value === '') {
        this.val = new Date();
      }
      this.$refs.picker.open();
    },
  },
};
</script>

<style>
.mobile-datetime-content .picker-item {
  font-size: 16px;
}
</style>
