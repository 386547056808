<template>
  <div>
    <el-button plain class="mobile-dropdown" @click="visible = true">
      <div v-if="value && value.length > 0" style="display: inline-block; width: 90%">{{ translate(value) }}</div>
      <div v-else style="display: inline-block; width: 90%">{{ 'Please select' | trans }}</div>
      <i class="el-icon-arrow-down" style="float: right"></i>
    </el-button>
    <mt-popup v-model="visible" position="bottom" class="mobile-popup-content">
      <fb-mobile-radio-button
        :field="field"
        :value="value"
        @update="update"
        @close="visible = false"
      ></fb-mobile-radio-button>
    </mt-popup>
  </div>
</template>

<script>
import isMobile from 'ismobilejs';
import u from 'utils/utils';
import FbMobileRadioButton from 'MobileField/RadioButton';

export default {
  name: 'FbMobileDropDown',
  components: { FbMobileRadioButton },
  props: ['field', 'value'],
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    visible() {
      if (isMobile().apple.device && !this.visible) {
        u.scroll(this);
      }
    },
  },
  methods: {
    update(value) {
      this.$emit('update', value);
    },
    translate(option) {
      if (this.$store.state.form.isTranslate) {
        return u.trans(option);
      }
      return option;
    },
  },
};
</script>

<style scoped>
.mobile-dropdown {
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}
.mobile-popup-content {
  width: 100%;
  height: 60%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
</style>
