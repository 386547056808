<template>
  <el-input
    v-model="val"
    type="textarea"
    :rows="rows"
    :disabled="!field.editable"
    :placeholder="placeholder | trans"
    @blur="blur"
  ></el-input>
</template>

<script>
import u from '../../../utils/utils';

export default {
  name: 'FbMultiLineText',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : '';
      },
      set(value) {
        this.$emit('update', value);
      },
    },
    placeholder() {
      return u.has(this.field.attributes, 'placeholder') ? this.field.attributes.placeholder : '';
    },
    rows() {
      return parseInt(this.field.attributes.rows, 10);
    },
  },
  methods: {
    blur(event) {
      if (this.value !== event.target.value) {
        this.$emit('update', event.target.value);
      }
    },
  },
};
</script>

<style scoped></style>
