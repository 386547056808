import Vue from 'vue';
import Vuex from 'vuex';
import { Notification } from 'element-ui';
import { Validator } from 'vee-validate';
import axios from 'axios';
import u from 'utils/utils';
import { findFieldByCode, fieldsToFieldRows, setupRuntimeProps } from 'utils/field-utils';
import ru from 'utils/record-utils';
import { calc } from 'utils/calc-utils';
import ku from 'utils/kviewer-utils';
import { renameTo } from 'utils/url-utils';
import { submit as submitForm } from 'api/public-api';
import kviewerApi from 'api/kviewer';
import us from './utils/user-state';
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        form: {},
        fields: [],
        fieldRows: [],
        record: {},
        validations: {},
        tableValidations: {},
        logs: [],
        loaded: false,
        fileUploading: false,
        submitting: false,
        submitted: false,
        kappauthPreview: null,
    },
    getters: {
        getFieldByCode: (state) => (code) => state.fields.find((field) => field.code === code),
        posted: (state) => state.submitting || state.submitted,
    },
    actions: {
        submit: async ({ state }, vm) => {
            state.submitting = true;
            let verifyToken = null;
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
            if (form.isUseRecaptcha && vm.$recaptcha) {
                verifyToken = await vm.$recaptcha(state.form.code).catch(() => null);
            }
            state.record.__posted_at__ = {
                value: new Date().toISOString(),
                type: 'DATETIME',
            };
            ru.fixingRecord(state.fields, state.record);
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'code' does not exist on type '{}'.
            submitForm(state.form, verifyToken, state.record, state.kappauthPreview)
                .then((response) => {
                Vue.set(state, 'submitted', true);
                Vue.set(state, 'submitting', false);
                Vue.set(state, 'record', response.data.data);
                Vue.set(state, 'logs', response.data.logs);
                const query = {};
                if (state.kappauthPreview) {
                    query.kappauthPreview = state.kappauthPreview;
                }
                vm.$router.replace({ name: renameTo(vm.$route.name, 'finish'), query });
            })
                .catch((error) => {
                if (u.has(error.response.data, 'logs')) {
                    Vue.set(state, 'logs', error.response.data.logs);
                }
                u.error(vm, error);
                Vue.set(state, 'submitting', false);
            });
        },
        updateField: ({ state, commit }, payload) => {
            Vue.set(state.record[payload.code], 'value', payload.value);
            calc(payload, state.fields, state.record);
            commit('updateBranch');
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
            if (u.has(fb.events.fields, payload.code) && u.has(fb.events.fields[payload.code], 'changed')) {
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                commit('callEvents', { events: fb.events.fields[payload.code].changed });
                calc(payload, state.fields, state.record);
                commit('updateBranch');
            }
        },
        updateTableField: ({ state, commit }, payload) => {
            const tableField = findFieldByCode(payload.tableCode, state.fields);
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const tableRecord = state.record[payload.tableCode].value[payload.tableIndex];
            Vue.set(tableRecord.value[payload.code], 'value', payload.value);
            calc(tableField, state.fields, state.record);
            if (
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
            u.has(fb.events.fields, payload.tableCode) &&
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                u.has(fb.events.fields[payload.tableCode].fields, payload.code) &&
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                u.has(fb.events.fields[payload.tableCode].fields[payload.code], 'changed')) {
                commit('callEvents', {
                    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                    events: fb.events.fields[payload.tableCode].fields[payload.code].changed,
                    params: { index: payload.tableIndex, value: payload.value },
                });
                calc(tableField, state.fields, state.record);
            }
        },
        addTableRecord: ({ state, commit }, payload) => {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            state.record[payload.code].value.push(ru.defaultTableRecord(payload));
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
            if (u.has(fb.events.fields, payload.code) && u.has(fb.events.fields[payload.code], 'add')) {
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                commit('callEvents', { events: fb.events.fields[payload.code].add });
            }
            calc(payload, state.fields, state.record);
        },
        removeTableRecord: ({ state, commit }, payload) => {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            state.record[payload.code].value.splice(payload.index, 1);
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
            if (u.has(fb.events.fields, payload.code) && u.has(fb.events.fields[payload.code], 'remove')) {
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                commit('callEvents', { events: fb.events.fields[payload.code].remove });
            }
            calc(payload, state.fields, state.record);
        },
        initKviewerRecord: async ({ state, commit }) => {
            const kviewerParamKeys = ['__kViewerSubdomain__', '__kViewerViewCode__', '__kViewerRecordCode__'];
            if (kviewerParamKeys.every((key) => u.has(state.record, key))) {
                return;
            }
            const viewCode = u.query('__kViewerViewCode__');
            const recordCode = u.query('__kViewerRecordCode__');
            const subdomain = u.query('__kViewerSubdomain__') || undefined;
            if (viewCode &&
                recordCode &&
                typeof viewCode === 'string' &&
                typeof recordCode === 'string' &&
                (typeof subdomain === 'string' || typeof subdomain === 'undefined')) {
                Vue.set(state.record, '__kViewerViewCode__', { value: viewCode, type: 'SINGLE_LINE_TEXT' });
                Vue.set(state.record, '__kViewerRecordCode__', { value: recordCode, type: 'SINGLE_LINE_TEXT' });
                Vue.set(state.record, '__kViewerSubdomain__', { value: subdomain, type: 'SINGLE_LINE_TEXT' });
                try {
                    const res = await kviewerApi.fetchRecord(viewCode, recordCode, subdomain);
                    ru.mergeKviewerRecord(state.fields, state.record, res.data);
                    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                    commit('callEvents', { events: fb.events.kviewer.record.mapped });
                    state.loaded = true;
                }
                catch (e) {
                    let errorMessage = u.trans("Can't get record information.");
                    if (u.pickErrorMessage(e)) {
                        errorMessage += `(${u.trans(u.pickErrorMessage(e))})`;
                    }
                    errorMessage += u.trans('Please give it some time and try again.');
                    Notification.error({
                        title: u.trans('Error'),
                        message: errorMessage,
                        customClass: 'break-all',
                    });
                    if (axios.isAxiosError(e)) {
                        if (e.response?.status === 404 &&
                            ku.isKvErrorResponse(e.response.data) &&
                            e.response.data.error === 'The one time token may have expired or invalid.') {
                            ku.showMessageAndRedirectKViewer(viewCode, recordCode, subdomain);
                        }
                    }
                }
            }
            else {
                state.loaded = true;
            }
        },
    },
    mutations: {
        init: (state) => {
            /* global form:true */
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'form'.
            Vue.set(state, 'form', form);
            /* global fields:true */
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fields'.
            fields.forEach((f) => {
                f.show = true;
                setupRuntimeProps(f);
            });
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fields'.
            Vue.set(state, 'fields', fields);
            /* global record:true */
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'record'.
            Vue.set(state, 'record', record);
            Vue.set(state, 'fieldRows', fieldsToFieldRows(state.fields));
            if (Object.keys(state.record).length === 0) {
                state.fields.forEach((field) => {
                    if (field.code) {
                        Vue.set(state.record, field.code, { value: ru.generateValue(field), type: field.type });
                    }
                });
            }
            // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
            if (typeof fb.addValidators === 'function') {
                // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'fb'.
                const validators = fb.addValidators(state);
                Object.keys(validators).forEach((name) => {
                    Validator.extend(name, validators[name]);
                });
            }
            const query = new URLSearchParams(window.location.search);
            Vue.set(state, 'kappauthPreview', query.get('kappauthPreview'));
        },
        callEvents: (state, payload) => {
            us.callEvents(state, payload, (userState) => {
                if (userState.form && typeof userState.form === 'object') {
                    state.form = userState.form;
                }
                if (userState.fields && typeof userState.fields === 'object') {
                    state.fields = userState.fields;
                    state.fieldRows = fieldsToFieldRows(state.fields);
                }
                if (userState.record && typeof userState.record === 'object') {
                    state.record = userState.record;
                }
            });
        },
        updateBranch: (state) => {
            if (!state.form.isBranchForm) {
                return;
            }
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'branches' does not exist on type '{}'.
            state.form.branches.forEach((branch) => {
                branch.availableFields.forEach((code) => {
                    const field = findFieldByCode(code, state.fields);
                    if (field) {
                        field.show = false;
                        field.validations = [];
                        if (field.type === 'SUBTABLE') {
                            field.fields.forEach((fieldInTable) => {
                                fieldInTable.validations = [];
                            });
                        }
                    }
                });
                const showFields = [];
                branch.conditions.forEach((cond) => {
                    if (!u.has(cond, 'logical')) {
                        cond.logical = 'or';
                    }
                    let show = cond.logical === 'and';
                    cond.rules.forEach((rule) => {
                        if (!u.has(state.record, rule.field)) {
                            return;
                        }
                        const v = state.record[rule.field].value;
                        switch (rule.rule) {
                            case '=':
                                if (cond.logical === 'and') {
                                    // v and rule.value is a String or Array.
                                    show = show && JSON.stringify(v) === JSON.stringify(rule.value);
                                }
                                else {
                                    show = show || JSON.stringify(v) === JSON.stringify(rule.value);
                                }
                                break;
                            case '!=':
                                if (cond.logical === 'and') {
                                    show = show && JSON.stringify(v) !== JSON.stringify(rule.value);
                                }
                                else {
                                    show = show || JSON.stringify(v) !== JSON.stringify(rule.value);
                                }
                                break;
                            case 'In':
                                if (cond.logical === 'and') {
                                    // @ts-expect-error Property 'indexOf' does not exist on type 'Entity'. (tsserver 2339)
                                    show = show && v.indexOf(rule.value) >= 0;
                                }
                                else {
                                    // @ts-expect-error Property 'indexOf' does not exist on type 'Entity'. (tsserver 2339)
                                    show = show || v.indexOf(rule.value) >= 0;
                                }
                                break;
                            case 'Not in':
                                if (cond.logical === 'and') {
                                    // @ts-expect-error Property 'indexOf' does not exist on type 'Entity'. (tsserver 2339)
                                    show = show && v.indexOf(rule.value) < 0;
                                }
                                else {
                                    // @ts-expect-error Property 'indexOf' does not exist on type 'Entity'. (tsserver 2339)
                                    show = show || v.indexOf(rule.value) < 0;
                                }
                                break;
                            default:
                                break;
                        }
                    });
                    if (show) {
                        cond.fields.forEach((code) => {
                            showFields.push(code);
                        });
                    }
                });
                showFields.forEach((code) => {
                    const field = findFieldByCode(code, state.fields);
                    if (field) {
                        field.show = true;
                        field.validations = state.validations[field.code];
                        if (field.type === 'SUBTABLE') {
                            field.fields.forEach((fieldInTable) => {
                                fieldInTable.validations = state.tableValidations[field.code][fieldInTable.code];
                            });
                        }
                    }
                });
            });
        },
        setFileUploading(state, payload) {
            state.fileUploading = payload;
        },
        storeValidations(state) {
            if (state.form.isBranchForm) {
                state.fields.forEach((field) => {
                    if (!u.has(state.validations, field.code)) {
                        state.validations[field.code] = field.validations;
                    }
                    if (field.type === 'SUBTABLE' && !u.has(state.tableValidations, field.code)) {
                        const tableValidations = {};
                        field.fields.forEach((fieldInTable) => {
                            tableValidations[fieldInTable.code] = fieldInTable.validations;
                        });
                        state.tableValidations[field.code] = tableValidations;
                    }
                });
            }
        },
    },
});
export default store;
