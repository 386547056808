import { planGtEq } from 'utils/plan-utils';
import u from 'utils/utils';
export default {
    callEvents(state, payload, afterEvent) {
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'plan'.
        if (planGtEq(plan, 'premium') && Array.isArray(payload.events)) {
            payload.events.forEach((event) => {
                if (typeof event === 'function') {
                    let userState = {};
                    try {
                        if (u.has(payload, 'params')) {
                            userState = event(state, payload.params);
                        }
                        else {
                            userState = event(state);
                        }
                    }
                    catch (e) {
                        const fbError = e;
                        if (fbError.type && fbError.title && fbError.message) {
                            u.notify(fbError.type, fbError.title, fbError.message);
                            return;
                        }
                        throw e;
                    }
                    if (userState) {
                        afterEvent(userState);
                    }
                }
            });
        }
    },
};
