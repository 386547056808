<template>
  <el-time-picker v-model="val" :picker-options="pickerOptions" :disabled="!field.editable"></el-time-picker>
</template>

<script>
import { formatTimeWithZeroPadding } from 'utils/datetime-utils';

export default {
  name: 'FbTime',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        if (this.value && this.value !== '') {
          const v = this.value.split(':');
          const d = new Date();
          d.setSeconds(0);
          d.setMinutes(v[1]);
          d.setHours(v[0]);
          return d;
        }
        return '';
      },
      set(value) {
        if (value instanceof Date) {
          value.setSeconds(0);
          this.$emit('update', formatTimeWithZeroPadding(value));
        } else {
          this.$emit('update', '');
        }
      },
    },
    pickerOptions() {
      if (this.field.pickerOptions) {
        return this.field.pickerOptions;
      }
      return {
        selectableRange: '00:00:00 - 23:59:59',
        format: 'HH:mm',
      };
    },
  },
  mounted() {
    this.$el.getElementsByTagName('input')[0].setAttribute('readonly', 'readonly');
  },
};
</script>

<style scoped></style>
