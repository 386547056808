<template>
  <div>
    <form-title :form="form" />
    <preview-navigation v-if="isPreview" />
    <div v-if="loaded" :class="['ui', 'segment', { loading: submitting }]">
      <form class="ui form" @submit.prevent>
        <normal-fields v-if="!form.isStepForm" @confirm="confirm" @submit="submit"></normal-fields>
        <step-form v-if="form.isStepForm" @confirm="confirm" @submit="submit"></step-form>
      </form>
    </div>
    <logout-button v-if="form.isUseMailAuthentication" :is-hide-copyright="form.hideCopyright" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'vee-validate';
import NormalFields from 'Public/NormalFields';
import StepForm from 'Public/StepForm';
import FormTitle from 'Public/Header/FormTitle';
import PreviewNavigation from 'Public/Header/PreviewNavigation';
import LogoutButton from 'Public/Button/LogoutButton';
import fu from '../utils/field-utils';
import url, { getPathType } from '../utils/url-utils';
import csp from '../utils/csp-utils';
import u from '../utils/utils';

export default {
  name: 'PublicForm',
  components: { NormalFields, StepForm, FormTitle, PreviewNavigation, LogoutButton },
  directives: { scroll: window.scroll },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$store.state.form.googleAnalytics && vm.$store.state.form.trackingId) {
        // ページ初期化時のタイミングの問題により, tracking ID が gtag スクリプトに登録される
        // 前にイベントの登録が行われる可能性がある. その場合でもイベントデータが送信される
        // ように, send_to で関連付ける tracking ID を指定している.
        if (from.name === null) {
          vm.$gtag.event(`visit: ${to.params.code}`, {
            event_category: 'Form',
            send_to: vm.$store.state.form.trackingId,
          });
        } else if (from.name.indexOf('confirm') > 0) {
          vm.$gtag.event(`back: ${to.params.code}`, {
            event_category: 'Form',
            send_to: vm.$store.state.form.trackingId,
          });
        }
      }
    });
  },
  data() {
    return {
      form: {},
      initKviewerRecordProcess: new Promise(() => {}),
    };
  },
  computed: {
    ...mapState(['loaded', 'submitting', 'kappauthPreview']),

    isPreview() {
      return getPathType(window.location.pathname) === 'private';
    },
  },
  created() {
    this.$store.commit('init');
    this.initKviewerRecordProcess = this.$store.dispatch('initKviewerRecord');
    this.$store.commit('callEvents', { events: fb.events.form.created });
    this.$store.commit('storeValidations');
    this.form = this.$store.state.form;
    this.addConfirmedFieldValidator();

    if (csp.isPreviewExternalResource(this.form)) {
      csp.addSecurityPolicyViolationEvent(this);
    }
  },
  async mounted() {
    this.$scrollTo('body');
    const children = this.$children;
    fb.getElementByCode = function (code) {
      const vm = fu.findVmByCode(code, children);
      if (vm && vm.$el.offsetParent !== null) {
        return vm.$el;
      }
      return null;
    };
    fb.getTableElementsByCode = function (tableCode, code) {
      const tableVm = fu.findVmByCode(tableCode, children);
      if (!tableVm) {
        return null;
      }
      if (tableVm.$children.length !== 1) {
        return null;
      }
      const rowVms = tableVm.$children[0].$children;
      const els = [];
      rowVms.forEach((vm) => {
        const fieldVm = fu.findVmByCode(code, vm.$children, 'TableField');
        if (fieldVm) {
          els.push(fieldVm.$el);
        }
      });
      return els;
    };
    fb.forceSubmitForm = () => {
      this.submit();
    };
    await this.initKviewerRecordProcess;
    this.$store.commit('callEvents', { events: fb.events.form.mounted });
  },
  methods: {
    confirm() {
      const query = {};
      if (this.kappauthPreview) {
        query.kappauthPreview = this.kappauthPreview;
      }
      this.$router.replace({
        name: url.renameTo(this.$route.name, 'confirm'),
        query,
      });
    },
    submit() {
      this.$store.dispatch('submit', this);
    },
    addConfirmedFieldValidator() {
      const { record } = this.$store.state;
      Validator.extend('confirmed_field', (value, [target]) => {
        if (u.has(record, target)) {
          return value === record[target].value;
        }
        return true;
      });
    },
  },
};
</script>

<style scoped></style>
