<template>
  <el-date-picker
    v-model="val"
    type="date"
    :picker-options="pickerOptions"
    :disabled="!field.editable"
  ></el-date-picker>
</template>

<script>
export default {
  name: 'FbDate',
  props: ['field', 'value'],
  computed: {
    val: {
      get() {
        return this.value ? this.value : '';
      },
      set(value) {
        if (!value) {
          this.$emit('update', '');
        } else {
          this.$emit('update', value);
        }
      },
    },
    pickerOptions() {
      if (this.field.pickerOptions) {
        return this.field.pickerOptions;
      }
      return {};
    },
  },
  mounted() {
    this.$el.getElementsByTagName('input')[0].setAttribute('readonly', 'readonly');
  },
};
</script>

<style scoped></style>
